import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router";

function WelcomePage() {
  const theme = useTheme();

  const history = useHistory<any>();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "calc(100% - 40px)",
      }}
    >
      <Grid container direction="column" justify="center" alignItems="center">
        <img src="/images/welcome.png" width={177.67} height={207.67} alt="welcome" />

        <Typography
          color="textPrimary"
          variant="h3"
          style={{ textAlign: "center", whiteSpace: "pre-line" }}
          children={[
            "Getting your bonuses is ",
            <span style={{ color: theme.palette.primary.main, fontWeight: 700 }} key="easier">
              easier
            </span>,
            " than you think!",
          ]}
        />

        <Typography
          children="Sign up takes only 2 minutes"
          style={{
            fontFamily: "Karla",
            fontSize: 16,
            color: "#646874",
            marginTop: 10,
          }}
        />

        <Button
          fullWidth
          style={{ maxWidth: 280, marginTop: theme.spacing(4) }}
          variant="contained"
          color="primary"
          children="CREA IL TUO PROFILO"
          onClick={() => {
            history.push({
              pathname: "/register",
              state: { from: history.location?.state?.from || "" },
            });
          }}
        />
        <Button
          fullWidth
          style={{ maxWidth: 280, marginTop: theme.spacing(2) }}
          variant="contained"
          color="default"
          children="ACCEDI"
          onClick={() => {
            history.push({
              pathname: "/login",
              state: { from: history.location?.state?.from || "" },
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default WelcomePage;
