"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceApi = exports.ServiceApiFactory = exports.ServiceApiFp = exports.ServiceApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * ServiceApi - axios parameter creator
 * @export
 */
exports.ServiceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add service to building
         * @param {string} buildingId
         * @param {string} [externalId]
         * @param {string} [name]
         * @param {any} [file]
         * @param {string} [description]
         * @param {string} [placeId]
         * @param {ServiceContacts} [contacts]
         * @param {ServiceDiscount} [discount]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServiceToBuilding: (buildingId, externalId, name, file, description, placeId, contacts, discount, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'buildingId' is not null or undefined
            common_1.assertParamExists('addServiceToBuilding', 'buildingId', buildingId);
            const localVarPath = `/admin/service/{buildingId}/`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (externalId !== undefined) {
                localVarFormParams.append('externalId', externalId);
            }
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (placeId !== undefined) {
                localVarFormParams.append('placeId', placeId);
            }
            if (contacts !== undefined) {
                localVarFormParams.append('contacts', new Blob([JSON.stringify(contacts)], { type: "application/json", }));
            }
            if (discount !== undefined) {
                localVarFormParams.append('discount', new Blob([JSON.stringify(discount)], { type: "application/json", }));
            }
            if (type !== undefined) {
                localVarFormParams.append('type', type);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit service
         * @param {string} id
         * @param {string} [externalId]
         * @param {string} [name]
         * @param {any} [file]
         * @param {string} [description]
         * @param {string} [placeId]
         * @param {ServiceContacts} [contacts]
         * @param {ServiceDiscount} [discount]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editService: (id, externalId, name, file, description, placeId, contacts, discount, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editService', 'id', id);
            const localVarPath = `/admin/service/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (externalId !== undefined) {
                localVarFormParams.append('externalId', externalId);
            }
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (placeId !== undefined) {
                localVarFormParams.append('placeId', placeId);
            }
            if (contacts !== undefined) {
                localVarFormParams.append('contacts', new Blob([JSON.stringify(contacts)], { type: "application/json", }));
            }
            if (discount !== undefined) {
                localVarFormParams.append('discount', new Blob([JSON.stringify(discount)], { type: "application/json", }));
            }
            if (type !== undefined) {
                localVarFormParams.append('type', type);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin service by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminServiceById', 'id', id);
            const localVarPath = `/admin/service/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all admin building services
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminBuildingServices: (buildingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'buildingId' is not null or undefined
            common_1.assertParamExists('getAllAdminBuildingServices', 'buildingId', buildingId);
            const localVarPath = `/admin/service/{buildingId}/`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all user services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserServices: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/service/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get service by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getServiceById', 'id', id);
            const localVarPath = `/service/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ServiceApi - functional programming interface
 * @export
 */
exports.ServiceApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.ServiceApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add service to building
         * @param {string} buildingId
         * @param {string} [externalId]
         * @param {string} [name]
         * @param {any} [file]
         * @param {string} [description]
         * @param {string} [placeId]
         * @param {ServiceContacts} [contacts]
         * @param {ServiceDiscount} [discount]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServiceToBuilding(buildingId, externalId, name, file, description, placeId, contacts, discount, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addServiceToBuilding(buildingId, externalId, name, file, description, placeId, contacts, discount, type, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit service
         * @param {string} id
         * @param {string} [externalId]
         * @param {string} [name]
         * @param {any} [file]
         * @param {string} [description]
         * @param {string} [placeId]
         * @param {ServiceContacts} [contacts]
         * @param {ServiceDiscount} [discount]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editService(id, externalId, name, file, description, placeId, contacts, discount, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editService(id, externalId, name, file, description, placeId, contacts, discount, type, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin service by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminServiceById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all admin building services
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminBuildingServices(buildingId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminBuildingServices(buildingId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all user services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserServices(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllUserServices(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get service by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getServiceById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ServiceApi - factory interface
 * @export
 */
exports.ServiceApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.ServiceApiFp(configuration);
    return {
        /**
         *
         * @summary Add service to building
         * @param {string} buildingId
         * @param {string} [externalId]
         * @param {string} [name]
         * @param {any} [file]
         * @param {string} [description]
         * @param {string} [placeId]
         * @param {ServiceContacts} [contacts]
         * @param {ServiceDiscount} [discount]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServiceToBuilding(buildingId, externalId, name, file, description, placeId, contacts, discount, type, options) {
            return localVarFp.addServiceToBuilding(buildingId, externalId, name, file, description, placeId, contacts, discount, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit service
         * @param {string} id
         * @param {string} [externalId]
         * @param {string} [name]
         * @param {any} [file]
         * @param {string} [description]
         * @param {string} [placeId]
         * @param {ServiceContacts} [contacts]
         * @param {ServiceDiscount} [discount]
         * @param {string} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editService(id, externalId, name, file, description, placeId, contacts, discount, type, options) {
            return localVarFp.editService(id, externalId, name, file, description, placeId, contacts, discount, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin service by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminServiceById(id, options) {
            return localVarFp.getAdminServiceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all admin building services
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminBuildingServices(buildingId, options) {
            return localVarFp.getAllAdminBuildingServices(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all user services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserServices(options) {
            return localVarFp.getAllUserServices(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get service by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceById(id, options) {
            return localVarFp.getServiceById(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
class ServiceApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add service to building
     * @param {string} buildingId
     * @param {string} [externalId]
     * @param {string} [name]
     * @param {any} [file]
     * @param {string} [description]
     * @param {string} [placeId]
     * @param {ServiceContacts} [contacts]
     * @param {ServiceDiscount} [discount]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    addServiceToBuilding(buildingId, externalId, name, file, description, placeId, contacts, discount, type, options) {
        return exports.ServiceApiFp(this.configuration).addServiceToBuilding(buildingId, externalId, name, file, description, placeId, contacts, discount, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit service
     * @param {string} id
     * @param {string} [externalId]
     * @param {string} [name]
     * @param {any} [file]
     * @param {string} [description]
     * @param {string} [placeId]
     * @param {ServiceContacts} [contacts]
     * @param {ServiceDiscount} [discount]
     * @param {string} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    editService(id, externalId, name, file, description, placeId, contacts, discount, type, options) {
        return exports.ServiceApiFp(this.configuration).editService(id, externalId, name, file, description, placeId, contacts, discount, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin service by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    getAdminServiceById(id, options) {
        return exports.ServiceApiFp(this.configuration).getAdminServiceById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all admin building services
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    getAllAdminBuildingServices(buildingId, options) {
        return exports.ServiceApiFp(this.configuration).getAllAdminBuildingServices(buildingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all user services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    getAllUserServices(options) {
        return exports.ServiceApiFp(this.configuration).getAllUserServices(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get service by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    getServiceById(id, options) {
        return exports.ServiceApiFp(this.configuration).getServiceById(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ServiceApi = ServiceApi;
