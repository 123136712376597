/** @jsxImportSource @emotion/react */
import { TableCell, TableRow, Typography, useTheme } from "@material-ui/core";
import { Place } from "belvillage-api-manager";
import { forwardRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CircledEditIcon } from "../../../../components/CircledEditIcon";
import { backendMediaUrlParser } from "../../../../utils";

type PlaceRowProps = {
	place: Place;
};
const PlaceRow = forwardRef<any, PlaceRowProps>(({ place, ...props }, ref) => {
	const theme = useTheme();

	const history = useHistory();
	const { url } = useRouteMatch();

	return (
		<TableRow
			onClick={() => {
				history.push(url + "/" + place.id);
			}}
			ref={ref}
			{...props}
		>
			<TableCell>
				{place.photo ? (
					<img
						src={backendMediaUrlParser(place.photo)}
						alt={place.name}
						width={48}
						height={48}
						css={{ borderRadius: 10, objectFit: "cover" }}
					/>
				) : (
					<div
						css={{
							width: 48,
							height: 48,
							borderRadius: 10,
							backgroundColor: theme.palette.background.default,
						}}
					/>
				)}
			</TableCell>
			<TableCell>
				<Typography children={place.name} variant="subtitle1" />
			</TableCell>
			<TableCell>
				<CircledEditIcon />
			</TableCell>
		</TableRow>
	);
});
export default PlaceRow;
