import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function ArrowRightIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "18"}
			height={iconSize || "18"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 18 18"
		>
			<path
				fill={color || "#EF7705"}
				d="M12.844 9.487l-6.237 6.497a.703.703 0 01-1.159-.741.704.704 0 01.148-.23L11.364 9 5.596 2.987a.701.701 0 111.01-.972l6.238 6.497a.7.7 0 01.192.459v.005a.701.701 0 01-.192.511z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default ArrowRightIcon;
