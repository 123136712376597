import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const DocumentsIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize || "16"}
      height={iconSize || "18"}
      fillOpacity={opacity || 100}
      fill="none"
      viewBox="0 0 16 18"
    >
      <path
        fill={color || "#fff"}
        d="M9.346 5.994v.007a.033.033 0 00.013.028l.015.012.014.014a.043.043 0 00.037.011l.013-.002 1.505-.142-1.598-1.607.001 1.679zm4.476-1.907l-1.626-1.615V4.169c0 .005 0 .01.003.015a.033.033 0 00.01.013l.015.012.014.014a.042.042 0 00.037.011l.013-.002 1.534-.145zM4.27.797a1.75 1.75 0 01.674-.13h6.956l3.636 3.635v9.465a1.758 1.758 0 01-1.758 1.734h-1.092v.098a1.758 1.758 0 01-1.758 1.734H2.092a1.749 1.749 0 01-1.759-1.76V4.259a1.75 1.75 0 011.76-1.76h1.09v-.072A1.75 1.75 0 014.27.798zm.406.994a.675.675 0 00-.42.632V2.5h4.792l2.21 2.21a1.137 1.137 0 01-.137-.557V1.742H4.938a.675.675 0 00-.262.05zm8.008 4.344v8.293h1.096a.675.675 0 00.682-.682V5.112l-2.093.212h-.098a1.12 1.12 0 01-.535-.136l.948.947zM1.825 3.623a.675.675 0 00-.418.63V15.6a.675.675 0 00.68.681h8.822a.675.675 0 00.681-.681V6.945l-2.073.21H9.42a1.12 1.12 0 01-.777-.314 1.137 1.137 0 01-.372-.856V3.573H2.087a.675.675 0 00-.262.05z"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default DocumentsIcon;
