/** @jsxImportSource @emotion/react */

import { Container, Grow, TableCell, TableRow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CategoryApi, TutorialApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdminTable, {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  parseCountFrom,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { backendMediaUrlParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const tutorialApi = useApi(TutorialApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });

  const { path: matchedPath } = useRouteMatch();

  useEffect(() => {
    drawerDispatch({ open: true });
    headerDispatch({ actionText: "ADD NEW" });
  }, []);

  const {
    data: categories,
    rawData,
    loading,
  } = useFetch(tutorialApi.getAllAdminTutorials, transformToStringFrom(filter));

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid container spacing={1}>
          <AdminTable
            headCells={headCells}
            fullWidth
            occlusionHeight={60}
            loading={loading}
            queryFilter={[filter, setFilter]}
            count={parseCountFrom(rawData)}
            data={categories}
          >
            {(data) => {
              return data?.map((tutorial, i) => {
                return (
                  <Grow
                    key={tutorial?.id}
                    appear={true}
                    in={true}
                    timeout={i * 300 + 300}
                  >
                    <TableRow
                      key={tutorial?.id}
                      onClick={() => {
                        history.push(matchedPath + "/" + tutorial.id);
                      }}
                    >
                      <TableCell>
                        {tutorial.thumbnail && (
                          <div
                            css={{
                              borderRadius: 10,
                              width: 58,
                              height: 58,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url("${backendMediaUrlParser(
                                tutorial.thumbnail
                              )}")`,
                              backgroundPosition: "50% 50%",
                            }}
                          />
                        )}
                      </TableCell>

                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          {tutorial.name}
                        </Typography>
                      </TableCell>
                      <TableCell>{tutorial.description}</TableCell>
                      <TableCell css={{ textAlign: "right", paddingRight: 24 }}>
                        <CircledEditIcon />
                      </TableCell>
                    </TableRow>
                  </Grow>
                );
              });
            }}
          </AdminTable>
        </Grid>
      </Grid>
    </Container>
  );
}

const headCells: IHeadCell[] = [
  { id: "thumbnail", label: "Video" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
