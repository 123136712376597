import { Grow } from "@material-ui/core";
import { PlaceApi } from "belvillage-api-manager";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AdminTable, {
	defaultQueryFilter,
	IHeadCell,
	IQueryFilter,
	parseCountFrom,
	transformToStringFrom,
} from "../../../../components/admin/AdminTable";
import { useApi, useFetch } from "../../../../utils/api";
import ReviewRow from "./ReviewRow";

const headCells: IHeadCell[] = [
	{ id: "edit", label: "", notSelectable: true, width: 24 },
	{ id: "owner.name", label: "Guest name", notSelectable: true },
	{ id: "createdAt", label: "Date" },
	{ id: "edit", label: "", notSelectable: true, width: 120 },
];

type ReviewsTableProps = {};

const ReviewsTable: React.FC<ReviewsTableProps> = () => {
	const placeApi = useApi(PlaceApi);
	const [filter, setFilter] = useState<IQueryFilter>({
		...defaultQueryFilter,
	});

	const { buildingId } = useParams<any>();
	const {
		data: reviews,
		rawData,
		loading,
		revalidate,
	} = useFetch(
		placeApi.getAllBuildingReviews,
		buildingId,
		transformToStringFrom(filter),
		{
			skip: !buildingId,
		}
	);

	return (
		<AdminTable
			headCells={headCells}
			fullWidth
			fullHeight
			// occlusionHeight={100}
			loading={loading}
			queryFilter={[filter, setFilter]}
			count={parseCountFrom(rawData)}
			data={reviews}
			size="small"
		>
			{(data) => {
				return data?.map((review, i) => {
					return (
						<Grow
							appear={true}
							key={`${review.id}_${i}`}
							in={true}
							timeout={i * 300 + 300}
						>
							<ReviewRow
								revalidate={revalidate}
								review={review}
								headCells={headCells}
							/>
						</Grow>
					);
				});
			}}
		</AdminTable>
	);
};
export default ReviewsTable;
