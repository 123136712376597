/**@jsxImportSource @emotion/react */

import {
  Grow,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { TicketCategory } from "belvillage-api-manager";
import AdminTable, {
  IHeadCell,
  parseCountFrom,
} from "../../../../components/admin/AdminTable";
import { PencilEditIcon } from "../../../../components/BelVillageIcons";
import { AdminTableBaseProps } from "../List";
import BuildingTicketEmailEditor from "./BuildingTicketEmailEditor";

const TicketCategoryAdminTable = (
  props: AdminTableBaseProps<TicketCategory[]> & {
    onEdit: (initialValue: TicketCategory) => void;
    onMonitoringMailEdit: () => void;
    monitoringEmail?: string;
  }
) => {
  const { filterState, loading, rawData, data: ticketCategories } = props;

  const [filter, setFilter] = filterState;
  return (
    <AdminTable
      headCells={TutorialHeadCells}
      fullWidth
      occlusionHeight={60}
      loading={loading}
      queryFilter={[filter, setFilter]}
      count={parseCountFrom(rawData)}
      data={ticketCategories}
      settingsHeader={
        <BuildingTicketEmailEditor
          monitoringEmail={props.monitoringEmail}
          onEdit={() => {
            props.onMonitoringMailEdit();
          }}
        />
      }
    >
      {(data) =>
        data?.map((ticketCategory, i) => {
          return (
            <Grow
              key={ticketCategory?.id}
              appear={true}
              in={true}
              timeout={i * 300 + 300}
            >
              <TableRow key={ticketCategory?.id}>
                <TableCell>
                  <Typography variant="body1" color="textPrimary">
                    {ticketCategory.name}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body1" color="textPrimary">
                    {ticketCategory.linkedEmail}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    children={<PencilEditIcon />}
                    onClick={() => {
                      props.onEdit(ticketCategory);
                    }}
                  />
                </TableCell>
              </TableRow>
            </Grow>
          );
        })
      }
    </AdminTable>
  );
};

const TutorialHeadCells: IHeadCell[] = [
  { id: "name", label: "Category Name" },
  { id: "linkedMail", label: "Connected Mail" },
  { id: "edit", label: "" },
];

export default TicketCategoryAdminTable;
