/** @jsxImportSource @emotion/react */

import { MouseEvent } from "react";
import { PencilEditIcon } from "./BelVillageIcons";

export interface CircleEditProps {
  onClick?: (e: MouseEvent) => void;
}

export function CircledEditIcon(props: CircleEditProps) {
  return (
    <div
      css={{
        display: "inline-block",
        padding: 10,
        width: 36,
        height: 36,
        background: "rgba(255, 255, 255, 0.12)",
        borderRadius: 20,
        ":hover": {
          background: "rgba(255, 255, 255, 0.16)",
        },
      }}
      onClick={(e) => {
        if (props?.onClick) {
          e.preventDefault();
          e.stopPropagation();
          props?.onClick?.(e);
        }
      }}
    >
      <PencilEditIcon />
    </div>
  );
}
