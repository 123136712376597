import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const ProhibitionIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      fill="none"
      fillOpacity={opacity || 100}
      viewBox="0 0 17 16"
    >
      <path
        fill={color}
        d="M4.509 3.082l8.776 8.776a6.251 6.251 0 00-8.776-8.776zm7.715 9.836L3.448 4.142a6.251 6.251 0 008.776 8.776zM2.886 2.52a7.75 7.75 0 1110.96 10.96A7.75 7.75 0 012.887 2.52z"
      ></path>
    </svg>
  );
  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default ProhibitionIcon;
