import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const SnowIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize || "26"}
      height={iconSize || "28"}
      fill="none"
      fillOpacity={opacity || 100}
      viewBox="0 0 26 28"
    >
      <path
        fill={color || "#EF7705"}
        d="M24.316 17.54c.475-.258.638-.83.364-1.278-.275-.448-.883-.601-1.358-.343l-2.402 1.308L14.99 14l5.93-3.226 2.399 1.305c.475.258 1.083.105 1.357-.343s.11-1.02-.364-1.278l-1.404-.764 2.596-1.414c.474-.259.637-.83.363-1.278-.274-.447-.881-.6-1.356-.342l-2.603 1.415v-1.54c0-.516-.444-.935-.992-.935s-.992.419-.992.935v2.62l-5.94 3.231V5.924l2.412-1.311c.475-.258.638-.83.364-1.278-.274-.447-.881-.601-1.356-.343l-1.42.771V.936c0-.517-.444-.936-.993-.936-.548 0-.993.42-.993.936V3.76l-1.405-.765c-.475-.259-1.083-.105-1.357.343-.274.447-.111 1.02.364 1.278l2.398 1.306v6.451L6.069 9.145V6.537c0-.518-.445-.937-.993-.937-.55 0-.994.42-.994.937v1.53L1.49 6.654C1.014 6.397.407 6.55.133 6.998c-.274.447-.111 1.019.363 1.277L3.094 9.69l-1.412.768c-.475.258-.637.83-.364 1.278.274.447.882.601 1.357.343L5.08 10.77 11.017 14 5.08 17.232l-2.407-1.31c-.475-.26-1.083-.106-1.357.342-.275.448-.112 1.02.364 1.28l1.414.768L.5 19.724c-.475.259-.637.831-.362 1.279.274.447.881.6 1.355.341l2.59-1.41v1.53c0 .517.445.936.994.936.548 0 .993-.42.993-.936v-2.61l5.929-3.226v6.453l-2.395 1.302c-.475.259-.638.831-.364 1.28.275.447.882.6 1.357.341l1.402-.763v2.823c0 .517.445.936.993.936.55 0 .994-.42.994-.936v-2.827l1.414.77c.475.258 1.083.105 1.357-.343s.111-1.02-.364-1.279l-2.407-1.309v-6.46l5.939 3.23v2.62c0 .515.444.934.992.934s.992-.418.992-.935v-1.539l2.599 1.414c.475.258 1.082.105 1.356-.342.275-.448.113-1.02-.363-1.28l-2.592-1.41 1.408-.767z"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default SnowIcon;
