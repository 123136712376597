import comuni from '../assets/comuni.json'

const data = { comuni }

export function validate(forms, values) {
  const result = []
  forms.forms.map((v, k) => {
    v.values.fields.forEach((item, i) => {
      if (!item.optional) {
        const value = values[item.name]
        switch (item.validation) {
          case 'has':
            if (!value || (value && value.length < 1)) {
              result.push({ name: item.name })
            }
            break;
          case 'number':
            if (!value || (value && isNaN(value))) {
              result.push({ name: item.name })
            }
            break;
          case 'selected':
            if (!value || (value && value.length < 1)) {
              result.push({ name: item.name })
            }
            break;
        }
      }
    });
  })

  return result
}

export function getValuesFromName(values, name) {
  let v = values[name]
  return v
}

export function nextStep(step, values) {
  switch (step) {
    case 1:
      if (values[0].FirstName == 'Emilio') {
        return step + 1
      } else {
        return 0;
      }
      break;
    default:
      return step + 1
  }
}

export function checkForStep(operator, value, control, stepTrue, stepFalse) {
  // Example "nextstep": "check:<:Membrinucleo:2:f9:f1",
  switch (operator) {
    case '<':
      if (value < control) {
        return stepTrue
      } else {
        return stepFalse
      }
      break;
    default:
      console.log("ATTENTION!!! Default check function value.")
      return stepFalse
  }
}

export function switchForStep(v, text, response) {
  // Check all options, and jump to the first one true...there is no else....
  // nextstep": "switch:[{"eval":"v.Membrinucleo<2", "step":"f9"}]",
  const cases = JSON.parse(text)
  for (let i = 0; i < cases.length; i++) {
    if (eval(cases[i].eval)) return cases[i].step
  }
  console.log('Problem here...at least one should be true.')
}

export function getDataSet(name) {
  return data[name];
}

export function getIdFromForm(forms, name) {
  let id = 0

  forms.forEach((item, i) => {
    if (item.name === name) {
      id = i
    }
  });

  return id
}

export function getFormByName(forms, name) {
  const id = getIdFromForm(forms, name)
  return forms[id]
}

export function getNextForm(forms, current, direction) {
  const id = getIdFromForm(forms, current)
  if (direction === 'FORWARD') {
    console.log("ID FORM".red, id, forms[id + 1])
    if (id + 1 >= forms.length) return forms[id]
    return forms[id + 1]
  }

  if (direction === 'BACKWARD') {
    if (id === 0) return forms[id]
    return forms[id - 1]
  }

  return forms[id]
}

export function getFieldsFromStep(forms, activeStepName) {

  for (let form = 0; form < forms.length; form++) {
    for (let section = 0; section < forms[form].sections.length; section++) {
      for (let step = 0; step < forms[form].sections[section].forms.length; step++) {
        if (forms[form].sections[section].forms[step].stepname === activeStepName)
          return forms[form].sections[section].forms[step].fields
      }
    }
  }

  return []
}
