import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function CalendarIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "24"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 24"
		>
			<path
				fill={color || "#EF7705"}
				d="M4.007 19.286v-9.279h15.986v9.279a.269.269 0 01-.279.278H4.286a.269.269 0 01-.279-.278zM7.714 2.85c-.787 0-1.435.648-1.435 1.436v.707H4.286c-.788 0-1.436.648-1.436 1.436v12.857c0 .787.648 1.435 1.436 1.435h15.428c.788 0 1.436-.648 1.436-1.435V6.429c0-.788-.648-1.436-1.436-1.436h-1.992v-.707c0-.788-.649-1.436-1.436-1.436-.788 0-1.436.648-1.436 1.436v.707h-5.7v-.707c0-.788-.648-1.436-1.436-1.436zm0 1.157c.162 0 .279.117.279.279v2.571a.269.269 0 01-.279.279.269.269 0 01-.278-.279V4.286c0-.162.116-.279.278-.279zm8.572 0c.162 0 .278.117.278.279v2.571a.269.269 0 01-.278.279.269.269 0 01-.279-.279V4.286c0-.162.117-.279.279-.279zm-12 2.143h1.993v.707c0 .788.648 1.436 1.435 1.436.788 0 1.436-.648 1.436-1.436V6.15h5.7v.707c0 .788.648 1.436 1.436 1.436.787 0 1.435-.648 1.435-1.436V6.15h1.993c.162 0 .279.117.279.279V8.85H4.007V6.43c0-.162.117-.279.279-.279z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default CalendarIcon;
