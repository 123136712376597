/** @jsxImportSource @emotion/react */
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { AuthenticationApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NotVisibleIcon from "../../components/icons/not_visible";
import VisibleIcon from "../../components/icons/visible";
import { useDrawerDispatchHelper } from "../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../hooks/useHeaderDispatchHelper";
import { apiErrorParser } from "../../utils";
import { AccessTokenName, useApi } from "../../utils/api";
// import { fasterFetch } from "../src/utils";

function LoginPage() {
  const theme = useTheme();

  const history = useHistory<any>();

  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  // const [redirectUri, setRedirectUri] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  const [visiblePassword, setVisibilePassword] = useState(false);

  // useEffect(() => {
  //   const REDIRECT_URI = process.env.NEXT_PUBLIC_HOST;
  //   // console.log("STARTUP", router.query, "REDIRECT", REDIRECT_URI);

  //   setRedirectUri((history?.query["back-url"] as string) || REDIRECT_URI);
  //   setScope((history?.query["scope"] as string) || "platform");
  // }, [history]);

  const authenticationApi = useApi(AuthenticationApi);

  const headerDispatchHelper = useHeaderDispatchHelper();
  const drawerDispatchHelper = useDrawerDispatchHelper();

  useEffect(() => {
    headerDispatchHelper({ actionVisible: false });
    drawerDispatchHelper({ open: false });
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: "calc(100% - 40px)",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        css={{ maxWidth: 300 }}
      >
        <img
          src="/images/login/lock.png"
          width="110"
          height="159"
          alt="login"
        />

        <Grid container css={{ marginTop: 30, marginBottom: 16 }}>
          <img
            src="/images/logo_belvillage.png"
            width="153"
            height="24"
            alt="login"
          />
        </Grid>
        <Typography
          children="Use the credentials you received via email after booking"
          color="textSecondary"
        />

        <TextField
          value={email}
          style={{ maxWidth: isMobile ? 280 : 300, marginTop: 30 }}
          fullWidth
          label="Email"
          placeholder="E-mail"
          variant="outlined"
          onChange={(event) => {
            // setErrorMessage("");
            // setErrorMessageVisible(false);
            setEmail(event.target.value);
          }}
        />
        <TextField
          style={{
            marginTop: theme.spacing(2),
            maxWidth: isMobile ? 280 : 300,
          }}
          fullWidth
          variant="outlined"
          label="Password"
          type={!visiblePassword ? "password" : "text"}
          name="password"
          placeholder="Password"
          value={password}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && !ev.shiftKey) {
              ev.preventDefault();
              invokeLoginApi();
            }
          }}
          onChange={(event) => {
            // setErrorMessage("");
            // setErrorMessageVisible(false);
            setPassword(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => {
                    setVisibilePassword((value) => !value);
                  }}
                  edge="end"
                  style={{ color: "black" }}
                >
                  {visiblePassword ? <VisibleIcon /> : <NotVisibleIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Collapse in={Boolean(errorMessageVisible)}>
          <Typography
            children={errorMessage}
            style={{
              marginTop: 16,
              color: theme.palette.warning.main,
              textAlign: "center",
            }}
          />
        </Collapse>

        <Grid
          container
          css={{ maxWidth: 300, "> *": { marginTop: 16 }, marginTop: 30 }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            children="ACCEDI"
            onClick={() => {
              invokeLoginApi();
            }}
          />

          {/* <Button fullWidth variant="contained" color="primary" children="CONT PRIMARY" />
          <Button fullWidth variant="contained" color="secondary" children="CONT SECONDARY" />
          <Button fullWidth variant="outlined" color="primary" children="OUTLINED" />
          <Button fullWidth variant="text" color="primary" children="TEXT" />
          <Button fullWidth disabled variant="text" color="primary" children="DISABLED" /> */}
        </Grid>
      </Grid>
    </Grid>
  );

  async function invokeLoginApi() {
    if (email === "" || password === "") {
      setErrorMessage("Devi compilare tutti i campi per continuare");
      toggleErrorMessageVisible(true);
      return;
    } else {
      // setErrorMessage("");
      toggleErrorMessageVisible(false);
    }

    if (errorMessage) setErrorMessage(null);
    try {
      const { data } = await authenticationApi.loginAdmin({
        email,
        password,
      });

      console.log(data);

      if (data?.redisKey) {
        localStorage.clear();
        localStorage.setItem(AccessTokenName, data.redisKey);
        localStorage.setItem(
          "ability-rules",
          JSON.stringify(data?.abilityRules || [])
        );

        window.location.href = history?.location?.state?.from?.pathname || "/";
      }
    } catch (axiosError) {
      const error = apiErrorParser(axiosError as AxiosError);

      console.log("ERROR", error);

      setTimeout(
        () => {
          if (error) {
            switch (error) {
              case "email":
                setErrorMessage("Inserisci una mail valida");
                toggleErrorMessageVisible(true);
                break;
              case "password":
                setErrorMessage(
                  "Inserisci una password valida (almeno 5 caratteri)"
                );
                toggleErrorMessageVisible(true);
                break;
              case "invalidEmail":
                setErrorMessage("Mail non registrata");
                toggleErrorMessageVisible(true);
                break;
              case "invalidPassword":
                setErrorMessage("Password errata, riprova!");
                toggleErrorMessageVisible(true);
                break;
              default:
                setErrorMessage(null);
                toggleErrorMessageVisible(false);
            }

            return;
          }
        },
        errorMessage ? 500 : 0
      );
    }
  }

  function toggleErrorMessageVisible(visible: any) {
    setTimeout(
      () => {
        setErrorMessageVisible(visible);
      },
      errorMessage ? 500 : 0
    );
  }
}

export default LoginPage;
