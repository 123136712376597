/** @jsxImportSource @emotion/react */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	alpha,
	Button,
	Divider,
	Grid,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { PlaceApi, Review } from "belvillage-api-manager";
import { DateTime } from "luxon";
import { forwardRef, ReactNode, useCallback, useMemo } from "react";
import { IHeadCell } from "../../../../components/admin/AdminTable";
import { CircledDeleteIcon } from "../../../../components/CircledDeleteIcon";
import ArrowDownIcon from "../../../../components/icons/arrow_down";
import CloseCancIcon from "../../../../components/icons/close_canc";
import DeleteIcon from "../../../../components/icons/delete";
import FaceIcon from "../../../../components/icons/face_icon";
import PinIcon from "../../../../components/icons/pin";
import PriceIcon from "../../../../components/icons/price_icon";
import StarIcon from "../../../../components/icons/star_icon";
import StarIconOutlined from "../../../../components/icons/star_icon_outlined";
import LoadingIconButton from "../../../../components/LoadingIconButton";
import MLDialog from "../../../../components/poppers";
import { backendMediaUrlParser } from "../../../../utils";
import { useApi } from "../../../../utils/api";

type EvaluationProps = {
	title: string;
	titleIcon: ReactNode;
	howManyStars: number;
};
const Evaluation: React.FC<EvaluationProps> = ({
	title,
	titleIcon,
	howManyStars,
}) => {
	const theme = useTheme();
	return (
		<Grid item>
			<div css={{ display: "flex", alignItems: "center", gap: 4 }}>
				{titleIcon}
				<Typography children={title} variant="body2" />
			</div>
			<div
				css={{
					display: "flex",
					alignItems: "center",
					gap: 4,
					paddingTop: 2,
				}}
			>
				<Typography
					variant="body2"
					css={{
						color: alpha("#fff", 0.6),
						b: {
							fontSize: 16,
							fontWeight: 600,
							color: theme.palette.primary.main,
						},
					}}
				>
					<b>{howManyStars}</b>/5
				</Typography>
				<Rating
					value={howManyStars}
					readOnly
					icon={<StarIcon />}
					emptyIcon={<StarIconOutlined color="#fff" />}
				/>
			</div>
		</Grid>
	);
};

type ReviewRowProps = {
	review: Review;
	headCells: IHeadCell[];
	revalidate: () => void;
};
const ReviewRow = forwardRef<any, ReviewRowProps>(
	({ review, headCells, revalidate, ...props }, ref) => {
		const totalColSpan = useMemo(() => {
			return headCells.reduce((acc, cell) => {
				return acc + (cell.colSpan ?? 1);
			}, 0);
		}, [headCells]);

		const theme = useTheme();
		const placeApi = useApi(PlaceApi);

		const deleteReview = useCallback(() => {
			return placeApi
				.deleteAdminReview(review.id)
				.then(() => {
					MLDialog.showSnackbar("Review deleted successfully", {
						variant: "success",
					});
					revalidate();
				})
				.catch((e) => {
					MLDialog.showSnackbar("Error deleting review", {
						variant: "error",
					});
				});
		}, [placeApi, revalidate, review.id]);

		const openDeleteModal = useCallback(() => {
			MLDialog.showModal(
				"Delete this review?",
				"Are you sure about delete this review? This action is irreversible",
				{
					onPositiveClick: deleteReview,
					positiveButton: (onClick, loading) => (
						<LoadingIconButton
							onClick={onClick}
							loading={loading}
							variant="contained"
							startIcon={<DeleteIcon color="white" size={24} />}
							color="primary"
							css={{ height: 48 }}
						>
							Delete
						</LoadingIconButton>
					),
					negativeButton: (
						<Button
							onClick={() => {
								MLDialog.hideModal();
							}}
							startIcon={
								<CloseCancIcon color="white" size={24} />
							}
						>
							Cancel
						</Button>
					),
				}
			);
		}, [deleteReview]);

		return (
			<TableRow
				ref={ref}
				{...props}
				css={{ background: "unset !important" }}
			>
				<TableCell
					colSpan={totalColSpan}
					css={{
						padding: "0px !important",
						background: "unset !important",
					}}
				>
					<Accordion
						css={{
							backgroundColor: "rgba(7, 7, 7, 1)",
							borderRadius: "12px !important",
							border: "unset",
							overflow: "hidden",
						}}
					>
						<AccordionSummary
							expandIcon={
								<ArrowDownIcon size={24} color="white" />
							}
							css={{
								padding: 12,
								backgroundColor: "#222222",
								"&.Mui-expanded": {
									minHeight: 0,
								},
								"& .MuiAccordionSummary-content": {
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									padding: 0,
									paddingRight: 8,
									margin: 0,
									order: 1,
								},
								"& .MuiAccordionSummary-expandIcon": {
									padding: 12,
									margin: 0,
									order: 0,
								},
							}}
						>
							<Grid
								container
								alignItems="center"
								css={{ paddingLeft: 12 }}
							>
								<Grid
									item
									xs={7}
									css={{
										display: "flex",
										alignItems: "center",
										gap: 24,
									}}
								>
									{review.owner?.profile_image ? (
										<img
											src={backendMediaUrlParser(
												review.owner?.profile_image
											)}
											alt={`${review.owner?.name ?? 0}`}
											width={40}
											height={40}
											css={{
												borderRadius: 20,
												border: "1px solid #EF7705",
											}}
										/>
									) : (
										<div
											css={{
												height: 40,
												width: 40,
												backgroundColor:
													theme.palette.background
														.default,
												borderRadius: 20,
												border: "1px solid #EF7705",
											}}
										/>
									)}

									<Typography
										children={review.owner?.name ?? ""}
										variant="subtitle2"
									/>
								</Grid>
								<Grid item xs>
									{review.createdAt && (
										<Typography
											variant="body2"
											children={DateTime.fromISO(
												review.createdAt
											).toFormat("dd/MM/yyyy")}
										/>
									)}
								</Grid>
								<Grid item xs="auto">
									<CircledDeleteIcon
										onClick={openDeleteModal}
									/>
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails
							css={{
								padding: 12,
								paddingBottom: 24,
								flexDirection: "column",
							}}
						>
							<div
								css={{
									padding: "0px 60px",
									width: "100%",
									boxSizing: "border-box",
								}}
							>
								<Typography
									children="Place"
									variant="overline"
								/>
								<div
									css={{
										display: "flex",
										alignItems: "center",
										gap: 12,
									}}
								>
									{review.place?.photo ? (
										<img
											src={backendMediaUrlParser(
												review.place?.photo
											)}
											alt={`${review.place.name ?? 0}`}
											width={48}
											height={48}
											css={{
												borderRadius: 10,
											}}
										/>
									) : (
										<div
											css={{
												height: 48,
												width: 48,
												backgroundColor:
													theme.palette.background
														.paper,
												borderRadius: 10,
											}}
										/>
									)}
									<Typography
										children={review.place?.name ?? ""}
										variant="subtitle2"
										css={{ flexGrow: 1 }}
									/>
									{review.place?.categories.map(
										(category, index) => {
											return (
												<Typography
													key={`review_category_${review.id}_${category.id}_${index}`}
													children={
														category.name
															.charAt(0)
															.toUpperCase() +
														category.name.slice(1)
													}
													variant="caption"
													css={{
														backgroundColor:
															theme.palette
																.primary.main,
														padding: "10px 12px",
														borderRadius: 20,
														fontWeight: 500,
													}}
												/>
											);
										}
									)}
								</div>
							</div>
							<Divider
								css={{ width: "50%", margin: "12px 0px" }}
							/>
							<div
								css={{
									padding: "0px 60px",
									width: "100%",
									boxSizing: "border-box",
								}}
							>
								<Typography
									children="Reviews"
									variant="overline"
								/>
								<Grid
									container
									spacing={9}
									css={{ paddingTop: 8 }}
								>
									<Evaluation
										howManyStars={review.locationEvaluation}
										title="Location"
										titleIcon={<PinIcon color="#fff" />}
									/>
									<Evaluation
										howManyStars={review.serviceEvaluation}
										title="Service"
										titleIcon={<FaceIcon color="#fff" />}
									/>
									<Evaluation
										howManyStars={review.priceEvaluation}
										title="Price"
										titleIcon={<PriceIcon color="#fff" />}
									/>
								</Grid>
							</div>
						</AccordionDetails>
					</Accordion>
				</TableCell>
			</TableRow>
		);
	}
);
export default ReviewRow;
