/** @jsxImportSource @emotion/react */

import {
  alpha,
  Container,
  Grow,
  IconButton,
  Tab,
  TableCell,
  TableRow,
  Tabs,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CategoryApi, NotificationApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import AdminTable, {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  parseCountFrom,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { PencilEditIcon } from "../../../components/BelVillageIcons";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import useQueryParams from "../../../hooks/useQueryParams";
import { useApi, useFetch } from "../../../utils/api";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const notificationApi = useApi(NotificationApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });

  const { path: matchedPath } = useRouteMatch();
  const params = useQueryParams();
  // console.log("~ ~ params", params.get("section"));

  useEffect(() => {
    drawerDispatch({ open: true });
    headerDispatch({ actionText: "ADD NEW" });
  }, []);

  const [openTab, setOpenTab] = useState(
    params.get("section") === "sent" ? 1 : 0
  );

  const {
    data: notifications,
    loading,
    rawData,
  } = useFetch(
    notificationApi.getAllAdminNotifications,
    transformToStringFrom(filter)
  );

  // console.log("~ ~ notifications", notifications);
  return (
    <Container maxWidth="xl">
      <Grid container direction="column">
        <Grid item xs={3}>
          <Tabs
            value={openTab}
            onChange={(_e, newValue) => {
              if (newValue === 0) {
                history.replace({
                  pathname: history.location.pathname,
                  search: "?section=scheduled",
                });
              } else {
                history.replace({
                  pathname: history.location.pathname,
                  search: "?section=sent",
                });
              }
              setOpenTab(newValue);
            }}
            textColor="primary"
            indicatorColor="primary"
            css={{ color: "#EE7705" }}
            variant="fullWidth"
          >
            <Tab label="Scheduled" value={0} />
            <Tab label="Sent" value={1} />
          </Tabs>
        </Grid>
        <Grid item xs>
          <Grid container spacing={1}>
            <AdminTable
              headCells={headCells}
              fullWidth
              occlusionHeight={60}
              loading={loading}
              queryFilter={[filter, setFilter]}
              count={parseCountFrom(rawData)}
              data={openTab === 0 ? notifications?.unsent : notifications?.sent}
            >
              {(data) => {
                return data?.map((notification, i) => {
                  return (
                    <Grow
                      key={notification?.id}
                      appear={true}
                      in={true}
                      timeout={i * 300 + 300}
                    >
                      <TableRow
                        key={notification?.id}
                        onClick={() => {
                          if (openTab === 1) {
                            history.push(matchedPath + "/" + notification.id);
                          }
                        }}
                      >
                        <TableCell>
                          {notification.notification?.title ||
                          notification.notification?.description ? (
                            <Typography variant="body1" color="textPrimary">
                              {notification.notification?.title ||
                                notification.notification?.description}
                            </Typography>
                          ) : (
                            <Typography
                              children="This notification has no content"
                              variant="overline"
                              css={{ color: alpha("#fff", 0.38) }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {notification.notification?.link ? (
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              children={notification.notification?.link}
                            />
                          ) : (
                            <Typography
                              children="This notification has no link"
                              variant="overline"
                              css={{ color: alpha("#fff", 0.38) }}
                            />
                          )}
                        </TableCell>
                        {openTab === 0 ? (
                          <TableCell
                            css={{ textAlign: "right", paddingRight: 24 }}
                          >
                            <IconButton
                              onClick={() => {
                                history.push(
                                  matchedPath + "/" + notification.id
                                );
                              }}
                              css={{ backgroundColor: alpha("#fff", 0.12) }}
                            >
                              <PencilEditIcon />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell
                            css={{
                              textAlign: "right",
                              paddingRight: 24,
                            }}
                            children={<div css={{ height: 36 }} />}
                          />
                        )}
                      </TableRow>
                    </Grow>
                  );
                });
              }}
            </AdminTable>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const headCells: IHeadCell[] = [
  { id: "name", label: "Name" },
  { id: "link", label: "Link app section" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
