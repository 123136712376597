/** @jsxImportSource @emotion/react */

import { Route, Switch, useRouteMatch } from "react-router";
import { CreateEditView } from "./CreateEditView";
import List from "./List";

function AdvicePage(props: any) {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={path}>
				<List />
			</Route>
			<Route path={path + "/:id"}>
				<CreateEditView parentPath={path} />
			</Route>
		</Switch>
	);
}

export default AdvicePage;
