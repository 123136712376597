/**@jsxImportSource @emotion/react */

import { alpha, Grid, Typography } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";
import {
  AlertIcon,
  WarningTriangleIcon,
} from "../../../../components/BelVillageIcons";
import theme from "../../../../theme";

export type ReportsComponentProps = {
  reports: number;
};

export default function ReportsComponent(props: ReportsComponentProps) {
  return (
    <div
      css={{
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
        borderRadius: 12,
        padding: 8,
        border: `1px solid ${theme.palette.primary.main}`,
        width: 114,
        height: 40,
      }}
    >
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item>
          <WarningTriangleIcon color={theme.palette.primary.main} />
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle2"
            component="span"
            children={props.reports}
            color="primary"
          />
          <Typography
            variant="caption"
            children=" Reports"
            component="span"
            color="primary"
          />
        </Grid>
      </Grid>
    </div>
  );
}
