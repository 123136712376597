/**@jsxImportSource @emotion/react  */

import { Card, CardContent, Slider, Typography } from "@material-ui/core";

type BuildingTemperatureSliderProps = {
  title: string;
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
};
export default function BuildingTemperatureSlider(
  props: BuildingTemperatureSliderProps
) {
  return (
    <Card css={{ borderRadius: 12, marginTop: 24 }}>
      <CardContent css={{ padding: "12px 24px 12px 12px" }}>
        <span>
          <Typography
            children={props.title}
            component="span"
            variant="subtitle2"
          />
          <Typography
            children={`${props.value}°C`}
            component="span"
            variant="h6"
            color="primary"
          />
        </span>
        <Slider
          min={props.min || 14}
          max={props.max || 25}
          step={0.1}
          onChange={(_event, value) => props.onChange(value as number)}
          value={props.value || props.min}
        />
      </CardContent>
    </Card>
  );
}
