"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentEditCreateInputTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ComponentEditCreateInputTypeEnum;
(function (ComponentEditCreateInputTypeEnum) {
    ComponentEditCreateInputTypeEnum["Light"] = "light";
    ComponentEditCreateInputTypeEnum["Fan"] = "fan";
    ComponentEditCreateInputTypeEnum["DimmerableLight"] = "dimmerable_light";
    ComponentEditCreateInputTypeEnum["Thermometer"] = "thermometer";
    ComponentEditCreateInputTypeEnum["Motion"] = "motion";
})(ComponentEditCreateInputTypeEnum = exports.ComponentEditCreateInputTypeEnum || (exports.ComponentEditCreateInputTypeEnum = {}));
