/** @jsxImportSource @emotion/react */
import {
  Card,
  CardContent,
  darken,
  Grid,
  lighten,
  Typography,
} from "@material-ui/core";
import { BuildingConditioningModeEnum } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import DropIcon from "../../../../components/icons/drop";
import FlameIcon from "../../../../components/icons/flame";
import ProhibitionIcon from "../../../../components/icons/prohibition";
import SnowIcon from "../../../../components/icons/snow";
import theme from "../../../../theme";

export type ConditioningModeCardProps = {
  value: BuildingConditioningModeEnum;
  onChange: (selected: BuildingConditioningModeEnum) => void;
};

export default function ConditioningModeCard(props: ConditioningModeCardProps) {
  return (
    <Card css={{ background: "none" }}>
      <CardContent>
        <Typography
          variant="h6"
          children={"Temperature Settings"}
          gutterBottom
        />
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="column">
              <Grid item xs>
                <Typography variant="subtitle2" children="Setting Mode" />
              </Grid>
              <Grid item xs>
                <Grid container spacing={2}>
                  {Object.keys(BuildingConditioningModeEnum).map((key, i) => {
                    return (
                      <Grid item xs={4} key={`conditioning-mode-${i}`}>
                        <ConditioningCard
                          onClick={(selected) => {
                            props.onChange(selected);
                          }}
                          enabled={
                            props.value ===
                            BuildingConditioningModeEnum[
                              key as keyof typeof BuildingConditioningModeEnum
                            ]
                          }
                          mode={
                            BuildingConditioningModeEnum[
                              key as keyof typeof BuildingConditioningModeEnum
                            ]
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

type ModeProps = {
  enabled: boolean;
  mode: BuildingConditioningModeEnum;
  onClick: (selected: BuildingConditioningModeEnum) => void;
};
function ConditioningCard(props: ModeProps) {
  const [icon, setIcon] = useState(<SnowIcon />);

  useEffect(() => {
    switch (props.mode) {
      case BuildingConditioningModeEnum.Cooling:
        setIcon(<SnowIcon color={theme.palette.secondary.main} />);
        break;
      case BuildingConditioningModeEnum.Dehumidifier:
        setIcon(<DropIcon color={theme.palette.secondary.main} />);
        break;
      case BuildingConditioningModeEnum.Heating:
        setIcon(<FlameIcon color={theme.palette.secondary.main} />);
        break;
      case BuildingConditioningModeEnum.NoConditioning:
        setIcon(<ProhibitionIcon color={theme.palette.secondary.main} />);
        break;
    }
  }, [props.mode, props.enabled]);

  return (
    <Card
      onClick={() => {
        props.onClick(props.mode);
      }}
      css={{
        backgroundColor: props.enabled
          ? theme.palette.primary.main
          : darken(theme.palette.background.paper, 0.435),
        ":hover": {
          backgroundColor: props.enabled
            ? lighten(theme.palette.primary.main, 0.2)
            : theme.palette.background.paper,
        },
        transition: "0.3s",
      }}
      elevation={0}
    >
      <CardContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography children={props.mode} variant="subtitle2" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
