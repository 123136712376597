import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function ArrowDownIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "24"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 24"
		>
			<path
				fill={color || "#EF7705"}
				d="M17.855 7.285a1.011 1.011 0 111.43 1.43l-6.578 6.578a1 1 0 01-1.414 0L4.715 8.715a1.011 1.011 0 111.43-1.43L12 13.139l5.855-5.854z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default ArrowDownIcon;
