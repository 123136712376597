/** @jsxImportSource @emotion/react */

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { AxiosError } from "axios";
import {
  Component,
  ComponentApi,
  ComponentEditCreateInput,
  ComponentEditCreateInputTypeEnum,
  ComponentTypeEnum,
  EditOrCreateAirConditioningModel,
  PresetApi,
  Room,
  RoomApi,
  RoomTypeEnum,
} from "belvillage-api-manager";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DropEvent, FileRejection } from "react-dropzone";
import { useHistory, useParams } from "react-router";
import {
  BinIcon,
  DoneIcon,
  LightIcon,
  PlusIcon,
  VisibleBaseIcon,
} from "../../../components/BelVillageIcons";
import { DialogClosableProps } from "../../../components/DialogClosable";
import DocumentPicker from "../../../components/DocumentPicker";
import MotionIcon from "../../../components/icons/motion";
import ThermometerIcon from "../../../components/icons/thermometer";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import {
  apiErrorParser,
  backendMediaUrlParser,
  DeepKeyof,
  getObjectDeepValues,
} from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";
import DialogEditAddAirConditioning from "./components/DialogEditAddAirConditioning";
import RoomAirConditioning from "./components/RoomAirConditioning";

const initialState: Omit<Omit<Room, "building">, "customSettings"> = {
  id: "",
  name: "",
  externalId: "",
  doorHsycoId: "",
  type: RoomTypeEnum.Private,
  image: "",
};

const initialConditioningState: EditOrCreateAirConditioningModel = {
  hsycoId: "",
  typeOfAirConditioning: undefined,
};
const initialComponentState: ComponentEditCreateInput = {
  hsycoId: "",
  name: "",
  room: "",
  type: ComponentEditCreateInputTypeEnum.DimmerableLight,
  additionalConfig: {
    offset: 0,
  },
};

export function CreateEditView(props: { parentPath: string }) {
  const history = useHistory();
  const { id, buildingId } = useParams<any>();

  const theme = useTheme();

  const roomApi = useApi(RoomApi);
  const componentsApi = useApi(ComponentApi);
  const presetsApi = useApi(PresetApi);

  const { data, loading } = useFetch(roomApi.getRoomById, id, { skip: !id });
  const {
    data: airConditioningData,
    loading: airConditioningLoading,
    error: _airConditioningError,
    revalidate: revalidateAirConditioning,
  } = useFetch(roomApi.getAdminRoomAirConditioning, id, { skip: !id });

  const { data: dataComponents, revalidate: revalidateComponents } = useFetch(
    componentsApi.allRoomComponents,
    id,
    {
      skip: !id,
    }
  );

  const { data: allRoomPresets, revalidate: revalidatePresets } = useFetch(
    presetsApi.allRoomPresets,
    id,
    { skip: !id }
  );

  const { data: checkinCodeData, revalidate: revalidateCheckinCode } = useFetch(
    roomApi.generateCheckInCode,
    id,
    { skip: !id }
  );

  const [state, dispatch] = useFormInputReducer(initialState);

  const [open, setOpen] = useState(false);
  const [lightId, setLightId] = useState<string | undefined>();

  const [openCheckInCodeDialog, setOpenCheckInCodeDialog] = useState(false);

  const [openNewAirConditioning, setOpenNewAirCondition] =
    useState<boolean>(false);
  const [loadingNewAirConditioning, setLoadingNewAirCondition] =
    useState<boolean>(false);

  const [deleteRoomDialogOpen, setDeleteRoomDialogOpen] = useState(false);

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  useEffect(() => {
    drawerDispatch({ open: true, editMode: true, editModelName: "rooms" });
    headerDispatch({
      actionVisible: true,
      actionText: "CheckIn code",
      actionIcon: VisibleBaseIcon,
      actionOnClick: () => {
        if (id) {
          roomApi.generateCheckInCode(id).then((res) => {
            setOpenCheckInCodeDialog(true);
          });
        } else {
          MLDialog.showSnackbar("Wait for room to be loaded");
        }
      },
    });
  }, []);

  const onDrop = useCallback<
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => void
  >((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file: File) => {
      // onEventStateChange({ image: file });
      dispatch({ field: "image", value: file });
    });

    fileRejections.forEach((fileRejection) => {
      MLDialog.showSnackbar("Failed to upload file", {
        variant: "error",
        autoHideDuration: 3000,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const initialKeys = Object.keys(initialState);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      dispatch({
        field: "UPDATE_STATE",
        value: availableDataFromInitialState as typeof initialState,
      });
    }
  }, [data, dispatch]);

  function saveDataToBackend(exit?: boolean) {
    const { id, ...others } = state;

    if (id) {
      //! EDIT
      roomApi
        .editRoom(
          id,
          others.name,
          others.type,
          others.image,
          others.doorHsycoId,
          undefined,
          others.externalId as string
        )
        .then((value) => {
          if (exit) history.push(props.parentPath);
          else
            MLDialog.showSnackbar("Saved successfully", {
              variant: "success",
            });
        })
        .catch((error: AxiosError) => {
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
        });
    } else {
      //! ADD
      roomApi
        .createRoom(
          buildingId,
          others.name,
          others.type,
          others.image,
          others.doorHsycoId,
          undefined,
          others.externalId as string
        )
        .then((value) => {
          MLDialog.showSnackbar("Created successfully", {
            variant: "success",
          });
          history.replace(props.parentPath + "/" + value.data?.id);
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    }
  }

  if ((!state || loading || airConditioningLoading || !data) && Boolean(id)) {
    return (
      <Grid container alignItems="center" css={{ height: "100%" }}>
        <Grid container justifyContent="center">
          <CircularProgress size={60} variant="indeterminate" color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      alignContent="flex-start"
      css={{
        padding: "26px 48px",
      }}
    >
      <Grid item xs>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grow appear={true} in={true}>
              <Card>
                <CardContent
                  css={{
                    ">*": {
                      marginBottom: 24,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    children={(id ? "Edit" : "New") + " Room"}
                    gutterBottom
                  />
                  {!id && (
                    <FormControl>
                      <FormLabel id="room-type-label">Room Type</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="room-type-label"
                        name="room-type-radio"
                        {...valueChangeReducerHelper("type")}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value={RoomTypeEnum.Private}
                          label={RoomTypeEnum.Private}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={RoomTypeEnum.Public}
                          label={RoomTypeEnum.Public}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  <Grid container direction="row" spacing={2}>
                    {data?.type === "public" && (
                      <Grid item xs={6}>
                        {/* <div
                        css={{
                          width: "100%",
                          minHeight: "150px",
                          border: "1px solid red",
                        }}
                      /> */}

                        <DocumentPicker
                          onDrop={onDrop}
                          title="Upload room image"
                          accept="image/*"
                          border
                          css={{
                            backgroundImage:
                              state?.image &&
                              `url("${
                                typeof state?.image === "string"
                                  ? backendMediaUrlParser(state?.image)
                                  : URL.createObjectURL(state?.image)
                              }")`,
                            backgroundPosition: "50% 50%",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            marginTop: 0,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={data?.type === "public" ? 6 : 12}>
                      <TextField
                        {...valueChangeReducerHelper("name")}
                        label="Room name"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {state.type === RoomTypeEnum.Private && [
                    <TextField
                      {...valueChangeReducerHelper("doorHsycoId")}
                      label="Door Hsyco id"
                      fullWidth
                      variant="outlined"
                    />,
                    <TextField
                      {...valueChangeReducerHelper("externalId")}
                      label="Kross booking id"
                      fullWidth
                      variant="outlined"
                    />,
                  ]}

                  {id && renderRoomLights()}
                  {id &&
                  state.type === RoomTypeEnum.Private &&
                  airConditioningData ? (
                    <RoomAirConditioning
                      airConditioningData={airConditioningData}
                      onNew={() => {
                        setOpenNewAirCondition(true);
                      }}
                      onDelete={(id) => {
                        roomApi
                          .deleteAirConditioning(id)
                          .then(() => {
                            MLDialog.showSnackbar(
                              "Air conditioning deleted successfully",
                              { variant: "success" }
                            );
                            revalidateAirConditioning();
                          })
                          .catch((err) => {
                            MLDialog.showSnackbar(
                              err.message || "UNKNOWN ERROR",
                              {
                                variant: "error",
                              }
                            );
                          });
                      }}
                      onSave={(airConditioningId, airConditioningEditData) => {
                        roomApi
                          .editAdminRoomAirConditioning(
                            airConditioningId,
                            airConditioningEditData
                          )
                          .then(() => {
                            MLDialog.showSnackbar(
                              "Air conditioning updated successfully",
                              { variant: "success" }
                            );
                            revalidateAirConditioning();
                          })
                          .catch((err) => {
                            MLDialog.showSnackbar(
                              err.message || "UNKNOWN ERROR",
                              {
                                variant: "error",
                              }
                            );
                          });
                      }}
                    />
                  ) : null}

                  {/* <Grid item xs>
                    <LightPreset
                      roomPresets={allRoomPresets || []}
                      roomComponents={dataComponents || []}
                      onSave={(preset) => {}}
                      onDelete={() => {}}
                      tmpPresetId="tmp_preset"
                    />
                  </Grid> */}
                  <Grid
                    container
                    justifyContent={id ? "space-between" : "flex-end"}
                  >
                    {id && (
                      <Button
                        variant="text"
                        children="Delete"
                        startIcon={<BinIcon />}
                        onClick={() => {
                          setDeleteRoomDialogOpen(true);
                        }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      children="Confirm"
                      startIcon={<DoneIcon />}
                      onClick={() => {
                        console.log("%cSAVING!", "color: red");

                        saveDataToBackend(true);
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
      <DialogEditAddLight
        lightId={lightId}
        open={open}
        onClose={() => {
          setOpen(false);
          setLightId(undefined);
          revalidateComponents();
        }}
      />
      <DialogEditAddAirConditioning
        open={openNewAirConditioning}
        onClose={() => {
          setOpenNewAirCondition(false);
        }}
        loading={loadingNewAirConditioning}
        onSave={(airConditioningData) => {
          setLoadingNewAirCondition(true);
          roomApi
            .createAdminRoomAirConditioning(id, airConditioningData)
            .then(() => {
              MLDialog.showSnackbar("Air conditioning created successfully.", {
                variant: "success",
              });
              setOpenNewAirCondition(false);
              revalidateAirConditioning();
            })
            .catch((err) => {
              MLDialog.showSnackbar(err.message || "UNKNOWN ERROR", {
                variant: "error",
              });
            })
            .finally(() => {
              setLoadingNewAirCondition(false);
            });
        }}
      />
      <Dialog
        open={openCheckInCodeDialog}
        onClose={() => {
          setOpenCheckInCodeDialog(false);
        }}
      >
        <DialogTitle>Check-in Code</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            children={checkinCodeData}
            css={{ wordBreak: "break-all" }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item css={{ flex: 1 }}>
              <Button
                children="Close"
                onClick={() => {
                  setOpenCheckInCodeDialog(false);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                children="Copy code"
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!navigator?.clipboard) {
                    MLDialog.showSnackbar("Cannot copy into clipboard", {
                      variant: "warning",
                    });
                    return;
                  }
                  try {
                    if (checkinCodeData) {
                      navigator.clipboard
                        ?.writeText(checkinCodeData)
                        .then(() => {
                          MLDialog.showSnackbar("Code copied!", {
                            variant: "success",
                          });
                        });
                    }
                  } catch (err) {
                    MLDialog.showSnackbar("Error:" + err, {
                      variant: "error",
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteRoomDialogOpen}
        onClose={() => {
          setDeleteRoomDialogOpen(false);
        }}
      >
        <DialogTitle>Delete Room</DialogTitle>
        <DialogContent>
          <Typography children="Are you sure you want to delete this room?" />
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item css={{ flex: 1 }}>
              <Button
                children="Cancel"
                onClick={() => {
                  setDeleteRoomDialogOpen(false);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                children="Confirm"
                onClick={() => {
                  roomApi
                    .deleteRoom(id)
                    .then(() => {
                      history.goBack();
                    })
                    .catch((error) =>
                      MLDialog.showSnackbar(apiErrorParser(error), {
                        variant: "error",
                      })
                    );
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );

  function renderRoomLights() {
    return (
      <Grid container>
        <Grid container justifyContent="space-between">
          <Typography variant="overline" color="textSecondary">
            COMPONENTS
          </Typography>
          <Button
            variant="text"
            color="primary"
            size="small"
            css={{
              textTransform: "initial",
              fontSize: 14,
              fontWeight: 400,
            }}
            endIcon={<PlusIcon color={theme.palette.primary.main} />}
            onClick={() => {
              setOpen(true);
              setLightId(undefined);
            }}
          >
            Add new component
          </Button>
        </Grid>
        <Grid container css={{ marginTop: 24 }}>
          {(dataComponents || []).map((x) => {
            return (
              <ComponentHandler
                key={x.id}
                comp={x}
                onClick={() => {
                  setLightId(x?.id);
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    );
  }

  function valueChangeReducerHelper(field: keyof typeof initialState) {
    return {
      value: state[field],
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
}

function ComponentHandler(props: { comp: Component; onClick: () => void }) {
  const theme = useTheme();
  const [active, setActive] = useState(false);
  const [type, setType] = useState("");
  const componentApi = useApi(ComponentApi);

  const intervalId = useRef<any>();

  useEffect(() => {
    intervalId.current = setInterval(() => {
      componentApi
        .getCurrentStatusById(props?.comp?.id || "")
        .then((response) => {
          const value = response?.data?.value;
          // console.log("VALUE", value);
          setActive(value != "off" && value != "0" && value != undefined);
          setType(response.data?.type);
        })
        .catch((error) => console.log("ERROR", error));
    }, 1000);
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <Grid
      item
      xs={6}
      md={4}
      css={{
        borderRadius: 20,
        cursor: "pointer",
        border: "1px solid rgba(255, 255, 255, 0)",
        ":hover": {
          border: "1px solid rgba(255, 255, 255, 0.12)",
          background: "rgba(255, 255, 255, 0.12)",
        },
      }}
      onClick={() => {
        props?.onClick?.();
      }}
    >
      {Boolean(type) ? (
        <Grid container justifyContent="center" css={{ padding: 16 }}>
          <Grid container justifyContent="center" css={{ marginBottom: 16 }}>
            {returnCorrectIcon(type as ComponentTypeEnum, active)}
          </Grid>
          <Typography variant="subtitle2">{props?.comp.name}</Typography>
        </Grid>
      ) : (
        <Grid container justifyContent="center" css={{ padding: 16 }}>
          <Typography children="loading..." />
        </Grid>
      )}
    </Grid>
  );

  function returnCorrectIcon(
    componentType: typeof ComponentTypeEnum[keyof typeof ComponentTypeEnum],
    isComponentActive: boolean
  ) {
    switch (componentType) {
      case "light":
      case "dimmerable_light":
        return (
          <LightIcon
            color={isComponentActive ? theme.palette.primary.main : "white"}
          />
        );
      case "motion":
        return (
          <MotionIcon
            color={isComponentActive ? theme.palette.primary.main : "white"}
          />
        );
      case "thermometer":
        return (
          <ThermometerIcon
            color={isComponentActive ? theme.palette.primary.main : "white"}
          />
        );

      default:
        break;
    }
  }
}

interface DialogEditAddLightProps extends DialogClosableProps {
  lightId?: string;
}

const initialStateComponent: ComponentEditCreateInput & { id: string } = {
  id: "",
  name: "",
  hsycoId: "",
  type: ComponentEditCreateInputTypeEnum.Light,
  room: "",
  additionalConfig: {
    offset: 0,
  },
};
const DialogEditAddLight = (props: DialogEditAddLightProps) => {
  const { lightId } = props;

  const { id: roomId } = useParams<any>();

  const componentApi = useApi(ComponentApi);

  const { data, loading, revalidate } = useFetch(
    componentApi.getComponentById,
    lightId || "",
    { skip: !lightId }
  );

  useEffect(() => {
    reset();
    revalidate();
  }, [lightId]);

  const [state, dispatch] = useFormInputReducer(initialStateComponent);

  useEffect(() => {
    if (data) {
      const initialKeys = Object.keys(initialStateComponent);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      dispatch({
        field: "UPDATE_STATE",
        value: availableDataFromInitialState as typeof initialStateComponent,
      });
    }
  }, [data, dispatch]);

  function reset() {
    dispatch({ field: "UPDATE_STATE", value: { ...initialStateComponent } });
  }

  function saveDataToBackend(exit?: boolean) {
    const { id, ...others } = state;

    if (id) {
      //! EDIT
      componentApi
        .editComponent(id, { ...others })
        .then((value) => {
          props?.onClose?.();
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    } else {
      //! ADD
      componentApi
        .addComponent(roomId, { ...others })
        .then((value) => {
          props?.onClose?.();
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    }
  }

  return (
    <Dialog
      fullWidth
      {...props}
      open={props?.open || props?.lightId !== undefined}
    >
      <DialogTitle
        children={
          <Typography
            variant="h6"
            children={(props?.lightId ? "Edit" : "New") + " Component"}
          />
        }
      />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              {...valueChangeReducerHelper("name")}
              label="Component name"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <FormControl css={{ width: "100%" }}>
              <InputLabel
                id="select-component-type"
                css={{ top: -5, left: 15 }}
              >
                Component Type
              </InputLabel>
              <Select
                labelId="select-component-type"
                {...valueChangeReducerHelper("type")}
                fullWidth
                variant="outlined"
                label="Component Type"
              >
                <MenuItem value={ComponentEditCreateInputTypeEnum.Light}>
                  On/Off Light
                </MenuItem>
                <MenuItem
                  value={ComponentEditCreateInputTypeEnum.DimmerableLight}
                >
                  Dimmerable Light
                </MenuItem>
                <MenuItem value={ComponentEditCreateInputTypeEnum.Motion}>
                  Motion Sensor
                </MenuItem>
                <MenuItem value={ComponentEditCreateInputTypeEnum.Thermometer}>
                  Thermometer
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              {...valueChangeReducerHelper("hsycoId")}
              label="Hsyco id"
              fullWidth
              variant="outlined"
            />
          </Grid>
          {state.type === ComponentEditCreateInputTypeEnum.Thermometer && (
            <Grid item>
              <TextField
                value={state.additionalConfig?.offset}
                onChange={(e) =>
                  dispatch({
                    field: "additionalConfig",
                    value: {
                      ...state.additionalConfig,
                      offset: e.target.value,
                    },
                  })
                }
                label="Offset"
                fullWidth
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={lightId ? "space-between" : "flex-end"}>
          {lightId && (
            <Button
              variant="text"
              children="Delete"
              startIcon={<BinIcon />}
              onClick={() => {
                componentApi
                  .deleteComponent(lightId)
                  .then(() => {
                    props?.onClose();
                  })
                  .catch((error) =>
                    MLDialog.showSnackbar(apiErrorParser(error), {
                      variant: "error",
                    })
                  );
              }}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            children="Confirm"
            startIcon={<DoneIcon />}
            onClick={() => {
              saveDataToBackend(true);
            }}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );

  function valueChangeReducerHelper(
    field: DeepKeyof<typeof initialStateComponent>
  ) {
    return {
      value: getObjectDeepValues(state, field),
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
};
