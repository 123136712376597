/** @jsxImportSource @emotion/react */

import { Card, CardContent, Container, Grow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Building, BuildingApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { InfoIcon } from "../../../components/BelVillageIcons";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { useApi, useFetch } from "../../../utils/api";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const buildingApi = useApi(BuildingApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
    perPage: 1000,
  });

  const { path: matchedPath } = useRouteMatch();

  useEffect(() => {
    drawerDispatch({ open: true });
    headerDispatch({ actionText: "ADD NEW" });
  }, []);

  const { data: buildings } = useFetch(
    buildingApi.allAdminBuildings,
    transformToStringFrom(filter)
  );

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid container spacing={4}>
          {buildings?.map((building, i) => {
            return (
              <Grow
                key={building?.id}
                appear={true}
                in={true}
                timeout={i * 300 + 300}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={building?.id}
                  css={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/" + building.id + "/rooms");
                  }}
                >
                  <Card
                    css={{
                      ":hover": {
                        backgroundColor: "rgba(255,255,255, 0.12)",
                      },
                    }}
                  >
                    <CardContent>
                      {renderHeader(building)}
                      {renderAddress(building)}

                      {renderAlerts()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grow>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );

  function renderAlerts() {
    return (
      <Grid container css={{ marginTop: 36, marginBottom: 16 }}>
        <InfoIcon />
        <Typography
          css={{ marginLeft: 8 }}
          variant="body2"
          color="textSecondary"
          children="There aren’t new report"
        />
      </Grid>
    );
  }

  function renderAddress(building: Building) {
    return (
      <Typography
        variant="body1"
        color="textSecondary"
        css={{ fontWeight: 400 }}
      >
        {building.place?.address || ""}
      </Typography>
    );
  }

  function renderHeader(building: Building) {
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="textPrimary">
          {building.name}
        </Typography>

        <CircledEditIcon
          onClick={() => history.push(matchedPath + "/" + building.id)}
        />
      </Grid>
    );
  }
}

const headCells: IHeadCell[] = [
  { id: "name", label: "Name" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
