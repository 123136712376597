"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentApi = exports.ComponentApiFactory = exports.ComponentApiFp = exports.ComponentApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * ComponentApi - axios parameter creator
 * @export
 */
exports.ComponentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add component
         * @param {string} roomId
         * @param {ComponentEditCreateInput} componentEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComponent: (roomId, componentEditCreateInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'roomId' is not null or undefined
            common_1.assertParamExists('addComponent', 'roomId', roomId);
            // verify required parameter 'componentEditCreateInput' is not null or undefined
            common_1.assertParamExists('addComponent', 'componentEditCreateInput', componentEditCreateInput);
            const localVarPath = `/admin/component/room/{roomId}/`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(componentEditCreateInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary All components in room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allComponentsInRoom: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/component/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary All room components
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoomComponents: (roomId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'roomId' is not null or undefined
            common_1.assertParamExists('allRoomComponents', 'roomId', roomId);
            const localVarPath = `/admin/component/room/{roomId}/`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete component
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComponent: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteComponent', 'id', id);
            const localVarPath = `/admin/component/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit component
         * @param {string} id
         * @param {ComponentEditCreateInput} componentEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editComponent: (id, componentEditCreateInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editComponent', 'id', id);
            // verify required parameter 'componentEditCreateInput' is not null or undefined
            common_1.assertParamExists('editComponent', 'componentEditCreateInput', componentEditCreateInput);
            const localVarPath = `/admin/component/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(componentEditCreateInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get component by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getComponentById', 'id', id);
            const localVarPath = `/admin/component/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get current status by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentStatusById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getCurrentStatusById', 'id', id);
            const localVarPath = `/component/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user domotic item schedule
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDomoticItemSchedule: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserDomoticItemSchedule', 'id', id);
            const localVarPath = `/component/{id}/schedule/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary On off component
         * @param {InputOnOffComponent} inputOnOffComponent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onOffComponent: (inputOnOffComponent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputOnOffComponent' is not null or undefined
            common_1.assertParamExists('onOffComponent', 'inputOnOffComponent', inputOnOffComponent);
            const localVarPath = `/component/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputOnOffComponent, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set status
         * @param {string} id
         * @param {InputSetStatus} inputSetStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatus: (id, inputSetStatus, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('setStatus', 'id', id);
            // verify required parameter 'inputSetStatus' is not null or undefined
            common_1.assertParamExists('setStatus', 'inputSetStatus', inputSetStatus);
            const localVarPath = `/component/{id}/setStatus/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputSetStatus, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary User remove domotic item schedule
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRemoveDomoticItemSchedule: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('userRemoveDomoticItemSchedule', 'id', id);
            const localVarPath = `/component/{id}/schedule/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary User schedule component
         * @param {string} id
         * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userScheduleComponent: (id, editOrCreateDomoticItemSchedule, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('userScheduleComponent', 'id', id);
            const localVarPath = `/component/{id}/schedule/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editOrCreateDomoticItemSchedule, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ComponentApi - functional programming interface
 * @export
 */
exports.ComponentApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.ComponentApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add component
         * @param {string} roomId
         * @param {ComponentEditCreateInput} componentEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComponent(roomId, componentEditCreateInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addComponent(roomId, componentEditCreateInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary All components in room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allComponentsInRoom(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allComponentsInRoom(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary All room components
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoomComponents(roomId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allRoomComponents(roomId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete component
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComponent(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteComponent(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit component
         * @param {string} id
         * @param {ComponentEditCreateInput} componentEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editComponent(id, componentEditCreateInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editComponent(id, componentEditCreateInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get component by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getComponentById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get current status by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentStatusById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCurrentStatusById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user domotic item schedule
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDomoticItemSchedule(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserDomoticItemSchedule(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary On off component
         * @param {InputOnOffComponent} inputOnOffComponent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onOffComponent(inputOnOffComponent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.onOffComponent(inputOnOffComponent, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set status
         * @param {string} id
         * @param {InputSetStatus} inputSetStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatus(id, inputSetStatus, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setStatus(id, inputSetStatus, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary User remove domotic item schedule
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRemoveDomoticItemSchedule(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.userRemoveDomoticItemSchedule(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary User schedule component
         * @param {string} id
         * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userScheduleComponent(id, editOrCreateDomoticItemSchedule, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.userScheduleComponent(id, editOrCreateDomoticItemSchedule, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ComponentApi - factory interface
 * @export
 */
exports.ComponentApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.ComponentApiFp(configuration);
    return {
        /**
         *
         * @summary Add component
         * @param {string} roomId
         * @param {ComponentEditCreateInput} componentEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComponent(roomId, componentEditCreateInput, options) {
            return localVarFp.addComponent(roomId, componentEditCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary All components in room
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allComponentsInRoom(options) {
            return localVarFp.allComponentsInRoom(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary All room components
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoomComponents(roomId, options) {
            return localVarFp.allRoomComponents(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete component
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComponent(id, options) {
            return localVarFp.deleteComponent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit component
         * @param {string} id
         * @param {ComponentEditCreateInput} componentEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editComponent(id, componentEditCreateInput, options) {
            return localVarFp.editComponent(id, componentEditCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get component by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentById(id, options) {
            return localVarFp.getComponentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get current status by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentStatusById(id, options) {
            return localVarFp.getCurrentStatusById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user domotic item schedule
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDomoticItemSchedule(id, options) {
            return localVarFp.getUserDomoticItemSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary On off component
         * @param {InputOnOffComponent} inputOnOffComponent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onOffComponent(inputOnOffComponent, options) {
            return localVarFp.onOffComponent(inputOnOffComponent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set status
         * @param {string} id
         * @param {InputSetStatus} inputSetStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatus(id, inputSetStatus, options) {
            return localVarFp.setStatus(id, inputSetStatus, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary User remove domotic item schedule
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRemoveDomoticItemSchedule(id, options) {
            return localVarFp.userRemoveDomoticItemSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary User schedule component
         * @param {string} id
         * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userScheduleComponent(id, editOrCreateDomoticItemSchedule, options) {
            return localVarFp.userScheduleComponent(id, editOrCreateDomoticItemSchedule, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ComponentApi - object-oriented interface
 * @export
 * @class ComponentApi
 * @extends {BaseAPI}
 */
class ComponentApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add component
     * @param {string} roomId
     * @param {ComponentEditCreateInput} componentEditCreateInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    addComponent(roomId, componentEditCreateInput, options) {
        return exports.ComponentApiFp(this.configuration).addComponent(roomId, componentEditCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary All components in room
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    allComponentsInRoom(options) {
        return exports.ComponentApiFp(this.configuration).allComponentsInRoom(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary All room components
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    allRoomComponents(roomId, options) {
        return exports.ComponentApiFp(this.configuration).allRoomComponents(roomId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete component
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    deleteComponent(id, options) {
        return exports.ComponentApiFp(this.configuration).deleteComponent(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit component
     * @param {string} id
     * @param {ComponentEditCreateInput} componentEditCreateInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    editComponent(id, componentEditCreateInput, options) {
        return exports.ComponentApiFp(this.configuration).editComponent(id, componentEditCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get component by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    getComponentById(id, options) {
        return exports.ComponentApiFp(this.configuration).getComponentById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get current status by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    getCurrentStatusById(id, options) {
        return exports.ComponentApiFp(this.configuration).getCurrentStatusById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user domotic item schedule
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    getUserDomoticItemSchedule(id, options) {
        return exports.ComponentApiFp(this.configuration).getUserDomoticItemSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary On off component
     * @param {InputOnOffComponent} inputOnOffComponent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    onOffComponent(inputOnOffComponent, options) {
        return exports.ComponentApiFp(this.configuration).onOffComponent(inputOnOffComponent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set status
     * @param {string} id
     * @param {InputSetStatus} inputSetStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    setStatus(id, inputSetStatus, options) {
        return exports.ComponentApiFp(this.configuration).setStatus(id, inputSetStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary User remove domotic item schedule
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    userRemoveDomoticItemSchedule(id, options) {
        return exports.ComponentApiFp(this.configuration).userRemoveDomoticItemSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary User schedule component
     * @param {string} id
     * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    userScheduleComponent(id, editOrCreateDomoticItemSchedule, options) {
        return exports.ComponentApiFp(this.configuration).userScheduleComponent(id, editOrCreateDomoticItemSchedule, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ComponentApi = ComponentApi;
