import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function SuccessIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize}
			height={iconSize}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 25"
		>
			<g filter="url(#filter0_d_1116_1768)">
				<path
					fill={color}
					d="M20.78 5.915a.75.75 0 010 1.06L10.694 17.062a2.75 2.75 0 01-3.889 0L3.22 13.476a.75.75 0 111.06-1.061l3.586 3.586a1.25 1.25 0 001.768 0L19.72 5.915a.75.75 0 011.06 0z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_d_1116_1768"
					width="28"
					height="28"
					x="-2"
					y="-0.805"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="1"></feOffset>
					<feGaussianBlur stdDeviation="1"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1116_1768"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_1116_1768"
						result="shape"
					></feBlend>
				</filter>
			</defs>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default SuccessIcon;
