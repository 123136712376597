/**@jsxImportSource @emotion/react  */
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Account, Event } from "belvillage-api-manager";
import moment from "moment";
import ClockIcon from "../../../../components/icons/clock";
import PinIcon from "../../../../components/icons/pin";
import theme from "../../../../theme";
import { backendMediaUrlParser } from "../../../../utils";

export type EventDisplayerProps = {
	event: Event;
};

export function EventDisplayer(props: EventDisplayerProps) {
	return (
		<Card>
			<CardContent>
				<Grid
					container
					css={{ width: "100%" }}
					direction="column"
					spacing={2}
				>
					<Grid item>
						<Grid container direction="column">
							<Grid item>
								<Typography
									children={props.event.name}
									variant="subtitle1"
								/>
							</Grid>
							<Grid
								item
								css={{
									display: "flex",
									gap: 8,
									alignItems: "center",
									height: 18,
									paddingBottom: 2,
								}}
							>
								<ClockIcon
									color="#ffffff"
									opacity={60}
									size={16}
								/>
								<Typography
									children={moment(
										props.event.time.start
									).format("MMMM, dddd Do, hh:mma")}
									variant="body2"
									css={{ opacity: 0.6 }}
								/>
							</Grid>
							<Grid
								item
								css={{
									display: "flex",
									gap: 8,
									alignItems: "center",
									height: 18,
								}}
							>
								<PinIcon
									color="#ffffff"
									opacity={60}
									size={16}
								/>
								<Typography
									children={props.event.name}
									variant="body2"
									css={{ opacity: 0.6 }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs css={{ flex: 1 }}>
						<div
							css={{
								width: "100%",
								height: 1,
								backgroundColor: "rgba(255, 255, 255, 0.12)",
							}}
						/>
					</Grid>
					<Grid item>
						<Typography
							children={props.event.description}
							variant="body1"
							css={{ paddingBottom: 16 }}
						/>
						<div
							css={{
								height: 184,
								backgroundColor: "black",
								backgroundImage: `url(${backendMediaUrlParser(
									props.event.image
								)})`,
								borderRadius: 10,
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						/>
					</Grid>
					<Grid item xs css={{ flex: 1 }}>
						<div
							css={{
								width: "100%",
								height: 1,
								backgroundColor: "rgba(255, 255, 255, 0.12)",
							}}
						/>
					</Grid>
					<Grid
						container
						alignItems="center"
						justifyContent="flex-start"
					>
						<Grid item css={{ flex: 1, height: 40 }}>
							<UserSection user={props.event.owner} />
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function UserSection(props: { user?: Account; createdAt?: string }) {
	return (
		<Grid
			container
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			css={{ width: "100%" }}
		>
			<Grid item>
				<div
					css={{
						width: 40,
						height: 40,
						borderRadius: "100%",
						border: `1px solid ${theme.palette.primary.main} `,
						backgroundImage: `url(${backendMediaUrlParser(
							props.user?.profile_image
						)})`,
						backgroundPosition: "center",
						backgroundSize: "contain",
					}}
				/>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="column"
					alignItems="flex-start"
					justifyContent="center"
				>
					<Grid item>
						<Typography
							children={props.user?.name}
							variant="subtitle2"
							component="p"
						/>
					</Grid>
					<Grid item>
						<Typography
							variant="caption"
							color="textPrimary"
							children={moment(props.createdAt).format(
								"gg/mm/yyyy [at] hh:mma"
							)}
							component="p"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
