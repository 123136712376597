import { AxiosError } from "axios";
import { EventApi } from "belvillage-api-manager";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createContext, useContextSelector } from "use-context-selector";
import MLDialog from "../../../../components/poppers";
import { useFormInputReducer } from "../../../../hooks/useAdminReducer";
import { apiErrorParser } from "../../../../utils";
import { useApi, useFetch } from "../../../../utils/api";
import { EventInitialState } from "../interfaces/EventInitialState";

const initialState: EventInitialState = {
	id: "",
	name: "",
	description: "",
	room: undefined,
	time: {},
};

export function useEventController(parentPath: string) {
	const history = useHistory();
	const { buildingId, id } = useParams<any>();

	const eventApi = useApi(EventApi);
	const {
		data: event,
		loading: eventLoading,
		revalidate: revalidateEvent,
	} = useFetch(eventApi.getAdminEventById, id, {
		skip: !id,
	});

	const [eventEditState, dispatch] = useFormInputReducer(initialState);

	useEffect(() => {
		if (event) {
			const availableDataFromInitialState: EventInitialState = {
				id: event.id,
				name: event.name,
				description: event.description,
				room: event.room as unknown as string,
				date: event.time.start,
				time: { start: event.time.start, end: event.time.end },
			};

			dispatch({
				field: "UPDATE_STATE",
				value: availableDataFromInitialState as typeof initialState,
			});
		}
	}, [event, dispatch]);

	const onEventStateChange = useCallback(
		(data: Partial<EventInitialState>) => {
			for (const tmpKey in data) {
				dispatch({
					field: tmpKey as keyof EventInitialState,
					value: data[tmpKey as keyof EventInitialState],
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	function saveDataToBackend(exit?: boolean) {
		const { id, ...others } = eventEditState;

		const updateImage = () => {
			if (typeof eventEditState.image !== "string")
				return eventApi.updateEventImage(id, eventEditState.image);
		};

		if (
			others.date &&
			others.room &&
			others.time.start &&
			others.time.end
		) {
			if (id) {
				//! EDIT
				return Promise.all([
					eventApi.editAdminEvent(id, {
						name: others.name,
						description: others.description,
						date: others.date,
						timeData: {
							startTime: others.time.start,
							endTime: others.time.end,
						},
						places: [others.room],
					}),
					updateImage(),
				])
					.then(() => {
						if (exit) history.push(parentPath);
						else
							MLDialog.showSnackbar("Saved successfully", {
								variant: "success",
							});
					})
					.catch((error: AxiosError) =>
						MLDialog.showSnackbar(apiErrorParser(error), {
							variant: "error",
						})
					);
			} else {
				//! ADD
				return eventApi
					.createAdminEvent({
						name: others.name,
						description: others.description,
						date: others.date,
						timeData: {
							startTime: others.time.start,
							endTime: others.time.end,
						},
						places: [others.room],
					})
					.then((value) => {
						MLDialog.showSnackbar("Created successfully", {
							variant: "success",
						});
						history.replace(
							`/${buildingId}/events/${value.data?.id}`
						);
					})
					.catch((error: AxiosError) => {
						console.log(error);
						MLDialog.showSnackbar(apiErrorParser(error), {
							variant: "error",
						});
					});
			}
		}
	}

	const deleteEvent = useCallback(() => {
		return eventApi
			.deleteAdminEvent(id)
			.then((value) => {
				MLDialog.showSnackbar(
					`Event ${event?.name ?? ""} deleted successfully`,
					{
						variant: "success",
					}
				);
				history.replace(parentPath.replace(":buildingId", buildingId));
			})
			.catch((error: AxiosError) =>
				MLDialog.showSnackbar(apiErrorParser(error), {
					variant: "error",
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event, parentPath]);

	return {
		event,
		eventLoading,
		revalidateEvent,
		eventEditState,
		saveDataToBackend,
		deleteEvent,
		parentPath,
		onEventStateChange,
	};
}

type EventContextType = ReturnType<typeof useEventController>;

const EventContext = createContext<EventContextType | undefined>(undefined);

export const EventContextProvider: React.FC<{
	eventContext: EventContextType;
}> = ({ children, eventContext }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoChildren = useMemo(() => children, []);
	return (
		<EventContext.Provider value={eventContext}>
			{memoChildren}
		</EventContext.Provider>
	);
};

export function useEventContextSelector<T extends keyof EventContextType>(
	field: T
): EventContextType[T] {
	return useContextSelector(EventContext, (v) => v![field]);
}

export function useEventContextUnoptimized() {
	const context = useContextSelector(EventContext, (v) => v);

	if (context === undefined) {
		throw new Error("Trying to use context outside of provider");
	}

	return context;
}
