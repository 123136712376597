/** @jsxImportSource @emotion/react */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AirConditioning,
  AirConditioningTypeOfAirConditioningEnum,
  EditAirConditioningInput,
  EditOrCreateAirConditioningModel,
  EditOrCreateAirConditioningModelTypeOfAirConditioningEnum,
} from "belvillage-api-manager";
import { useState } from "react";
import { CloseIcon, DoneIcon } from "../../../../components/BelVillageIcons";
import theme from "../../../../theme";

export default function DialogEditAddAirConditioning(props: {
  open: boolean;
  onClose: () => void;
  airConditioning?: AirConditioning;
  onSave: (airConditioningData: EditOrCreateAirConditioningModel) => void;
  loading: boolean;
}) {
  const { airConditioning } = props;

  const [hsycoId, setHsycoId] = useState<string>(
    airConditioning?.hsycoId || ""
  );
  const [typeOfAirConditioning, setTypeOfAirConditioning] =
    useState<AirConditioningTypeOfAirConditioningEnum>(
      airConditioning?.typeOfAirConditioning ||
        AirConditioningTypeOfAirConditioningEnum.Cooling
    );
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      PaperProps={{ style: { backgroundColor: "#070707" } }}
    >
      <DialogTitle
        children={
          airConditioning?.typeOfAirConditioning || "Add air conditioning"
        }
      />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <InputLabel
              children={
                <Typography
                  children="Air Conditioning Type"
                  variant="caption"
                />
              }
              id="air-conditioning-selector"
              css={{ marginBottom: 8 }}
            />
            <FormControl fullWidth>
              <Select
                labelId="air-conditioning-selector"
                fullWidth
                css={{
                  "&.MuiOutlinedInput-root": {
                    height: "3rem",
                  },
                }}
                variant="outlined"
                value={typeOfAirConditioning}
                onChange={(e) =>
                  setTypeOfAirConditioning(
                    e.target.value as AirConditioningTypeOfAirConditioningEnum
                  )
                }
              >
                <MenuItem
                  value={AirConditioningTypeOfAirConditioningEnum.Heating}
                  children="Heating"
                />
                <MenuItem
                  value={AirConditioningTypeOfAirConditioningEnum.Cooling}
                  children="Cooling"
                />
                <MenuItem
                  value={AirConditioningTypeOfAirConditioningEnum.Dehumidifier}
                  children="Dry/Dehumidification"
                />
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              children={<Typography children="Hsyco Id" variant="caption" />}
              id="air-conditioning-hsyco-id"
              css={{ marginBottom: 8 }}
            />
            <TextField
              variant="outlined"
              fullWidth
              css={{
                ".MuiOutlinedInput-root": {
                  height: "3rem",
                },
              }}
              value={hsycoId}
              onChange={(event) => setHsycoId((x) => event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions css={{ top: 8 }}>
        <Grid container alignItems="center">
          <Grid item css={{ flex: 1 }}>
            <Button
              startIcon={
                <CloseIcon width={18} color={theme.palette.secondary.main} />
              }
              children="Cancel"
              variant="text"
              onClick={() => {
                // setAirConditioning(undefined);
                props?.onClose();
              }}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<DoneIcon width={18} />}
              variant="contained"
              color="primary"
              disabled={props.loading}
              onClick={() => {
                props.onSave({
                  hsycoId,
                  typeOfAirConditioning:
                    typeOfAirConditioning as unknown as EditOrCreateAirConditioningModelTypeOfAirConditioningEnum,
                });
              }}
            >
              Confirm
              {props.loading && (
                <CircularProgress css={{ position: "absolute" }} />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
