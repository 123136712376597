/** @jsxImportSource @emotion/react */
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  AirConditioning,
  AirConditioningTypeOfAirConditioningEnum,
} from "belvillage-api-manager";
import {
  BinIcon,
  PencilEditIcon,
} from "../../../../components/BelVillageIcons";
import DropIcon from "../../../../components/icons/drop";
import FlameIcon from "../../../../components/icons/flame";
import ProhibitionIcon from "../../../../components/icons/prohibition";
import SnowIcon from "../../../../components/icons/snow";
import theme from "../../../../theme";

export default function AirConditioningTile(props: {
  airConditioning: AirConditioning;
  [key: string]: any;
  onEdit: (airConditioning: AirConditioning) => void;
  onDelete: (airConditioning: AirConditioning) => void;
}) {
  const { airConditioning, ...others } = props;

  return (
    <Grid item {...others} xs={4}>
      <Card
        elevation={0}
        css={{
          width: 245,
          borderRadius: 20,
          backgroundColor: "#00000000",
          border: "1px solid #ffffff00",
          transition: "50ms",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#ffffff26",
            border: "1px solid #ffffff26",
          },
        }}
      >
        <CardContent>
          <Grid
            container
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              {renderCorrectAirConditioningIcon(
                airConditioning.typeOfAirConditioning
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                children={airConditioning.typeOfAirConditioning}
                variant="body2"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <IconButton
                    children={<PencilEditIcon />}
                    onClick={() => {
                      console.log("edit");

                      props.onEdit(airConditioning);
                    }}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    children={<BinIcon />}
                    onClick={() => {
                      props.onDelete(airConditioning);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function renderCorrectAirConditioningIcon(
  typeOfAirConditioning?: AirConditioningTypeOfAirConditioningEnum
) {
  switch (typeOfAirConditioning) {
    case AirConditioningTypeOfAirConditioningEnum.Cooling:
      return <SnowIcon size={20} color={theme.palette.secondary.main} />;

    case AirConditioningTypeOfAirConditioningEnum.Dehumidifier:
      return <DropIcon size={20} color={theme.palette.secondary.main} />;

    case AirConditioningTypeOfAirConditioningEnum.Heating:
      return <FlameIcon size={20} color={theme.palette.secondary.main} />;

    default:
      return <ProhibitionIcon />;
  }
}
