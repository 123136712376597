/** @jsxImportSource @emotion/react */

import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grow,
	IconButton,
	Tab,
	TableCell,
	TableRow,
	Tabs,
	TextField,
	Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AxiosResponse } from "axios";
import {
	AdminTicketApi,
	BuildingApi,
	EditCreateHelpInputEmergencyNumbers,
	EmergencyNumber,
	Help,
	HelpApi,
	TicketCategory,
	TutorialApi,
} from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import AdminTable, {
	defaultQueryFilter,
	IHeadCell,
	IQueryFilter,
	parseCountFrom,
	transformToStringFrom,
} from "../../../components/admin/AdminTable";
import {
	BinIcon,
	DoneIcon,
	PencilEditIcon,
} from "../../../components/BelVillageIcons";
import MLDialog from "../../../components/poppers";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import useQueryParams from "../../../hooks/useQueryParams";
import theme from "../../../theme";
import { useApi, useFetch } from "../../../utils/api";
import TicketCategoryAdminTable from "./components/TicketCategoryAdminTable";
import TicketCategoryDialog from "./components/TicketCategoryDialog";
import TutorialAdminTable from "./components/TutorialAdminTable";

function List() {
	const history = useHistory();
	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();
	const tutorialApi = useApi(TutorialApi);
	const helpApi = useApi(HelpApi);
	const ticketApi = useApi(AdminTicketApi);
	const buildingApi = useApi(BuildingApi);

	const [filter, setFilter] = useState<IQueryFilter>({
		...defaultQueryFilter,
	});
	const [dummyFilter, setDummyFilter] = useState<IQueryFilter>({
		...defaultQueryFilter,
	});

	const { path: matchedPath, url } = useRouteMatch();
	const { buildingId: id } = useParams<any>();

	const params = useQueryParams();

	const [openTab, setOpenTab] = useState(
		params.get("section") === "tutorials"
			? 1
			: params.get("section") === "phones"
			? 0
			: 2
	);

	const [openNumberDialog, setOpenNumberDialog] = useState(false);
	const [ticketCategoryDialogOpen, setTicketCategoryDialogOpen] =
		useState<boolean>(false);
	useEffect(() => {
		drawerDispatch({ open: true, buildingSelected: true });
		headerDispatch({
			actionText: "ADD NEW",
			actionVisible: openTab !== 1,
			actionOnClick: () => {
				return openTab === 0
					? setOpenNumberDialog(true)
					: openTab === 2
					? setTicketCategoryDialogOpen(true)
					: () => {};
			},
		});
	}, [openTab]);

	const {
		data: tutorials,
		rawData,
		loading,
	} = useFetch(
		tutorialApi.getAllAdminTutorials,
		transformToStringFrom(filter)
	);

	const {
		data: ticketData,
		loading: ticketLoading,
		rawData: ticketRawData,
		revalidate: ticketRevalidate,
	} = useFetch(
		ticketApi.getAllTicketCategories,
		id,
		transformToStringFrom(filter),
		{ skip: !id }
	);

	const {
		data: buildingHelp,
		loading: loadingBuildingHelp,
		rawData: buildingHelpRawData,

		revalidate: revalidateBuildingHelp,
	} = useFetch(helpApi.getAdminBuildingHelp, id, { skip: !id });

	const [numberData, setNumberData] = useState<EmergencyNumber[] | undefined>(
		buildingHelp?.emergencyNumbers
	);
	const [numberToEdit, setNumberToEdit] = useState<EmergencyNumber>();

	const [ticketCategoryToEdit, setTicketCategoryToEdit] = useState<
		TicketCategory | undefined
	>();

	const [visibleTutorialIds, setVisibleTutorialIds] = useState<string[]>(
		(buildingHelp?.videoShown || []).map((tutorial) => tutorial.id)
	);

	const { data: buildingData, revalidate: revalidateBuilding } = useFetch(
		buildingApi.getBuildingById,
		id
	);

	const [openDeleteCategory, setOpenDeleteCategory] =
		useState<boolean>(false);

	const [monitoringEmail, setMonitoringEmail] = useState(
		buildingData?.ticketEmail
	);
	const [openEditMonitoringMail, setOpenEditMonitoringMail] =
		useState<boolean>(false);

	useEffect(() => {
		setMonitoringEmail(buildingData?.ticketEmail);
	}, [buildingData]);

	useEffect(() => {
		const newArray = (buildingHelp?.videoShown || []).map(
			(tutorial) => tutorial.id
		);
		// console.log("~ ~ newArray", newArray);
		setVisibleTutorialIds(newArray);

		if (buildingHelp) {
			setNumberData(buildingHelp.emergencyNumbers);
		}
	}, [tutorials, buildingHelp]);

	return (
		<Container maxWidth="xl" css={{ height: "100%" }}>
			<Grid container direction="column">
				<Grid item xs="auto" css={{ width: "fit-content" }}>
					<Tabs
						value={openTab}
						onChange={(_e, newValue) => {
							switch (newValue) {
								case 0:
									history.replace({
										pathname: history.location.pathname,
										search: "?section=phones",
									});

									break;
								case 1:
									history.replace({
										pathname: history.location.pathname,
										search: "?section=tutorials",
									});
									break;

								default:
									history.replace({
										pathname: history.location.pathname,
										search: "?section=tickets",
									});
									break;
							}
							// if (newValue === 1) {
							// } else {
							// }

							setOpenTab(newValue);
						}}
						textColor="primary"
						indicatorColor="primary"
						css={{ color: "#EE7705" }}
						// variant="fullWidth"
					>
						<Tab label="Ticket Support" value={2} />
						<Tab label="Emergency telephone numbers" value={0} />
						<Tab label="Video tutorial" value={1} />
					</Tabs>
				</Grid>
				<Grid item xs>
					<Grid container spacing={1}>
						{openTab === 1 ? (
							<TutorialAdminTable
								data={tutorials}
								filterState={[filter, setFilter]}
								loading={loading}
								rawData={rawData}
								visibleTutorialIds={visibleTutorialIds}
								onAddVisibleTutorial={(tutorialId) => {
									helpApi
										.addTutorialToShownHelpVideos(
											id,
											tutorialId
										)
										.then(() => {
											revalidateBuildingHelp();
										})
										.catch((error) => {
											MLDialog.showSnackbar(
												error.message ||
													"UNKNOWN ERROR",
												{
													variant: "error",
												}
											);
										});
								}}
								onRemoveVisibleTutorial={(tutorialId) => {
									helpApi
										.removeTutorialFromShownHelpVideos(
											id,
											tutorialId
										)
										.then(() => {
											revalidateBuildingHelp();
										})
										.catch((error) => {
											MLDialog.showSnackbar(
												error.message ||
													"UNKNOWN ERROR",
												{
													variant: "error",
												}
											);
										});
								}}
							/>
						) : openTab === 0 ? (
							<TelephoneNumbersAdminTable
								filterState={[dummyFilter, setDummyFilter]}
								loading={loadingBuildingHelp}
								rawData={buildingHelpRawData}
								data={buildingHelp}
								onEditNumber={(id) => {
									setNumberToEdit(() => {
										return numberData?.find(
											(x) => x.id === id
										);
									});

									setOpenNumberDialog(true);
								}}
							/>
						) : (
							<div
								css={{
									marginTop: 28,
									width: "100%",
									height: "100%",
								}}
							>
								<TicketCategoryAdminTable
									onMonitoringMailEdit={() => {
										setOpenEditMonitoringMail(true);
									}}
									monitoringEmail={buildingData?.ticketEmail}
									filterState={[dummyFilter, setDummyFilter]}
									loading={ticketLoading}
									rawData={ticketRawData}
									data={ticketData}
									onEdit={(initial) => {
										setTicketCategoryToEdit(initial);
										setTicketCategoryDialogOpen(true);
									}}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Dialog
				open={openEditMonitoringMail}
				onClose={() => {
					setOpenEditMonitoringMail(false);
					setMonitoringEmail(buildingData?.ticketEmail);
				}}
				fullWidth
			>
				<DialogTitle>
					<Typography children="Monitoring Mail" />
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<Typography children="All tickets will be sent to this contact, regardless of their category" />
						</Grid>
						<Grid item>
							<TextField
								fullWidth
								label="Monitoring Mail"
								variant="outlined"
								value={monitoringEmail}
								onChange={(e) =>
									setMonitoringEmail(e.target.value)
								}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item css={{ flex: 1 }} />
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								children="confirm"
								onClick={() => {
									setOpenEditMonitoringMail(false);
									buildingApi
										.editBuilding(id, {
											ticketEmail: monitoringEmail,
										})
										.then(() => {
											MLDialog.showSnackbar(
												"Monitoring Mail Updated",
												{
													variant: "success",
												}
											);
											revalidateBuilding();
										})
										.catch((e) => {
											MLDialog.showSnackbar(
												e.message || e,
												{
													variant: "error",
												}
											);
										});
								}}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openNumberDialog}
				onClose={() => {
					setOpenNumberDialog(false);
					setNumberToEdit(undefined);
				}}
				fullWidth
			>
				<DialogTitle>
					{numberToEdit ? "Edit" : "Add"} Emergency Number
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<TextField
								fullWidth
								label="Name"
								variant="outlined"
								value={numberToEdit?.name || ""}
								onChange={(e) => {
									setNumberToEdit({
										id: numberToEdit?.id || "",
										phone: numberToEdit?.phone || "",
										name: e.target.value || "",
									});
								}}
							/>
						</Grid>
						<Grid item>
							<TextField
								fullWidth
								label="Phone Number"
								variant="outlined"
								value={numberToEdit?.phone || ""}
								onChange={(e) => {
									setNumberToEdit({
										id: numberToEdit?.id || "",
										name: numberToEdit?.name || "",
										phone: e.target.value || "",
									});
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						spacing={2}
						css={{ margin: "0px 8px 0px 8px" }}
					>
						<Grid item css={{ flex: 1 }}>
							<Button
								color="secondary"
								children="delete"
								variant="outlined"
								startIcon={<BinIcon />}
								css={{
									"&.MuiButton-outlined": {
										borderColor: `${theme.palette.secondary.main} !important`,
									},
								}}
								onClick={() => {
									const newPhones = (numberData || []).map(
										(emergencyNumber) => {
											console.table({
												emergencyNumber:
													emergencyNumber.id,
												numberToEdit: numberToEdit?.id,
											});

											if (
												emergencyNumber.id ===
												numberToEdit?.id
											) {
												return undefined;
											}
											return emergencyNumber;
										}
									);

									helpApi
										.editHelp(id, {
											emergencyNumbers: newPhones.filter(
												Boolean
											) as EditCreateHelpInputEmergencyNumbers[],
										})
										.then(() => {
											MLDialog.showSnackbar(
												"Emergency number removed successfully",
												{ variant: "success" }
											);
											revalidateBuildingHelp();
											setOpenNumberDialog(false);
											setNumberToEdit(undefined);
										})
										.catch((err) => {
											MLDialog.showSnackbar(
												"Something went wrong",
												{
													variant: "error",
												}
											);
										});
								}}
							/>
						</Grid>
						<Grid item>
							<Button
								children="save"
								variant="contained"
								color="primary"
								startIcon={<DoneIcon />}
								onClick={() => {
									const newPhones = (numberData || []).map(
										(emergencyNumber) => {
											if (
												emergencyNumber.id ===
												numberToEdit?.id
											) {
												return numberToEdit;
											}
											return emergencyNumber;
										}
									);
									if (!numberToEdit?.id && numberToEdit) {
										newPhones.push(numberToEdit);
									}

									helpApi
										.editHelp(id, {
											emergencyNumbers:
												newPhones as EditCreateHelpInputEmergencyNumbers[],
										})
										.then(() => {
											MLDialog.showSnackbar(
												"Emergency number updated successfully",
												{ variant: "success" }
											);
											revalidateBuildingHelp();
											setOpenNumberDialog(false);
											setNumberToEdit(undefined);
										})
										.catch((err) => {
											MLDialog.showSnackbar(
												"Something went wrong",
												{
													variant: "error",
												}
											);
										});
								}}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<TicketCategoryDialog
				open={ticketCategoryDialogOpen}
				onClose={() => {
					setTicketCategoryDialogOpen(false);
					setTicketCategoryToEdit(undefined);
				}}
				isNew={!ticketCategoryToEdit}
				initialValue={ticketCategoryToEdit}
				onSave={(data) => {
					console.log(data);

					if (ticketCategoryToEdit) {
						ticketApi
							.editTicketCategory(ticketCategoryToEdit!.id!, {
								...data,
							})
							.then(() => {
								setTicketCategoryDialogOpen(false);
								MLDialog.showSnackbar("Category updated", {
									variant: "success",
								});
								ticketRevalidate();
								setTicketCategoryToEdit(undefined);
							})
							.catch((err) => {
								MLDialog.showSnackbar(err.message || err, {
									variant: "error",
								});
							});
					} else {
						ticketApi
							.createTicketCategory(id, { ...data })
							.then(() => {
								setTicketCategoryDialogOpen(false);
								MLDialog.showSnackbar("Category created", {
									variant: "success",
								});
								ticketRevalidate();
							})
							.catch((err) => {
								MLDialog.showSnackbar(err.message || err, {
									variant: "error",
								});
							});
					}
				}}
				onDelete={() => {
					setTicketCategoryDialogOpen(false);
					setOpenDeleteCategory(true);
				}}
			/>
			<Dialog
				open={openDeleteCategory}
				onClose={() => setOpenDeleteCategory(false)}
			>
				<DialogTitle>
					<Typography
						children={"Delete Ticket Category"}
						variant="h6"
					/>
				</DialogTitle>
				<DialogContent>
					<Typography
						children={
							"Are you sure you want to delete this ticket category"
						}
					/>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item css={{ flex: 1 }}>
							<Button
								children={"Cancel"}
								onClick={() => setOpenDeleteCategory(false)}
								variant="outlined"
							/>
						</Grid>
						<Grid item>
							<Button
								children={"Delete"}
								variant="contained"
								color="primary"
								onClick={() => {
									ticketApi
										.removeTicketCategory(
											ticketCategoryToEdit!.id!
										)
										.then(() => {
											setOpenDeleteCategory(false);
											MLDialog.showSnackbar(
												"Category deleted",
												{
													variant: "success",
												}
											);
											ticketRevalidate();
										})
										.catch((err) => {
											MLDialog.showSnackbar(
												err.message || err,
												{
													variant: "error",
												}
											);
										});
								}}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

export type AdminTableBaseProps<T> = {
	loading: boolean;
	filterState: [
		IQueryFilter,
		React.Dispatch<React.SetStateAction<IQueryFilter>>
	];
	rawData: AxiosResponse<T>;
	data?: T;
};

const TelephoneNumbersAdminTable = (
	props: AdminTableBaseProps<Help> & {
		onEditNumber: (id: string) => void;
	}
) => {
	const { loading, rawData, data, filterState } = props;
	return (
		<AdminTable
			loading={loading}
			count={parseCountFrom(rawData)}
			fullWidth
			occlusionHeight={60}
			data={data?.emergencyNumbers}
			headCells={TelephoneNumbersHeaderCells}
			queryFilter={filterState}
		>
			{(data) =>
				(data || []).map((phoneNumber, index) => {
					return (
						<Grow
							key={`phoneNumber-${index}`}
							appear={true}
							in={true}
							timeout={index * 300 + 300}
						>
							<TableRow
								onClick={() =>
									props.onEditNumber(phoneNumber.id)
								}
							>
								<TableCell>{phoneNumber.name}</TableCell>
								<TableCell>{phoneNumber.phone}</TableCell>
								<TableCell
									css={{
										textAlign: "right",
										paddingRight: 24,
									}}
								>
									<IconButton
										children={<PencilEditIcon />}
										css={{
											backgroundColor:
												"rgba(255, 255, 255, 0.12)",
										}}
									/>
								</TableCell>
							</TableRow>
						</Grow>
					);
				})
			}
		</AdminTable>
	);
};

const TelephoneNumbersHeaderCells: IHeadCell[] = [
	{ id: "name", label: "Number Label" },
	{ id: "number", label: "Phone number" },
	{ id: "edit", label: "", notSelectable: true },
];

export default List;
