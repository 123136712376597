import { Grow } from "@material-ui/core";
import { PlaceApi } from "belvillage-api-manager";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AdminTable, {
	IQueryFilter,
	defaultQueryFilter,
	transformToStringFrom,
	IHeadCell,
	parseCountFrom,
} from "../../../../components/admin/AdminTable";
import { useApi, useFetch } from "../../../../utils/api";
import PlaceRow from "./PlaceRow";

const headCells: IHeadCell[] = [
	{ id: "image", label: "Image", notSelectable: true, width: 48 },
	{ id: "name", label: "Place name" },
	{ id: "edit", label: "", notSelectable: true, width: 36 },
];

type PlacesTableProps = {};
const PlacesTable: React.FC<PlacesTableProps> = () => {
	const placeApi = useApi(PlaceApi);
	const [filter, setFilter] = useState<IQueryFilter>({
		...defaultQueryFilter,
	});

	const { buildingId } = useParams<any>();
	const {
		data: places,
		rawData,
		loading,
	} = useFetch(
		placeApi.allBuildingPlaces,
		buildingId,
		transformToStringFrom(filter),
		{
			skip: !buildingId,
		}
	);

	return (
		<AdminTable
			headCells={headCells}
			fullWidth
			fullHeight
			loading={loading}
			queryFilter={[filter, setFilter]}
			count={parseCountFrom(rawData)}
			data={places}
			size="small"
		>
			{(data) => {
				return data?.map((place, i) => {
					return (
						<Grow
							appear={true}
							key={`${place.id}_${i}`}
							in={true}
							timeout={i * 300 + 300}
						>
							<PlaceRow place={place} />
						</Grow>
					);
				});
			}}
		</AdminTable>
	);
};
export default PlacesTable;
