"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FaqApi = exports.FaqApiFactory = exports.FaqApiFp = exports.FaqApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * FaqApi - axios parameter creator
 * @export
 */
exports.FaqApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create faq
         * @param {CreateEditFaqInput} createEditFaqInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq: (createEditFaqInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'createEditFaqInput' is not null or undefined
            common_1.assertParamExists('createFaq', 'createEditFaqInput', createEditFaqInput);
            const localVarPath = `/admin/faq/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createEditFaqInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete faq
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteFaq', 'id', id);
            const localVarPath = `/admin/faq/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit faq
         * @param {string} id
         * @param {CreateEditFaqInput} createEditFaqInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFaq: (id, createEditFaqInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editFaq', 'id', id);
            // verify required parameter 'createEditFaqInput' is not null or undefined
            common_1.assertParamExists('editFaq', 'createEditFaqInput', createEditFaqInput);
            const localVarPath = `/admin/faq/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createEditFaqInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin faq by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFaqById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminFaqById', 'id', id);
            const localVarPath = `/admin/faq/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all admin faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminFaq: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/faq/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all user faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserFaq: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/faq/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user faq by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFaqById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserFaqById', 'id', id);
            const localVarPath = `/faq/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * FaqApi - functional programming interface
 * @export
 */
exports.FaqApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.FaqApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Create faq
         * @param {CreateEditFaqInput} createEditFaqInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq(createEditFaqInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createFaq(createEditFaqInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete faq
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteFaq(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit faq
         * @param {string} id
         * @param {CreateEditFaqInput} createEditFaqInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFaq(id, createEditFaqInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editFaq(id, createEditFaqInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin faq by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFaqById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminFaqById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all admin faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminFaq(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminFaq(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all user faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserFaq(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllUserFaq(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user faq by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFaqById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserFaqById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * FaqApi - factory interface
 * @export
 */
exports.FaqApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.FaqApiFp(configuration);
    return {
        /**
         *
         * @summary Create faq
         * @param {CreateEditFaqInput} createEditFaqInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq(createEditFaqInput, options) {
            return localVarFp.createFaq(createEditFaqInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete faq
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq(id, options) {
            return localVarFp.deleteFaq(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit faq
         * @param {string} id
         * @param {CreateEditFaqInput} createEditFaqInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFaq(id, createEditFaqInput, options) {
            return localVarFp.editFaq(id, createEditFaqInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin faq by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminFaqById(id, options) {
            return localVarFp.getAdminFaqById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all admin faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminFaq(options) {
            return localVarFp.getAllAdminFaq(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all user faq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserFaq(options) {
            return localVarFp.getAllUserFaq(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user faq by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFaqById(id, options) {
            return localVarFp.getUserFaqById(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
class FaqApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create faq
     * @param {CreateEditFaqInput} createEditFaqInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    createFaq(createEditFaqInput, options) {
        return exports.FaqApiFp(this.configuration).createFaq(createEditFaqInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete faq
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    deleteFaq(id, options) {
        return exports.FaqApiFp(this.configuration).deleteFaq(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit faq
     * @param {string} id
     * @param {CreateEditFaqInput} createEditFaqInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    editFaq(id, createEditFaqInput, options) {
        return exports.FaqApiFp(this.configuration).editFaq(id, createEditFaqInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin faq by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    getAdminFaqById(id, options) {
        return exports.FaqApiFp(this.configuration).getAdminFaqById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all admin faq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    getAllAdminFaq(options) {
        return exports.FaqApiFp(this.configuration).getAllAdminFaq(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all user faq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    getAllUserFaq(options) {
        return exports.FaqApiFp(this.configuration).getAllUserFaq(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user faq by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    getUserFaqById(id, options) {
        return exports.FaqApiFp(this.configuration).getUserFaqById(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FaqApi = FaqApi;
