/** @jsxImportSource @emotion/react */
import { Grid, Typography, useTheme } from "@material-ui/core";
import { Report } from "belvillage-api-manager";
import { DateTime } from "luxon";
import { backendMediaUrlParser } from "../../../../utils";

type Props = {
	report: Report;
};

const SingleEventReport: React.FC<Props> = ({ report }) => {
	const theme = useTheme();
	return (
		<Grid
			container
			alignItems="center"
			spacing={1}
			css={{
				padding: 4,
				borderRadius: 12,
				backgroundColor: "rgba(255, 255, 255, 0.12);",
				marginBottom: 12,
			}}
		>
			<Grid item>
				<div
					css={{
						width: 40,
						height: 40,
						borderRadius: "100%",
						border: `1px solid ${theme.palette.primary.main} `,
						backgroundImage: `url(${backendMediaUrlParser(
							report.owner?.profile_image
						)})`,
						backgroundPosition: "center",
						backgroundSize: "contain",
					}}
				/>
			</Grid>
			<Grid item xs>
				<Typography
					variant="subtitle2"
					children={`${report.owner?.name ?? ""} `}
					noWrap
					css={{ width: "100%" }}
				/>
			</Grid>
			<Grid
				item
				css={{
					height: 40,
					display: "flex",
					alignItems: "flex-end",
					paddingBottom: "0px !important",
				}}
			>
				<Typography
					variant="caption"
					children={DateTime.fromISO(report.createdAt!).toFormat(
						"dd/MM/yyyy 'at' t"
					)}
				/>
			</Grid>
		</Grid>
	);
};

export default SingleEventReport;
