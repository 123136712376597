import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function ArrowLeftIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "18"}
			height={iconSize || "18"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 18 18"
		>
			<path
				fill={color || "#EF7705"}
				d="M5.155 9.487h.001l6.237 6.497a.702.702 0 101.011-.972L6.636 9l5.768-6.013a.702.702 0 00-1.011-.972L5.156 8.512a.7.7 0 00-.192.459v.005a.7.7 0 00.191.511z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default ArrowLeftIcon;
