import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function ClockIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "16"}
			height={iconSize || "17"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 16 17"
		>
			<path
				fill={color || "#EF7705"}
				d="M2.667 9.087a5.444 5.444 0 1110.889 0 5.444 5.444 0 01-10.89 0zm5.444-4.69a4.689 4.689 0 100 9.378 4.689 4.689 0 000-9.377zm.192 1.578a.389.389 0 00-.778 0v3.71c0 .118.053.23.145.303l2.23 1.799a.389.389 0 00.488-.606L8.303 9.5V5.975z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default ClockIcon;
