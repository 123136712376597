/** @jsxImportSource @emotion/react */

import DateDayJsIoUtils from "@date-io/dayjs";
import {
  alpha,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AxiosError } from "axios";
import { NotificationApi, NotificationSchedule } from "belvillage-api-manager";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { BinIcon, DoneIcon } from "../../../components/BelVillageIcons";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import theme from "../../../theme";
import { apiErrorParser, DeepKeyof, getObjectDeepValues } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

const initialState: NotificationSchedule = {
  id: "",
  notification: {
    title: "",
    description: "",
    link: "",
  },
  schedule: DateTime.now().toISO(),
};

export function CreateEditView(props: { parentPath: string }) {
  const history = useHistory();
  const { id } = useParams<any>();
  const notificationApi = useApi(NotificationApi);
  const { data, loading } = useFetch(
    notificationApi.getAdminNotificationById,
    id,
    { skip: !id }
  );

  const { params } = useRouteMatch();

  const { buildingId } = params as any;

  const [state, dispatch] = useFormInputReducer(initialState);

  const [isAlreadySent, setAlreadySent] = useState(false);

  const [scheduledNotification, setScheduledNotification] = useState(
    !!state.schedule || false
  );

  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  useEffect(() => {
    drawerDispatch({ open: true, editMode: true, editModelName: "categories" });
    headerDispatch({ actionVisible: false });
  }, []);

  useEffect(() => {
    if (data) {
      const initialKeys = Object.keys(initialState);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      dispatch({
        field: "UPDATE_STATE",
        value: availableDataFromInitialState as typeof initialState,
      });

      if (data.sentOn) {
        setAlreadySent(true);
      }
    }
  }, [data, dispatch]);

  function saveDataToBackend(exit?: boolean) {
    const { id, ...others } = state;

    if (id) {
      //! EDIT
      notificationApi
        .editAdminNotification(id, { ...others })
        .then((value) => {
          if (exit) history.push(props.parentPath);
          else
            MLDialog.showSnackbar("Saved successfully", {
              variant: "success",
            });
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    } else {
      //! ADD
      notificationApi
        .createBuildingNotification(buildingId, { ...others })
        .then((value) => {
          MLDialog.showSnackbar("Created successfully", {
            variant: "success",
          });
          history.replace(props.parentPath + "/" + value.data?.id);
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    }
  }

  if ((!state || loading || !data) && Boolean(id)) {
    return (
      <Grid container alignItems="center" css={{ height: "100%" }}>
        <Grid container justify="center">
          <CircularProgress size={60} variant="indeterminate" color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container alignItems="flex-start" alignContent="flex-start">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grow appear={true} in={true}>
              <Card>
                <CardContent
                  css={{
                    ">*": {
                      marginBottom: 24,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    children={
                      (id ? (isAlreadySent ? "Sent" : "Edit") : "New") +
                      " Notification"
                    }
                    gutterBottom
                  />
                  <TextField
                    {...valueChangeReducerHelper("notification.title")}
                    label="Notification name"
                    fullWidth
                    variant={isAlreadySent ? "standard" : "outlined"}
                    disabled={isAlreadySent}
                  />
                  <TextField
                    {...valueChangeReducerHelper("notification.description")}
                    label="Notification Description"
                    fullWidth
                    rows={isAlreadySent ? 1 : 3}
                    multiline
                    variant={isAlreadySent ? "standard" : "outlined"}
                    disabled={isAlreadySent}
                  />
                  <TextField
                    {...valueChangeReducerHelper("notification.link")}
                    label="Notification Link"
                    fullWidth
                    variant={isAlreadySent ? "standard" : "outlined"}
                    disabled={isAlreadySent}
                  />
                  <Card
                    elevation={0}
                    css={{
                      backgroundColor: alpha("#070707", 0.38),
                      border: "2px solid rgba(255,255, 255, 0.12)",
                      borderRadius: 12,
                    }}
                  >
                    <CardContent
                      css={{
                        position: "relative",
                        "::before": !scheduledNotification
                          ? {
                              position: "absolute",
                              content: "''",
                              height: "100%",
                              width: "100%",
                              left: 0,
                              top: 0,
                              backgroundColor: "rgba(0,0,0,.68)",
                            }
                          : undefined,
                      }}
                    >
                      <Grid container direction="column">
                        <Grid item xs>
                          <Grid container alignItems="center">
                            <Grid item css={{ flex: 1 }}>
                              <Typography children={"Scheduled Notification"} />
                            </Grid>
                            <Grid item>
                              <Switch
                                disabled={isAlreadySent}
                                checked={scheduledNotification}
                                css={{
                                  ".MuiSwitch-track": {
                                    backgroundColor: alpha("#070707", 0.38),
                                    borderRadius: 20,
                                  },
                                }}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    dispatch({
                                      field: "schedule",
                                      value: undefined,
                                    });
                                  }
                                  setScheduledNotification(e.target.checked);
                                }}
                                color="primary"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="caption"
                            children={
                              "Schedule when to send this notification to Belvillage users"
                            }
                          />
                        </Grid>
                        <Grid item>
                          <div
                            css={{
                              width: "100%",
                              padding: 8,
                              height: 48,
                              backgroundColor: alpha("#fff", 0.12),
                              borderRadius: 12,
                              userSelect: "none",
                            }}
                            onClick={() => {}}
                          >
                            <Grid
                              container
                              alignItems="center"
                              css={{
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <Grid item>
                                <DateSelectorLeftSide />
                              </Grid>
                              <Grid item css={{ flex: 1 }}>
                                {/* <Typography
                                  variant="body2"
                                  color="primary"
                                  children={DateTime.fromISO(
                                    state.schedule || new Date().toISOString()
                                  )
                                    .setLocale("en-EN")
                                    .toFormat("fff")}
                                /> */}
                                <MuiPickersUtilsProvider
                                  utils={DateDayJsIoUtils}
                                >
                                  <DateTimePicker
                                    // disableToolbar
                                    autoOk
                                    disabled={
                                      !scheduledNotification || isAlreadySent
                                    }
                                    rightArrowButtonProps={{
                                      color: "secondary",
                                    }}
                                    leftArrowButtonProps={{
                                      color: "secondary",
                                    }}
                                    onChange={(value) => {
                                      dispatch({
                                        field: "schedule",
                                        value: value?.toISOString(),
                                      });
                                    }}
                                    value={DateTime.fromISO(
                                      state.schedule || new Date().toISOString()
                                    )}
                                    css={{
                                      ".Mui-selected": {
                                        color: theme.palette.primary.main,
                                      },
                                    }}
                                    TextFieldComponent={(props) => {
                                      return (
                                        <DateTextField
                                          {...props}
                                          isalreadysent={
                                            isAlreadySent ? "true" : "false"
                                          }
                                        />
                                      );
                                    }}
                                    variant="dialog"
                                    // TextFieldComponent={(props) => {
                                    //   return (
                                    //     <TextField
                                    //       {...props}
                                    //       css={{
                                    //         color: theme.palette.primary.main,
                                    //       }}
                                    //     />
                                    //   );
                                    // }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Grid
                    container
                    justifyContent={id ? "space-between" : "flex-end"}
                  >
                    {id && (
                      <Button
                        variant="text"
                        children="Delete"
                        startIcon={<BinIcon />}
                        onClick={() => {
                          setOpenDeleteNotification(true);
                        }}
                      />
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      children="Confirm"
                      startIcon={<DoneIcon />}
                      onClick={() => {
                        saveDataToBackend(false);
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openDeleteNotification}
        onClose={() => {
          setOpenDeleteNotification(false);
        }}
        maxWidth="xl"
      >
        <DialogTitle children="Delete this notification schedule?" />
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            css={{ width: "100%" }}
          >
            <Grid item>
              <Typography
                children={`This notification schedule will be deleted.`}
                component="span"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                children={`If the notification has already been sent only the notification schedule will be removed.`}
              />
            </Grid>
            <Grid item>
              <Typography children={`This action is irreversible`} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item css={{ flex: 1 }}>
              <Button
                children="Cancel"
                variant="outlined"
                onClick={() => {
                  setOpenDeleteNotification(false);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                children="Delete"
                variant="contained"
                color="primary"
                onClick={() => {
                  notificationApi
                    .deleteAdminNotification(id)
                    .then(() => {
                      history.goBack();
                    })
                    .catch((error) =>
                      MLDialog.showSnackbar(apiErrorParser(error), {
                        variant: "error",
                      })
                    );
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Container>
  );

  function valueChangeReducerHelper(
    field: DeepKeyof<typeof initialState> | "UPDATE_STATE"
  ) {
    // function valueChangeReducerHelper(field: keyof typeof initialState) {
    return {
      // value: state[field],
      value: getObjectDeepValues(state, field),
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
}

const DateTextField = (props: any) => {
  // console.log("props.disabled = " + props.disabled);

  return (
    <TextField
      {...props}
      variant="standard"
      fullWidth
      css={{
        textAlign: "right",
        ".MuiInputBase-input": {
          color:
            props.disabled && !(props.isalreadysent === "true")
              ? darken(theme.palette.primary.main, 0.7)
              : theme.palette.primary.main,
          textAlign: "right",
          paddingRight: 8,
        },
        ".MuiInput-underline::before": {
          display: "none",
        },
        ".Mui-focused:after": {
          display: "none",
        },
      }}
      color="secondary"
    />
  );
};
const DateSelectorLeftSide = () => (
  <Grid container alignItems="center" spacing={1}>
    <Grid item>
      <CalendarTodayOutlined css={{ marginLeft: 8 }} />
    </Grid>
    <Grid item>
      <Typography children="Send on" component="span" align="center" />
    </Grid>
  </Grid>
);
