/** @jsxImportSource @emotion/react */
import {
	alpha,
	Grid,
	Grow,
	LinearProgress,
	Paper,
	Size,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
	useTheme,
} from "@material-ui/core";
import queryString from "querystring";
import React, { useCallback, useRef, useState } from "react";
export interface IHeadCell {
	id: string;
	label: string;
	subtitle?: string;
	notSelectable?: boolean;
	colSpan?: number;
	width?: number;
}
export type Order = "asc" | "desc";

export interface FilterObject {
	[x: string]: any;
}

export interface IQueryFilter {
	page?: number;
	perPage?: number;
	order?: Order;
	orderBy?: string;
	filter?: FilterObject[];
	// filter?: string;
}

const BOTTOM_OFFSET = 24;

function AdminTable<T>(props: {
	data: T[] | undefined;
	queryFilter: [IQueryFilter, (value: IQueryFilter) => void];
	count: number;
	loading?: boolean;
	headCells: IHeadCell[];
	children: (data: T[]) => any;
	occlusionHeight?: number;
	size?: Size;
	fullWidth?: boolean;
	fullHeight?: boolean;
	disablePagination?: boolean;
	settingsHeader?: React.ReactNode;
}) {
	const { queryFilter } = props;

	const currentFilter = queryFilter[0];

	const theme = useTheme();

	const setFilter = (name: keyof IQueryFilter, value: any) => {
		const newFilter = { ...currentFilter };
		newFilter[name] = value;
		queryFilter[1](newFilter);
	};

	const tableRef = useRef<HTMLDivElement>(null);

	const [fromTop, setFromTop] = useState<number | undefined>();
	const calcFromTop = useCallback(() => {
		if (tableRef.current) {
			setFromTop(
				window.scrollY +
					tableRef.current.getBoundingClientRect().top +
					BOTTOM_OFFSET
			);
		}
	}, []);

	return (
		<Grow
			appear={true}
			in={true}
			onEntered={() => {
				calcFromTop();
			}}
		>
			<Paper
				ref={tableRef}
				elevation={0}
				css={{
					minWidth: props?.fullWidth ? "100%" : "inherit",
					background: "transparent",
					...(props.fullHeight && {
						height: fromTop ? `calc(100vh - ${fromTop}px)` : "auto",
					}),
				}}
			>
				<TableContainer
					css={{
						width: "100%",
						maxHeight: props.fullHeight
							? `calc(100% - 52px)`
							: `calc(100vh - ${
									props?.occlusionHeight || 340
							  }px)`,
						overflowY: "auto",
					}}
				>
					<Table stickyHeader size={props.size || "medium"}>
						<TableHead
							css={{
								background: "transparent",

								"& .MuiTableCell-stickyHeader ": {
									background: "transparent",
								},
							}}
						>
							{props?.settingsHeader}
							<TableRow css={{ background: "transparent" }}>
								{props.headCells?.map((headCell) => (
									<TableCell
										key={headCell.id}
										colSpan={headCell?.colSpan || 1}
										css={{
											width: headCell?.width || "auto",
											background: "transparent",
										}}
										sortDirection={
											currentFilter.orderBy ===
											headCell.id
												? currentFilter.order
												: false
										}
									>
										<TableSortLabel
											active={
												currentFilter.orderBy ===
												headCell.id
											}
											disabled={headCell.notSelectable}
											direction={
												currentFilter.orderBy ===
												headCell.id
													? currentFilter.order
													: "asc"
											}
											onClick={() => {
												if (headCell.notSelectable)
													return;
												const isAsc =
													currentFilter.orderBy ===
														headCell.id &&
													currentFilter.order ===
														"asc";
												queryFilter[1]({
													...currentFilter,
													order: isAsc
														? "desc"
														: "asc",
													orderBy: headCell.id,
												});
											}}
										>
											<Grid item>
												<Typography
													css={{
														textTransform:
															"uppercase",
														color: "#5E5873",
														fontSize: 12,
														fontWeight: 500,
														letterSpacing: "1px",
														lineHeight: "15px",
													}}
												>
													{headCell.label}
												</Typography>
												{headCell?.subtitle && (
													<Typography
														css={{
															textTransform:
																"uppercase",
															color: "#5E5873",
															fontSize: 12,
															fontWeight: 300,
															letterSpacing:
																"1px",
															lineHeight: "15px",
														}}
													>
														{headCell?.subtitle}
													</Typography>
												)}
												{currentFilter.orderBy ===
												headCell.id ? (
													<span
														css={{
															border: 0,
															clip: "rect(0 0 0 0)",
															height: 1,
															margin: -1,
															overflow: "hidden",
															padding: 0,
															position:
																"absolute",
															top: 20,
															width: 1,
														}}
													>
														{currentFilter.order ===
														"desc"
															? "sorted descending"
															: "sorted ascending"}
													</span>
												) : null}
											</Grid>
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{props.children(props?.data || [])}
						</TableBody>
					</Table>
				</TableContainer>
				{props.loading && (
					<LinearProgress
						css={{ zIndex: 1000, top: 0, left: 0, right: 0 }}
					/>
				)}
				{!props?.disablePagination && (
					<TablePagination
						rowsPerPageOptions={[1, 5, 10, 25, 50]}
						labelRowsPerPage="Righe per pagina"
						css={{
							display: "flex",
							justifyContent: "flex-end",
						}}
						nextIconButtonProps={{
							className: "next-action",
							color: "primary",
							style: {
								background: "rgba(255,255,255, 0.2)",
							},
						}}
						backIconButtonProps={{
							className: "back-action",
							color: "primary",
							style: {
								background: "rgba(255,255,255, 0.2)",
							},
						}}
						labelDisplayedRows={({ from, to, count, page }) => {
							const rowsPerPage = currentFilter?.perPage || 10;
							const totalPages = Math.ceil(count / rowsPerPage);
							const maxPages = 7;
							let deltaBounds = Math.floor(maxPages / 2);
							if (deltaBounds < 0) deltaBounds = 0;
							const remainingPages =
								totalPages > maxPages ? maxPages : totalPages;
							let lb =
								page > deltaBounds ? page - deltaBounds : 0;
							if (lb > totalPages - maxPages)
								lb = totalPages - maxPages;
							if (lb < 0) lb = 0;
							let ub = lb + remainingPages;
							if (ub > totalPages) ub = totalPages;
							const pages = [];
							for (let i = 0; i < ub; i++) {
								if (i >= lb)
									pages.push({
										index: i,
										selected: i === page,
									});
							}
							return (
								<span>
									<Grid
										component="span"
										container
										alignItems="center"
										spacing={2}
										css={{ flex: 1 }}
										justifyContent="space-between"
									>
										<Grid component="span" item>
											Risultati dal {from} al {to} su{" "}
											{count} righe
										</Grid>
										<Grid component="span" item>
											<Grid
												container
												component="span"
												css={{
													height: 28,
													overflow: "visible",
													borderRadius: 16,
												}}
												alignItems="center"
											>
												{pages.map((p) => {
													return (
														<Grid
															component="span"
															key={
																"page-" +
																p.index
															}
															css={{
																marginTop: -2,
																height: 32,
																width: 32,
																borderRadius: 16,
																backgroundColor:
																	p.selected
																		? theme
																				.palette
																				.primary
																				.main
																		: "transparent",
																display: "flex",
																alignItems:
																	"center",
																justifyContent:
																	"center",
																transition:
																	theme.transitions.create(
																		[
																			"background-color",
																			"color",
																			"font-weight",
																		]
																	),
																cursor: p.selected
																	? "inherit"
																	: "pointer",
																":hover": {
																	backgroundColor:
																		p.selected
																			? theme
																					.palette
																					.primary
																					.main
																			: alpha(
																					theme
																						.palette
																						.primary
																						.main,
																					0.2
																			  ),
																},
															}}
															item
															onClick={() => {
																setFilter(
																	"page",
																	p.index
																);
															}}
														>
															<Typography
																component="span"
																css={{
																	fontWeight:
																		p.selected
																			? "bold"
																			: "normal",
																	color: p.selected
																		? "white"
																		: theme
																				.palette
																				.text
																				.primary,
																	transition:
																		theme.transitions.create(
																			[
																				"background-color",
																				"color",
																				"font-weight",
																			]
																		),
																}}
																children={
																	p.index + 1
																}
															/>
														</Grid>
													);
												})}
											</Grid>
										</Grid>
									</Grid>
								</span>
							);
						}}
						count={props?.count || 0}
						component="div"
						title="Righe per pagina"
						lang="it-IT"
						rowsPerPage={currentFilter?.perPage || 10}
						page={currentFilter?.page || 0}
						onPageChange={(event, newPage) => {
							setFilter("page", newPage);
						}}
						onRowsPerPageChange={(event) => {
							const newPerPageValue = parseInt(
								event.target.value
							);
							const perPage = currentFilter?.perPage || 10;
							const page = currentFilter?.page || 0;
							const oldIndex = page * perPage; // 10 | 0;
							const newPage = oldIndex / newPerPageValue;
							const newFilter = { ...currentFilter };
							newFilter["page"] = Math.floor(newPage);
							newFilter["perPage"] = newPerPageValue;
							queryFilter[1](newFilter);
						}}
					/>
				)}
			</Paper>
		</Grow>
	);
}

export default AdminTable;

export const defaultQueryFilter: IQueryFilter = {
	page: 0,
	perPage: 10,
	order: "asc",
	orderBy: "createdAt",
};

//! UTILITIES
export function transformToStringFrom(queryFilter: IQueryFilter): string {
	return queryString.stringify({
		...queryFilter,
		filter: JSON.stringify(queryFilter.filter),
	});
}

export function parseCountFrom(rawData: any): number {
	return parseInt(rawData?.headers?.count || 0);
}
