/** @jsxImportSource @emotion/react */
import { Button, Grid } from "@material-ui/core";
import { AxiosError } from "axios";
import { Event, EventApi } from "belvillage-api-manager";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import CloseCancIcon from "../../../../components/icons/close_canc";
import DeleteIcon from "../../../../components/icons/delete";
import LoadingIconButton from "../../../../components/LoadingIconButton";
import MLDialog from "../../../../components/poppers";
import { apiErrorParser } from "../../../../utils";
import { useApi } from "../../../../utils/api";

type Props = {
	event: Event;
	parentPath: string;
};

const EventActions: React.FC<Props> = ({ event, parentPath }) => {
	const history = useHistory();
	const { buildingId } = useParams<any>();

	const eventApi = useApi(EventApi);

	const deleteEvent = useCallback(() => {
		if (event) {
			return eventApi
				.deleteAdminEvent(event.id)
				.then((value) => {
					MLDialog.showSnackbar(
						`Event ${event.name} deleted successfully`,
						{
							variant: "success",
						}
					);
					history.replace(
						parentPath.replace(":buildingId", buildingId)
					);
				})
				.catch((error: AxiosError) =>
					MLDialog.showSnackbar(apiErrorParser(error), {
						variant: "error",
					})
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buildingId, event, history, parentPath]);

	return (
		<Grid container css={{ marginBottom: 0 }}>
			<Grid item>
				<Button
					onClick={() => {
						MLDialog.showModal(
							"Delete event",
							`Are you sure you want to delete this event?`,
							{
								negativeButton: (
									<Button
										children="Cancel"
										onClick={() => MLDialog.hideModal()}
										startIcon={<CloseCancIcon size={24} />}
										css={{
											height: 48,
										}}
										variant="outlined"
									/>
								),
								onPositiveClick: deleteEvent,
								positiveButton: (onClick, loading) => (
									<LoadingIconButton
										children="Delete"
										onClick={onClick}
										startIcon={
											<DeleteIcon
												size={24}
												color="#ffffff"
											/>
										}
										css={{
											height: 48,
										}}
										variant="contained"
										color="primary"
										loading={loading}
									/>
								),
							}
						);
					}}
					startIcon={<DeleteIcon size={24} color="#ffffff" />}
					color="secondary"
				>
					Delete
				</Button>
			</Grid>
		</Grid>
	);
};

export default EventActions;
