/** @jsxImportSource @emotion/react */
import { alpha, Box, Typography, useTheme } from "@material-ui/core";
import { DateTime } from "luxon";
import { useCallback } from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";
import ArrowLeftIcon from "../../../../components/icons/arrow_left";
import ArrowRightIcon from "../../../../components/icons/arrow_right";

type Props = {
	onDateChange: (date: Date) => void;
	selectedDate?: Date;
};

const CalendarPicker: React.FC<Props> = ({ onDateChange, selectedDate }) => {
	const theme = useTheme();
	const isTileDisabled = useCallback((tile: CalendarTileProperties) => {
		return (
			Math.ceil(DateTime.fromJSDate(tile.date).diffNow("days").days) < 0
		);
	}, []);
	return (
		<Calendar
			value={selectedDate}
			onClickDay={onDateChange}
			next2Label={null}
			prev2Label={null}
			tileDisabled={isTileDisabled}
			tileClassName={(tile) => {
				return !isTileDisabled(tile)
					? "react-calendar__tile"
					: "react-calendar__tile__disabled";
			}}
			navigationLabel={(props) => {
				return (
					<Box
						css={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: 4,
						}}
					>
						<Typography
							children={props.label}
							variant="h6"
							css={{ fontWeight: 800, color: "white" }}
						/>
					</Box>
				);
			}}
			prevLabel={<ArrowLeftIcon size={24} color="white" />}
			nextLabel={<ArrowRightIcon size={24} color="white" />}
			css={{
				width: 375,
				"& .react-calendar__navigation": {
					display: "flex",
					paddingBottom: 20,
					alignItems: "center",
					justifyContent: "space-between",
				},
				"& .react-calendar__navigation__label": {
					backgroundColor: "unset",
					border: "unset",
					cursor: "pointer",
					textAlign: "center",
					padding: "4px 8px",
					flexGrow: "unset !important",
					borderRadius: 8,
					transition: theme.transitions.create(["background-color"]),
					"&:hover": {
						backgroundColor: alpha(
							theme.palette.primary.main,
							0.36
						),
					},
				},
				"& .react-calendar__navigation__arrow": {
					backgroundColor: "unset",
					border: "unset",
					cursor: "pointer",
					padding: 4,
					height: 32,
					width: 32,
					borderRadius: 30,
					transition: theme.transitions.create(["background-color"]),
					"&:hover": {
						backgroundColor: alpha(
							theme.palette.primary.main,
							0.36
						),
					},
				},
				"& .react-calendar__tile": {
					color: "white",
					padding: 0,
					flex: "unset !important",
					margin: "4px 7.7px",
					width: 38,
					height: 38,
					textAlign: "center",
					fontWeight: 400,
					fontSize: 20,
					borderRadius: 8,
					borderStyle: "solid",
					backgroundColor: "unset",
					borderColor: "transparent",
					boxSizing: "border-box",
					cursor: "pointer",
					transition: theme.transitions.create([
						"border-color",
						"color",
					]),
					"&:hover": {
						color: "white !important",
						backgroundColor: alpha(
							theme.palette.primary.main,
							0.36
						),
					},
					"&--active": {
						color: "white !important",
						backgroundColor: `${theme.palette.primary.main} !important`,
					},
					"&--now": {
						color: theme.palette.primary.main,
					},
				},
				"& .react-calendar__tile__disabled": {
					cursor: "unset",
					color: alpha("#FFFFFF", 0.38),
					"&:hover": {
						backgroundColor: "transparent",
						color: `${alpha("#FFFFFF", 0.38)} !important`,
					},
					"&--active": {
						backgroundColor: "transparent",
						borderColor: "transparend",
					},
				},
				"& .react-calendar__month-view__days__day--neighboringMonth": {
					color: "rgba(255, 255, 255, 0.38)",
					backgroundColor: "transparent",
					borderColor: "transparent",
				},
				"& .react-calendar__month-view__weekdays": {
					paddingBottom: 10,
					alignItems: "center",
				},
				"& .react-calendar__month-view__days": {
					gap: "8px 0px",
					alignItems: "center",
				},
				"& .react-calendar__month-view__weekdays__weekday": {
					// flex: "unset !important",
					width: 38,
					textAlign: "center",
					textTransform: "uppercase",
					fontWeight: 700,
					fontSize: 14,
					color: "rgba(255, 255, 255, 0.38)",
					"abbr[title]": {
						textDecoration: "none",
					},
				},
				"& .react-calendar__year-view__months, .react-calendar__decade-view__years, .react-calendar__century-view":
					{
						"& .react-calendar__tile": {
							width: "unset",
							flexBasis: "50% !important",
							margin: 0,
							"&::after": {
								width: 0,
								height: 0,
							},
							"&:hover": {
								color: "white !important",
							},
							"&--now": {
								color: `${theme.palette.primary.main} !important`,
							},
						},
					},
			}}
		/>
	);
};

export default CalendarPicker;
