/** @jsxImportSource @emotion/react */

import { Container, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import useQueryParams from "../../../hooks/useQueryParams";
import PlacesTable from "./components/PlacesTable";
import ReviewsTable from "./components/ReviewsTable";

function List() {
	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();

	const history = useHistory();

	const params = useQueryParams();

	const [openTab, setOpenTab] = useState(
		params.get("section") === "places" ? 1 : 0
	);

	useEffect(() => {
		drawerDispatch({ open: true, buildingSelected: true });
		headerDispatch({ actionVisible: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openTab]);

	return (
		<Container maxWidth="xl" css={{ height: "100%" }}>
			<div css={{ width: "fit-content" }}>
				<Tabs
					value={openTab}
					onChange={(_e, newValue) => {
						switch (newValue) {
							case 0:
								history.replace({
									pathname: history.location.pathname,
									search: "?section=reviews",
								});

								break;
							case 1:
								history.replace({
									pathname: history.location.pathname,
									search: "?section=places",
								});
								break;

							default:
								history.replace({
									pathname: history.location.pathname,
									search: "?section=reviews",
								});
								break;
						}

						setOpenTab(newValue);
					}}
					textColor="primary"
					indicatorColor="primary"
					css={{ color: "#EE7705" }}
					// variant="fullWidth"
				>
					<Tab label="Reviews" value={0} />
					<Tab label="Places" value={1} />
				</Tabs>
			</div>

			{openTab === 0 ? <ReviewsTable /> : <PlacesTable />}
		</Container>
	);
}

export default List;
