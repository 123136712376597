/** @jsxImportSource @emotion/react */
import { alpha, Button, useTheme } from "@material-ui/core";
import { AxiosError } from "axios";
import { Event, EventApi } from "belvillage-api-manager";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import CloseCancIcon from "../../../../components/icons/close_canc";
import DeleteIcon from "../../../../components/icons/delete";
import SuccessIcon from "../../../../components/icons/success";
import LoadingIconButton from "../../../../components/LoadingIconButton";
import MLDialog from "../../../../components/poppers";
import { apiErrorParser } from "../../../../utils";
import { useApi } from "../../../../utils/api";
import SingleEventReport from "./SingleEventReport";

type Props = {
	event: Event;
	parentPath: string;
	revalidate: () => Promise<boolean>;
};

const EventReports: React.FC<Props> = ({ event, parentPath, revalidate }) => {
	const theme = useTheme();
	const history = useHistory();
	const { buildingId } = useParams<any>();

	const eventApi = useApi(EventApi);

	const shouldRejectReport = useCallback(
		(shouldReject: boolean) => {
			if (event) {
				return eventApi
					.reviewReportedEvent(event.id, {
						areReportsValid: !shouldReject,
					})
					.then((value) => {
						MLDialog.showSnackbar(
							`${
								event.reports && event.reports.length > 1
									? "Reports"
									: "Report"
							} ${
								shouldReject ? "rejected" : "accepted"
							} successfully`,
							{
								variant: "success",
							}
						);
						if (!shouldReject) {
							history.replace(
								parentPath.replace(":buildingId", buildingId)
							);
						} else {
							revalidate();
						}
					})
					.catch((error: AxiosError) =>
						MLDialog.showSnackbar(apiErrorParser(error), {
							variant: "error",
						})
					);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[event]
	);

	return (
		<div
			css={{
				backgroundColor: alpha(theme.palette.primary.main, 0.12),
				widht: "100%",
				border: `1px solid ${theme.palette.primary.main}`,
				borderRadius: 12,
				padding: 12,
				marginBottom: 0,
			}}
		>
			{event.reports?.map((report) => {
				return <SingleEventReport report={report} />;
			})}
			<div
				css={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					gap: 8,
				}}
			>
				<Button
					color="secondary"
					css={{ height: 48 }}
					startIcon={<DeleteIcon size={24} color="#fff" />}
					onClick={() => {
						MLDialog.showModal(
							"Reject report",
							`Are you sure you want to reject ${
								event.reports && event.reports?.length === 1
									? "this report"
									: "these reports"
							}?`,
							{
								negativeButton: (
									<Button
										children="Cancel"
										onClick={() => MLDialog.hideModal()}
										startIcon={<CloseCancIcon size={24} />}
										css={{
											height: 48,
										}}
										variant="outlined"
									/>
								),
								onPositiveClick: () => shouldRejectReport(true),
								positiveButton: (onClick, loading) => (
									<LoadingIconButton
										children="Reject"
										onClick={onClick}
										startIcon={
											<DeleteIcon
												size={24}
												color="#ffffff"
											/>
										}
										css={{
											height: 48,
										}}
										variant="contained"
										color="primary"
										loading={loading}
									/>
								),
							}
						);
					}}
				>
					Reject
					{event.reports && event.reports?.length > 1
						? " reports"
						: " report"}
				</Button>
				<Button
					color="primary"
					variant="contained"
					css={{ height: 48 }}
					startIcon={<SuccessIcon size={24} color="#fff" />}
					onClick={() => {
						MLDialog.showModal(
							"Accept report",
							`Are you sure you want to accept ${
								event.reports && event.reports?.length === 1
									? "this report"
									: "these reports"
							}?`,
							{
								negativeButton: (
									<Button
										children="Cancel"
										onClick={() => MLDialog.hideModal()}
										startIcon={<CloseCancIcon size={24} />}
										css={{
											height: 48,
										}}
										variant="outlined"
									/>
								),
								onPositiveClick: () =>
									shouldRejectReport(false),
								positiveButton: (onClick, loading) => (
									<LoadingIconButton
										children="Accept"
										onClick={onClick}
										startIcon={
											<SuccessIcon
												size={24}
												color="#ffffff"
											/>
										}
										css={{
											height: 48,
										}}
										variant="contained"
										color="primary"
										loading={loading}
									/>
								),
							}
						);
					}}
				>
					Accept
					{event.reports && event.reports?.length > 1
						? " reports"
						: " report"}
				</Button>
			</div>
		</div>
	);
};

export default EventReports;
