/** @jsxImportSource @emotion/react */
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { isFunction } from "lodash";
import {
	OptionsObject,
	SnackbarProvider,
	SnackbarProviderProps,
	withSnackbar,
	WithSnackbarProps,
} from "notistack";
import * as React from "react";

export class MLDialogProvider extends React.Component<SnackbarProviderProps> {
	render() {
		return (
			<SnackbarProvider {...this.props}>
				<__MLDialog />
				{this.props.children}
			</SnackbarProvider>
		);
	}
}

export interface MLDialogParams {
	title?: string;
	message?: string | any;
	onPositiveClick?: () => Promise<boolean | void> | boolean | void; // return true for not closing modal
	onNegativeClick?: (() => boolean | void) | undefined; // return true for not closing modal
	positiveText?: string;
	negativeText?: string;
	positiveButton?:
		| ((onClick: any, loading: boolean) => React.ReactElement<any, any>)
		| React.ReactElement<any, any>;
	negativeButton?: React.ReactElement<any, any>;
}

export default class MLDialog extends React.Component<WithSnackbarProps> {
	state = MLDialog.defaultState;

	static __uniqueRef: MLDialog;

	static defaultState = {
		open: false,
		title: "",
		message: "",
		loading: false,
		onPositiveClick: () => false,
		// TODO se localizzo queste due stringhe crasha, Cannot read property 'strings' of undefined perchè non legge R
		positiveText: "Ok",
		negativeText: "Annulla",
		//@ts-ignore
		onNegativeClick: null,
		//@ts-ignore
		positiveButton: null,
		//@ts-ignore
		negativeButton: null,
	};

	static showModal(
		title: String,
		message: string | any,
		params?: MLDialogParams
	) {
		MLDialog.__uniqueRef.showModal(title, message, params);
	}

	static hideModal() {
		MLDialog.__uniqueRef.hideModal();
	}

	static showSnackbar(message: String, options?: OptionsObject) {
		MLDialog.__uniqueRef.showSnackbar(message, options);
	}

	static hideSnackbar(key: string | number | undefined) {
		MLDialog.__uniqueRef.hideSnackbar(key);
	}

	constructor(props: WithSnackbarProps) {
		super(props);
		MLDialog.__uniqueRef = this;
	}

	showModal(title: String, message: string | any, params?: MLDialogParams) {
		this.setState(
			Object.assign({}, MLDialog.defaultState, params, {
				title: title,
				message: message,
				open: true,
			})
		);
	}

	hideModal = () => {
		this.setState({ open: false });
	};

	showSnackbar(message: String, options?: OptionsObject) {
		this.props.enqueueSnackbar(message, {
			anchorOrigin: { horizontal: "right", vertical: "bottom" },
			...options,
		});
	}

	hideSnackbar(key: string | number | undefined) {
		this.props.closeSnackbar(key);
	}

	onPositiveClick = async () => {
		if (this.state.onPositiveClick) {
			const result: any = this.state.onPositiveClick();
			if (result && result.then) {
				this.setState((state) => ({ ...state, loading: true }));
				const response = await result;
				this.setState((state) => ({ ...state, loading: false }));
				if (response === true) {
					return;
				}
			}
			if (result === true) {
				return;
			}
		}
		this.hideModal();
	};

	render() {
		return (
			<Dialog
				open={this.state.open}
				onClose={this.hideModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				css={{
					"& .MuiDialog-paper": {
						padding: 24,
					},
				}}
			>
				<DialogTitle
					id="alert-dialog-title"
					children={this.state.title}
					css={{ padding: 0, paddingBottom: 24 }}
				/>
				<DialogContent css={{ padding: 0, paddingBottom: 24 }}>
					{typeof this.state.message === "string" ? (
						<DialogContentText
							id="alert-dialog-description"
							children={this.state.message}
							css={{ margin: 0 }}
						/>
					) : (
						this.state.message
					)}
				</DialogContent>
				<DialogActions
					css={{
						display: "flex",
						justifyContent: "space-between",
						padding: 0,
					}}
				>
					{this.state.negativeButton ||
						(this.state.onNegativeClick && (
							<Button
								color="primary"
								children={this.state.negativeText}
								onClick={() => {
									if (
										this.state.onNegativeClick &&
										(
											this.state
												.onNegativeClick! as Function
										)()
									) {
										// console.log(this.state.onNegativeClick);
										return;
									}
									this.hideModal();
								}}
							/>
						))}

					{isFunction(this.state.positiveButton)
						? //@ts-ignore
						  this?.state?.positiveButton?.(
								this.onPositiveClick,
								this.state.loading
						  )
						: this.state.positiveButton || (
								<Button
									autoFocus
									color="primary"
									children={this.state.positiveText}
									onClick={() => {
										if (
											this.state.onPositiveClick &&
											this.state.onPositiveClick()
										) {
											return;
										}
										this.hideModal();
									}}
								/>
						  )}
				</DialogActions>
			</Dialog>
		);
	}
}

const __MLDialog = withSnackbar(MLDialog);
