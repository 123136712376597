/** @jsxImportSource @emotion/react */
import { alpha, Typography, useTheme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Room, RoomTypeEnum } from "belvillage-api-manager";
import { Fragment } from "react";
import { backendMediaUrlParser } from "../../../../utils";

type Props = {
	roomId?: string;
	rooms: Room[];
	loading: boolean;
	onLocationChange: (location: Room) => void;
};

const LocationPicker: React.FC<Props> = ({
	roomId,
	rooms,
	loading,
	onLocationChange,
}) => {
	const theme = useTheme();
	return (
		<Fragment>
			{loading
				? Array.from(new Array(3)).map((elem, index) => {
						return (
							<div
								key={`room_loading_${index}`}
								css={{
									display: "flex",
									alignItems: "center",
									gap: 12,
									padding: 8,
									width: "100%",
									marginBottom: 8,
								}}
							>
								<Skeleton
									variant="rect"
									width={64}
									height={64}
									css={{ borderRadius: 8 }}
								/>
								<Skeleton
									variant="rect"
									width={"50%"}
									height={20}
									css={{ borderRadius: 4 }}
								/>
							</div>
						);
				  })
				: rooms?.map((room) => {
						if (room.type === RoomTypeEnum.Private) return null;
						return (
							<div
								key={room.id}
								onClick={() => onLocationChange(room)}
								css={{
									cursor: "pointer",
									display: "flex",
									alignItems: "center",
									gap: 12,
									padding: 8,
									width: "100%",
									borderRadius: 12,
									marginBottom: 8,
									border: "1px solid transparent",
									borderColor:
										roomId && room.id === roomId
											? theme.palette.primary.main
											: "transparent",
									backgroundColor: "transparent",
									transition: theme.transitions.create([
										"border-color",
										"background-color",
									]),
									"&:hover": {
										backgroundColor: alpha(
											theme.palette.primary.main,
											0.12
										),
									},
								}}
							>
								{room.image ? (
									<img
										src={backendMediaUrlParser(room.image)}
										alt={`${room.name}`}
										css={{
											width: 64,
											height: 64,
											borderRadius: 8,
											backgroundColor:
												"rgba(255, 255, 255, 0.11)",
										}}
									/>
								) : (
									<div
										css={{
											width: 64,
											height: 64,
											borderRadius: 8,
											backgroundColor:
												"rgba(255, 255, 255, 0.11)",
										}}
									/>
								)}
								<Typography
									children={room.name}
									variant="subtitle1"
									css={{ color: "white" }}
								/>
							</div>
						);
				  })}
		</Fragment>
	);
};

export default LocationPicker;
