/** @jsxImportSource @emotion/react */

import { CircularProgress, Grid, Grow, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import { Faq, FaqApi } from "belvillage-api-manager";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import MLDialog from "../../../components/poppers";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { apiErrorParser } from "../../../utils";
import { useApi } from "../../../utils/api";
import SingleFAQ from "./components/SingleFaq";

function List() {
	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();

	const { path: matchedPath } = useRouteMatch();

	useEffect(() => {
		drawerDispatch({ open: true });
		headerDispatch({ actionText: "ADD NEW" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const faqApi = useApi(FaqApi);

	// const { data: faq, loading } = useFetch(faqApi.getAllAdminFaq);

	const containerRef = useRef<HTMLDivElement>(null);

	const [faq, setFaq] = useState<Faq[]>([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		faqApi
			.getAllAdminFaq()
			.then((data) => {
				setFaq(data.data);
			})
			.catch((error: AxiosError) =>
				MLDialog.showSnackbar(apiErrorParser(error), {
					variant: "error",
				})
			)
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const revalidate = useCallback(() => {
		faqApi
			.getAllAdminFaq()
			.then((data) => {
				setFaq(data.data);
			})
			.catch((error: AxiosError) =>
				MLDialog.showSnackbar(apiErrorParser(error), {
					variant: "error",
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changesRef = useRef<{
		[key: string]: { newIndex: number; answer: string; question: string };
	}>({});
	const timeoutRef = useRef<NodeJS.Timeout>();

	const updateElem = useCallback((elem: Faq, newIndex: number) => {
		changesRef.current[elem.id] = { ...elem, newIndex };
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			Promise.all(
				Object.keys(changesRef.current).map((key) => {
					return faqApi.editFaq(key, {
						position: changesRef.current[key].newIndex,
						question: changesRef.current[key].question,
						answer: changesRef.current[key].answer,
					});
				})
			)
				.catch((err) => {
					MLDialog.showSnackbar(apiErrorParser(err), {
						variant: "error",
					});
				})
				.finally(() => {
					changesRef.current = {};
					revalidate();
				});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			ref={containerRef}
			css={{
				width: "100%",
				height: "100%",
				position: "relative",
				padding: "0px 24px",
				paddingTop: 42,
			}}
		>
			<Grid
				container
				spacing={2}
				css={{
					boxSizing: "border-box",
					width: `calc(100% - ${
						(containerRef.current?.offsetLeft ?? 0) + 24
					}px)`,
					position: "fixed",
					top: 60,
					right: 0,
					backgroundColor: "#131313",
					zIndex: 1,
					margin: 0,
				}}
			>
				<Grid item xs={1}>
					<Typography
						children={`Tidy up`}
						variant="overline"
						css={{ opacity: 0.6 }}
					/>
				</Grid>
				<Grid item xs={5}>
					<Typography
						children={`Question`}
						variant="overline"
						css={{ opacity: 0.6 }}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography
						children={`Answer`}
						variant="overline"
						css={{ opacity: 0.6 }}
					/>
				</Grid>
			</Grid>
			{loading && (
				<div
					css={{
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress />
					<Typography
						children="Loading F.A.Q"
						variant="subtitle1"
						color="primary"
					/>
				</div>
			)}

			{faq?.map((elem, i) => {
				return (
					<Grow
						key={elem?.id}
						appear={true}
						in={true}
						timeout={i * 300 + 300}
					>
						<SingleFAQ
							FAQ={elem}
							matchedPath={matchedPath}
							index={i}
							faqNumber={faq.length}
							goUp={() => {
								if (i > 0) {
									updateElem(elem, i - 1);
									const temp = faq[i - 1];
									faq[i - 1] = faq[i];
									faq[i] = temp;
									setFaq([...faq]);
								}
							}}
							goDown={() => {
								if (i < faq.length - 1) {
									updateElem(elem, i + 1);
									const temp = faq[i];
									faq[i] = faq[i + 1];
									faq[i + 1] = temp;
									setFaq([...faq]);
								}
							}}
						/>
					</Grow>
				);
			})}
		</div>
	);
}
export default List;
