/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { BinIcon, CloseIcon } from "../../../../components/BelVillageIcons";

export default function DialogDeleteAirConditioning(props: {
  open: boolean;
  onClose: () => void;
  onDeleteConfirmation: () => void;
}) {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <DialogTitle children="Delete this Air conditioning system?" />
      <DialogContent>
        <Typography
          variant="body1"
          children="Are you sure to delete this Air conditioning system? This action is irreversible"
        />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item css={{ flex: 1 }}>
            <Button
              startIcon={<CloseIcon width={18} />}
              children="Cancel"
              onClick={() => props.onClose()}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<BinIcon width={18} />}
              children="Delete"
              variant="contained"
              color="primary"
              onClick={() => props.onDeleteConfirmation()}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
