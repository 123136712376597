import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function StarIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "24"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 24"
		>
			<path
				fill={color || "#EF7705"}
				d="M12.897 3.557a1 1 0 00-1.794 0L8.985 7.85l-4.737.688a1 1 0 00-.554 1.706l3.427 3.341-.809 4.718a1 1 0 001.451 1.054L12 17.13l4.237 2.227a1 1 0 001.451-1.054l-.81-4.718 3.428-3.341a1 1 0 00-.554-1.706l-4.737-.688-2.118-4.293z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default StarIcon;
