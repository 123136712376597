"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var NotificationTypeEnum;
(function (NotificationTypeEnum) {
    NotificationTypeEnum["NewReview"] = "newReview";
    NotificationTypeEnum["NewEvent"] = "newEvent";
    NotificationTypeEnum["EventJoinedByAccount"] = "eventJoinedByAccount";
    NotificationTypeEnum["BelvillageCommunication"] = "belvillageCommunication";
    NotificationTypeEnum["JoinedEventEdited"] = "joinedEventEdited";
    NotificationTypeEnum["JoinedEventDeleted"] = "joinedEventDeleted";
    NotificationTypeEnum["NewAppointmentDate"] = "newAppointmentDate";
    NotificationTypeEnum["ResolvedTicket"] = "resolvedTicket";
    NotificationTypeEnum["OneDayUntilCheckIn"] = "oneDayUntilCheckIn";
    NotificationTypeEnum["NewRentService"] = "newRentService";
    NotificationTypeEnum["NewPurchaseService"] = "newPurchaseService";
    NotificationTypeEnum["NewAccountDocument"] = "newAccountDocument";
    NotificationTypeEnum["TwoDaysUntilCheckIn"] = "twoDaysUntilCheckIn";
    NotificationTypeEnum["ReportedEventRemoved"] = "reportedEventRemoved";
    NotificationTypeEnum["ReportedEventKept"] = "reportedEventKept";
    NotificationTypeEnum["ReviewRedacted"] = "reviewRedacted";
    NotificationTypeEnum["EventRedacted"] = "eventRedacted";
    NotificationTypeEnum["TimerActivityReminder"] = "timerActivityReminder";
    NotificationTypeEnum["CreatedEventRedacted"] = "createdEventRedacted";
})(NotificationTypeEnum = exports.NotificationTypeEnum || (exports.NotificationTypeEnum = {}));
