/** @jsxImportSource @emotion/react */

import { Collapse, Grid, ListItem, ListItemIcon, ListItemText, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { ChevronDown } from "react-feather";
import { useHistory, useLocation } from "react-router";
import { ListItemRoute } from "./NavBar";

export interface AdminListItemProps {
  route: ListItemRoute;
  onClick?: () => void;
  style?: any;
}

function AdminListItem({ route, onClick, style }: AdminListItemProps) {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const currPath = location.pathname;

  const textColor = theme.palette.text.primary;

  const selected = route?.path && !route.subpaths && currPath.includes(route?.path);

  const subpaths = route?.subpaths?.map((x) => x.path) || [];

  const [open, setOpen] = useState(subpaths.some((x) => x === currPath));

  return (
    <React.Fragment>
      <ListItem
        button
        css={{ padding: "0px 15px", margin: 0, marginBottom: 4, ...style }}
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }

          if (route.subpaths) {
            setOpen(!open);
          } else {
            route?.path && history.push(route.path);
          }
        }}
      >
        <Grid
          container
          alignItems="center"
          css={{
            transition: theme.transitions.create(["background", "transform"]),
            background: open
              ? "linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #070707"
              : selected
              ? "linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #070707"
              : "transparent",
            padding: "12px 15px",

            borderRadius: 12,
            ":hover": {},
          }}
        >
          <ListItemIcon
            css={{
              color: selected ? theme.palette.primary.main : textColor,
              svg: {
                path: {
                  fill: selected ? theme.palette.primary.main : textColor,
                },
              },
            }}
          >
            {route?.icon}
          </ListItemIcon>
          <ListItemText
            css={{
              fontSize: 15,
              margin: 0,
              fontWeight: selected ? 600 : 400,
              color: selected ? theme.palette.primary.main : textColor,
            }}
            primary={route.label}
          />
          {route.subpaths && (
            <ChevronDown
              css={{
                color: selected ? theme.palette.primary.main : textColor,
                transition: theme.transitions.create(["transform"]),
                transform: `rotateZ(${open ? "180" : "0"}deg)`,
              }}
              size={16}
              onClick={() => {
                setOpen(!open);
              }}
            />
          )}
        </Grid>
      </ListItem>
      {route.subpaths && (
        <Collapse in={open}>
          {route.subpaths.map((subRoute, index) => (
            <AdminListItem key={subRoute.path} route={subRoute} />
          ))}
        </Collapse>
      )}
    </React.Fragment>
  );
}

export default AdminListItem;
