import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const UploadIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      fill="none"
      fillOpacity={opacity || 100}
      viewBox="0 0 25 24"
    >
      <path
        fill={color || "#EF7705"}
        d="M13.055 8.309l3.724 3.731a.805.805 0 01.015 1.112.787.787 0 01-1.108-.001l-2.402-2.407v8.433c0 .22-.085.412-.227.555a.8.8 0 01-.555.229.787.787 0 01-.782-.784v-8.433L9.32 13.15a.787.787 0 01-1.109 0 .783.783 0 01-.228-.555c0-.202.077-.403.228-.555l3.733-3.74a.76.76 0 01.427-.218c.012 0 .023-.002.029-.003h.001l.008-.002h.004l.083-.005c.03 0 .051 0 .071.004l.008.001h.008l.007.001h.001a.775.775 0 01.464.23v.001zm7.316-3.614a.71.71 0 01.022.173v3.548a.7.7 0 01-.189.471.658.658 0 01-.462.191.659.659 0 01-.656-.657V5.48H5.904V8.42a.66.66 0 01-.656.657.659.659 0 01-.656-.657V4.873c0-.052 0-.081.008-.114l.002-.012a.658.658 0 01.651-.587h14.474c.314 0 .581.228.642.531l.002.004z"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default UploadIcon;
