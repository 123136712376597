/** @jsxImportSource @emotion/react */
import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function DeleteIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "25"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 25"
		>
			<path
				fill={color || "#EF7705"}
				d="M3.305 6.69a.528.528 0 01-.155-.372.551.551 0 01.53-.53h5.033v-.784a1.659 1.659 0 011.659-1.659h2.668a1.66 1.66 0 011.66 1.66v.782h5.031a.551.551 0 01.53.531.529.529 0 01-.527.526h-1.326v10.734a3.445 3.445 0 01-3.469 3.467H8.495a3.468 3.468 0 01-3.468-3.468V6.844H3.678a.528.528 0 01-.373-.155zm6.464-1.686v.783h3.873v-.783a.602.602 0 00-.602-.602h-2.668a.602.602 0 00-.603.602zm7.582 12.573V6.844H6.084v10.733a2.411 2.411 0 002.41 2.411h6.446a2.411 2.411 0 002.41-2.41zm-8.37-.192a.528.528 0 01-.37.155.55.55 0 01-.532-.53V9.322a.528.528 0 01.529-.528h.005a.506.506 0 01.523.523v7.694c0 .14-.055.274-.155.373zm6.351-8.062v7.686a.551.551 0 01-.53.531.528.528 0 01-.527-.528V9.318a.507.507 0 01.524-.523h.005a.528.528 0 01.528.528zm-3.252 8.062a.528.528 0 01-.902-.373V9.323a.528.528 0 011.056 0v7.689c0 .14-.055.274-.154.373z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default DeleteIcon;
