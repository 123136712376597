import { Button, ButtonProps, CircularProgress } from "@material-ui/core";

type Props = { loading: boolean; startIcon: React.ReactNode } & ButtonProps;

const LoadingIconButton: React.FC<Props> = ({
	loading,
	startIcon,
	...props
}) => {
	return (
		<Button
			startIcon={
				loading ? (
					<CircularProgress color="inherit" size={24} />
				) : (
					startIcon
				)
			}
			disabled={loading}
			{...props}
		/>
	);
};

export default LoadingIconButton;
