/**@jsxImportSource @emotion/react */

import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { useState } from "react";
import { PencilEditIcon } from "../../../../components/BelVillageIcons";

export default function BuildingTicketEmailEditor(props: {
  monitoringEmail?: string;
  onEdit: () => void;
}) {
  return (
    <TableRow
      css={{
        backgroundColor: "transparent",
        cursor: "none",
        "&::before": { background: "none" },
        "&:hover": {
          backgroundColor: "transparent",
          background: "none",
          "&::before": { background: "none" },
        },
      }}
    >
      <TableCell>
        <Typography children="Monitoring Mail" variant="subtitle2" />
      </TableCell>
      <TableCell>
        <Typography
          children={props.monitoringEmail || "No email entered"}
          variant="body2"
        />
      </TableCell>
      <TableCell>
        <IconButton
          children={<PencilEditIcon />}
          onClick={() => {
            props.onEdit();
          }}
        />
      </TableCell>
    </TableRow>
  );
}
