/** @jsxImportSource @emotion/react */

import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Grow,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import { PlaceApi } from "belvillage-api-manager";
import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { BinIcon } from "../../../components/BelVillageIcons";
import CloseCancIcon from "../../../components/icons/close_canc";
import DeleteIcon from "../../../components/icons/delete";
import LoadingIconButton from "../../../components/LoadingIconButton";
import MLDialog from "../../../components/poppers";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { apiErrorParser, backendMediaUrlParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

export function CreateEditView(props: { parentPath: string }) {
	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();

	useEffect(() => {
		drawerDispatch({
			open: true,
			editMode: true,
			editModelName: "advices",
		});
		headerDispatch({ actionVisible: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const history = useHistory();

	const { id, buildingId } = useParams<any>();
	const placeApi = useApi(PlaceApi);
	const { data: place, loading } = useFetch(placeApi.getAdminPlaceById, id, {
		skip: !id,
	});

	const deletePlace = useCallback(() => {
		if (place) {
			return placeApi
				.deletePlace(place.id)
				.then(() => {
					MLDialog.showSnackbar("Place deleted successfully", {
						variant: "success",
					});
					history.replace(`/${buildingId}/advices`);
				})
				.catch((e) => {
					MLDialog.showSnackbar(apiErrorParser(e), {
						variant: "error",
					});
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buildingId, history, place]);

	const theme = useTheme();

	if ((loading || !place) && Boolean(id)) {
		return (
			<Grid container alignItems="center" css={{ height: "100%" }}>
				<Grid container justify="center">
					<CircularProgress
						size={60}
						variant="indeterminate"
						color="primary"
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid
			container
			alignItems="flex-start"
			alignContent="flex-start"
			css={{
				padding: "26px 48px",
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8} lg={6}>
					<Grow appear={true} in={true}>
						<Card>
							<CardContent
								css={{
									">*": {
										marginBottom: 24,
									},
								}}
							>
								<Typography
									variant="h6"
									children={id ? `Modify place` : "New place"}
									gutterBottom
								/>
								<Grid
									container
									spacing={3}
									css={{ marginBottom: "0px !important" }}
								>
									<Grid item xs="auto" css={{ height: 128 }}>
										{place ? (
											<img
												src={backendMediaUrlParser(
													place.photo
												)}
												css={{
													border: "1px solid #EF7705",
													height: 128,
													width: 128,
													borderRadius: 12,
													objectFit: "cover",
												}}
												alt={`${place.name}`}
											/>
										) : (
											<div
												css={{
													border: "1px solid #EF7705",
													height: 128,
													width: 128,
													borderRadius: 12,
												}}
											/>
										)}
									</Grid>
									<Grid item xs>
										<TextField
											value={place ? place.name : ""}
											disabled
											variant="outlined"
											label="Place name"
										/>
										<div
											css={{
												paddingTop: 20,
												display: "flex",
												flexWrap: "wrap",
												gap: 8,
											}}
										>
											{place?.categories.map(
												(category, index) => {
													return (
														<Typography
															key={`place_category_${place.id}_${category.id}_${index}`}
															children={
																category.name
																	.charAt(0)
																	.toUpperCase() +
																category.name.slice(
																	1
																)
															}
															variant="caption"
															css={{
																backgroundColor:
																	theme
																		.palette
																		.primary
																		.main,
																padding:
																	"10px 12px",
																borderRadius: 20,
																fontWeight: 500,
															}}
														/>
													);
												}
											)}
										</div>
									</Grid>
									<Grid
										item
										xs={12}
										css={{
											paddingBottom: "0px !important",
										}}
									>
										<Grid
											container
											justifyContent={"space-between"}
											css={{ paddingTop: 24 }}
										>
											<Grid item>
												<Button
													variant="text"
													children="Delete"
													startIcon={<BinIcon />}
													onClick={() => {
														MLDialog.showModal(
															"Delete place",
															"Are you sure you want to delete this place?",
															{
																onPositiveClick:
																	deletePlace,
																positiveButton:
																	(
																		onClick,
																		loading
																	) => {
																		return (
																			<LoadingIconButton
																				loading={
																					loading
																				}
																				variant="contained"
																				color="primary"
																				children="Delete"
																				startIcon={
																					<DeleteIcon
																						color="#fff"
																						size={
																							24
																						}
																					/>
																				}
																				onClick={
																					onClick
																				}
																			/>
																		);
																	},
																negativeButton:
																	(
																		<Button
																			children="Cancel"
																			startIcon={
																				<CloseCancIcon />
																			}
																			onClick={
																				MLDialog.hideModal
																			}
																			variant="outlined"
																		/>
																	),
															}
														);
													}}
													css={{ height: 48 }}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grow>
				</Grid>
			</Grid>
		</Grid>
	);
}
