/** @jsxImportSource @emotion/react */

import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { AdminUtilityApi } from "belvillage-api-manager";
import { useEffect } from "react";
import { useRouteMatch } from "react-router";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { useApi, useFetch } from "../../../utils/api";

function ConfigPage(props: any) {
  const { path } = useRouteMatch();

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  const adminUtility = useApi(AdminUtilityApi);

  useEffect(() => {
    drawerDispatch({ open: true });
    headerDispatch({ actionVisible: false });
  }, []);

  const { data } = useFetch(adminUtility.getEnvData);

  return (
    <Grid
      container
      alignItems="flex-start"
      alignContent="flex-start"
      css={{ padding: "36px 48px" }}
    >
      <Grid container spacing={1}>
        <Card>
          <CardContent>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography
                      css={{ color: "white" }}
                      variant="h6"
                      children="Version: "
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      css={{ color: "white" }}
                      children={data?.backendVersion}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography
                      css={{ color: "white" }}
                      variant="h6"
                      children="isDifferenceEnabled:  "
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      css={{ color: "white" }}
                      children={`${data?.isDifferenceEnabled}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ConfigPage;
