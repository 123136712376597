import { Dispatch, useReducer } from "react";
import { DeepKeyof, updateObject } from "../utils";

/**
 *
 * @description Generates a reducer with **all the keys** of the `initialState` object plus *"UPDATE_STATE"* as `action.field` accepted values returning the `useReducer` result.
 * @returns {T} initialState - the state of the reducer
 * @returns {function} dispatcher - function which allows in input `field` - the field to edit  or `UPDATE_STATE` and `value` - the new value of the field.
 */

export function useFormInputReducer<T>(initialState: T): [
  T,
  Dispatch<{
    field: (T extends object ? DeepKeyof<T> : keyof T) | "UPDATE_STATE";
    value: any;
  }>
] {
  //@ts-expect-error due to the fact that is field is of a recursive type and "possibly infinite"
  return useReducer(
    (
      state: T,
      action: {
        field: keyof T | "UPDATE_STATE";
        value: string | number | any | T;
      }
    ) => {
      // const stateKeys = Object.keys(state);

      if (action.field === "UPDATE_STATE" && typeof action.value === "object") {
        return { ...state, ...action.value } as T;
      }

      // const matchedKey = stateKeys.some((x) => x === action.field);
      // if (!matchedKey) throw new Error("invalidFieldReducer");

      // return { ...state, [action.field]: action.value } as T;
      const tmpState = { ...state };
      updateObject(tmpState, action.field as string, action.value);
      return { ...tmpState } as T;
    },
    initialState
  );
}
// export function useFormInputReducer<T>(initialState: T): [
//   T,
//   Dispatch<{
//     field:  keyof<T>  | "UPDATE_STATE";
//     value: any;
//   }>
// ] {
//   //@ts-expect-error due to the fact that is field is of a recursive type and "possibly infinite"
//   return useReducer(
//     (
//       state: T,
//       action: {
//         field: keyof T | "UPDATE_STATE";
//         value: string | number | any | T;
//       }
//     ) => {
//       const stateKeys = Object.keys(state);

//       if (action.field === "UPDATE_STATE" && typeof action.value === "object") {
//         return { ...state, ...action.value } as T;
//       }

//       const matchedKey = stateKeys.some((x) => x === action.field);
//       if (!matchedKey) throw new Error("invalidFieldReducer");

//       return { ...state, [action.field]: action.value } as T;
//     },
//     initialState
//   );
// }
