/** @jsxImportSource @emotion/react */

import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Grow,
	TextField,
	Typography,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { FaqApi } from "belvillage-api-manager";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { BinIcon, DoneIcon } from "../../../components/BelVillageIcons";
import CloseCancIcon from "../../../components/icons/close_canc";
import DeleteIcon from "../../../components/icons/delete";
import LoadingIconButton from "../../../components/LoadingIconButton";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { apiErrorParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

const initialState = {
	id: "",
	question: "",
	answer: "",
};

export function CreateEditView(props: { parentPath: string }) {
	const history = useHistory();
	const { id } = useParams<any>();
	const faqApi = useApi(FaqApi);
	const { data, loading } = useFetch(faqApi.getAdminFaqById, id, {
		skip: !id,
	});

	const [state, dispatch] = useFormInputReducer(initialState);

	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();

	useEffect(() => {
		drawerDispatch({
			open: true,
			editMode: true,
			editModelName: "FAQ",
		});
		headerDispatch({ actionVisible: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (data) {
			const initialKeys = Object.keys(initialState);

			const availableDataFromInitialState = {};
			initialKeys.forEach((key) => {
				//@ts-ignore
				availableDataFromInitialState[key] = data[key];
			});

			dispatch({
				field: "UPDATE_STATE",
				value: availableDataFromInitialState as typeof initialState,
			});
		}
	}, [data, dispatch]);

	const [saveLoading, setSaveLoading] = useState(false);

	function saveDataToBackend(exit?: boolean) {
		const { id, ...others } = state;
		setSaveLoading(true);
		if (id) {
			//! EDIT
			faqApi
				.editFaq(id, { ...others })
				.then((value) => {
					if (exit) history.push(props.parentPath);
					else
						MLDialog.showSnackbar("Saved successfully", {
							variant: "success",
						});
				})
				.catch((error: AxiosError) =>
					MLDialog.showSnackbar(apiErrorParser(error), {
						variant: "error",
					})
				)
				.finally(() => setSaveLoading(false));
		} else {
			//! ADD
			faqApi
				.createFaq({ ...others })
				.then((value) => {
					MLDialog.showSnackbar("Created successfully", {
						variant: "success",
					});
					history.replace(props.parentPath + "/" + value.data?.id);
				})
				.catch((error: AxiosError) =>
					MLDialog.showSnackbar(apiErrorParser(error), {
						variant: "error",
					})
				)
				.finally(() => setSaveLoading(false));
		}
	}

	const deleteFAQ = useCallback(() => {
		return faqApi
			.deleteFaq(id)
			.then(() => {
				history.replace("/faq");
			})
			.catch((error) =>
				MLDialog.showSnackbar(apiErrorParser(error), {
					variant: "error",
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, id]);

	if ((!state || loading || !data) && Boolean(id)) {
		return (
			<Grid container alignItems="center" css={{ height: "100%" }}>
				<Grid container justify="center">
					<CircularProgress
						size={60}
						variant="indeterminate"
						color="primary"
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid
			container
			alignItems="flex-start"
			alignContent="flex-start"
			css={{
				padding: "26px 48px",
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8} lg={6}>
					<Grow appear={true} in={true}>
						<Card>
							<CardContent
								css={{
									">*": {
										marginBottom: 24,
									},
								}}
							>
								<Typography
									variant="h6"
									children={(id ? "Edit" : "New") + " Faq"}
									gutterBottom
								/>
								<TextField
									{...valueChangeReducerHelper("question")}
									label="Question"
									fullWidth
									variant="outlined"
								/>
								<TextField
									{...valueChangeReducerHelper("answer")}
									label="Answer"
									fullWidth
									variant="outlined"
									multiline
								/>
								<Grid
									container
									justifyContent={
										id ? "space-between" : "flex-end"
									}
								>
									{id && (
										<Button
											variant="text"
											children="Delete"
											startIcon={<BinIcon />}
											onClick={async () => {
												MLDialog.showModal(
													"Delete FAQ",
													"Are you sure you want to delete this FAQ?",
													{
														onPositiveClick:
															deleteFAQ,
														positiveButton: (
															onClick,
															loading
														) => {
															return (
																<LoadingIconButton
																	loading={
																		loading
																	}
																	variant="contained"
																	color="primary"
																	children="Delete"
																	startIcon={
																		<DeleteIcon
																			color="#fff"
																			size={
																				24
																			}
																		/>
																	}
																	onClick={
																		onClick
																	}
																/>
															);
														},
														negativeButton: (
															<Button
																children="Cancel"
																startIcon={
																	<CloseCancIcon />
																}
																onClick={
																	MLDialog.hideModal
																}
																variant="outlined"
															/>
														),
													}
												);
											}}
											css={{ height: 48 }}
										/>
									)}
									<LoadingIconButton
										loading={saveLoading}
										variant="contained"
										color="primary"
										children="Confirm"
										startIcon={<DoneIcon />}
										onClick={() => {
											saveDataToBackend(true);
										}}
										css={{ height: 48 }}
									/>
								</Grid>
							</CardContent>
						</Card>
					</Grow>
				</Grid>
			</Grid>
		</Grid>
	);

	function valueChangeReducerHelper(field: keyof typeof initialState) {
		return {
			value: state[field],
			onChange: (event: any) =>
				dispatch({ field, value: event.target.value }),
		};
	}
}
