/** @jsxImportSource @emotion/react */

import {
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Grow,
	Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import EventActions from "./components/EventActions";
import { EventDisplayer } from "./components/EventDisplayer";
import EventEditForm from "./components/EventEditForm";
import EventReports from "./components/EventReport";
import {
	EventContextProvider,
	useEventContextSelector,
	useEventController,
} from "./controllers/EventController";

const MainView = () => {
	const { id } = useParams<any>();
	const event = useEventContextSelector("event");
	const eventLoading = useEventContextSelector("eventLoading");
	const revalidateEvent = useEventContextSelector("revalidateEvent");
	const parentPath = useEventContextSelector("parentPath");
	return (
		<Grid
			container
			alignItems="flex-start"
			alignContent="flex-start"
			css={{
				padding: "26px 48px",
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8} lg={6}>
					<Grow appear={true} in={true}>
						<Card>
							<CardContent
								css={{
									">*": {
										marginBottom: 24,
									},
								}}
							>
								<Typography
									variant="h6"
									children={
										id
											? !event?.owner?.isAdmin
												? "Guest event"
												: `Modify ${event?.name} event`
											: "New event"
									}
									gutterBottom
								/>
								{id ? (
									eventLoading ? (
										<CircularProgress />
									) : event?.owner?.isAdmin ? (
										<EventEditForm />
									) : (
										<EventDisplayer event={event!} />
									)
								) : (
									<EventEditForm new />
								)}
								{event?.owner?.isAdmin === false ? (
									event?.reports &&
									event?.reports.length > 0 ? (
										<EventReports
											event={event!}
											parentPath={parentPath}
											revalidate={revalidateEvent}
										/>
									) : (
										<EventActions
											event={event!}
											parentPath={parentPath}
										/>
									)
								) : null}
							</CardContent>
						</Card>
					</Grow>
				</Grid>
			</Grid>
		</Grid>
	);
};

export function CreateEditView(props: { parentPath: string }) {
	const { id } = useParams<any>();

	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();

	useEffect(() => {
		drawerDispatch({
			open: true,
			editMode: true,
			editModelName: "events",
		});
		headerDispatch({ actionVisible: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const eventController = useEventController(props.parentPath);

	if (
		(!eventController.eventEditState ||
			eventController.eventLoading ||
			!eventController.event) &&
		Boolean(id)
	) {
		return (
			<Grid container alignItems="center" css={{ height: "100%" }}>
				<Grid container justify="center">
					<CircularProgress
						size={60}
						variant="indeterminate"
						color="primary"
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<EventContextProvider eventContext={eventController}>
			<MainView />
		</EventContextProvider>
	);
}
