/** @jsxImportSource @emotion/react */

import { Container, Grow, TableCell, TableRow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { EventApi } from "belvillage-api-manager";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdminTable, {
	defaultQueryFilter,
	IHeadCell,
	IQueryFilter,
	parseCountFrom,
	transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import theme from "../../../theme";
import { backendMediaUrlParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";
import ReportsComponent from "./components/ReportsComponent";

function List() {
	const history = useHistory();
	const drawerDispatch = useDrawerDispatchHelper();
	const headerDispatch = useHeaderDispatchHelper();
	const eventApi = useApi(EventApi);
	const [filter, setFilter] = useState<IQueryFilter>({
		...defaultQueryFilter,
	});

	const { params, url } = useRouteMatch();

	const { buildingId } = params as any;

	useEffect(() => {
		drawerDispatch({ open: true, buildingSelected: true });
		headerDispatch({ actionVisible: true });
	}, []);

	const {
		data: events,
		rawData,
		loading,
	} = useFetch(
		eventApi.getAllEventsByBuildingId,
		buildingId,
		transformToStringFrom(filter),
		{ skip: !buildingId }
	);

	return (
		<Container maxWidth="xl" css={{ height: "100%" }}>
			<AdminTable
				headCells={headCells}
				fullWidth
				fullHeight
				loading={loading}
				queryFilter={[filter, setFilter]}
				count={parseCountFrom(rawData)}
				data={events}
				size="small"
			>
				{(data) => {
					return data?.map((event, i) => {
						return (
							<Grow
								key={event?.id}
								appear={true}
								in={true}
								timeout={i * 300 + 300}
							>
								<TableRow
									key={event?.id}
									onClick={() => {
										history.push(url + "/" + event.id);
									}}
								>
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
										>
											<Grid
												container
												alignItems="center"
												spacing={3}
											>
												<Grid item>
													<div
														css={{
															width: 40,
															height: 40,
															borderRadius:
																"100%",
															border: `1px solid ${theme.palette.primary.main} `,
															backgroundImage: `url(${backendMediaUrlParser(
																event.owner
																	?.profile_image
															)})`,
															backgroundPosition:
																"center",
															backgroundSize:
																"contain",
														}}
													/>
												</Grid>
												<Grid item>
													{event.owner?.name}
												</Grid>
											</Grid>
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
										>
											{event.name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
										>
											{event.room.name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant="body1"
											color="textPrimary"
										>
											{moment(event.time?.start).format(
												"MMMM, dddd Do, hh:mma"
											)}
										</Typography>
									</TableCell>
									<TableCell>
										{event.reports?.length ? (
											<ReportsComponent
												reports={event.reports?.length}
											/>
										) : (
											""
										)}
									</TableCell>

									<TableCell
										css={{
											textAlign: "right",
											paddingRight: 24,
										}}
									>
										<CircledEditIcon />
									</TableCell>
								</TableRow>
							</Grow>
						);
					});
				}}
			</AdminTable>
		</Container>
	);
}

const headCells: IHeadCell[] = [
	{ id: "owner.name", label: "Event Creator" },
	{ id: "name", label: "Event Name" },
	{ id: "room.name", label: "Location" },
	{ id: "time.start", label: "Schedule" },
	{ id: "reports", label: "Reports" },
	{ id: "edit", label: "", notSelectable: true },
];

export default List;
