/** @jsxImportSource @emotion/react */
import { alpha, Button, darken, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { EventApi, LightEvent } from "belvillage-api-manager";
import { DateTime, Duration } from "luxon";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import MLDialog from "../../../../components/poppers";
import { apiErrorParser } from "../../../../utils";
import { useApi } from "../../../../utils/api";
import { useEventContextSelector } from "../controllers/EventController";

type AlreadyCreatedEventProps = {
	event: LightEvent;
};
const AlreadyCreatedEvent: React.FC<AlreadyCreatedEventProps> = ({ event }) => {
	return (
		<div
			css={{
				display: "flex",
				alignItems: "center",
				borderRadius: 8,
				backgroundColor: "rgba(255, 255, 255, 0.12)",
				height: 48,
				width: "100%",
				overflow: "hidden",
				opacity: 0.6,
				marginBottom: 8,
			}}
		>
			<div
				css={{
					height: "100%",
					width: 4,
					backgroundColor: "rgba(123, 97, 255, 0.38)",
				}}
			/>
			<div css={{ flexGrow: 1, padding: "0px 8px" }}>
				<div
					css={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						children={event.name}
						variant="overline"
						noWrap
						css={{ color: "white" }}
					/>
					<Typography
						noWrap
						children={
							event.from &&
							event.to &&
							`${DateTime.fromISO(event.from).toFormat(
								"MMMM, EEEE dd, t"
							)} - ${DateTime.fromISO(event.to).toFormat("t")}`
						}
						variant="caption"
						css={{ color: "white" }}
					/>
				</div>
			</div>
		</div>
	);
};

type SlotSelectorProps = {
	slot: { start: DateTime; end: DateTime };
	selectedStart?: DateTime;
	selectedEnd?: DateTime;
};
const SlotSelector: React.FC<SlotSelectorProps> = ({
	slot,
	selectedStart,
	selectedEnd,
}) => {
	const selectedStartHour = selectedStart?.startOf("hour");
	const selectedEndHour = selectedEnd?.startOf("hour");
	const isStart = selectedStartHour && slot.start.equals(selectedStartHour);
	const isEnd = selectedEndHour && slot.end.equals(selectedEndHour);

	const isEnabled =
		selectedStartHour === undefined ||
		selectedEndHour === undefined ||
		isStart ||
		isEnd ||
		slot.end.equals(selectedStartHour) ||
		slot.start.equals(selectedEndHour);

	const shouldSelectStart =
		selectedStartHour && isEnabled && slot.end.equals(selectedStartHour);
	const shouldSelectEnd =
		selectedEndHour && isEnabled && slot.start.equals(selectedEndHour);

	const eventEditState = useEventContextSelector("eventEditState");
	const onEventStateChange = useEventContextSelector("onEventStateChange");

	const onRangeChange = useCallback(
		(
			time:
				| { start: DateTime; end: DateTime }
				| { start: DateTime; end: undefined }
				| { start: undefined; end: DateTime }
				| { start: undefined; end: undefined }
		) => {
			if (time.start && time.end) {
				onEventStateChange({
					time: {
						start: time.start.toISO(),
						end: time.end.toISO(),
					},
				});
			} else if (time.start) {
				onEventStateChange({
					time: {
						start: time.start.toISO(),
						end: eventEditState.time.end,
					},
				});
			} else if (time.end) {
				onEventStateChange({
					time: {
						start: eventEditState.time.start,
						end: time.end.toISO(),
					},
				});
			} else {
				onEventStateChange({
					time: {
						start: undefined,
						end: undefined,
					},
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[eventEditState.time]
	);

	return (
		<Button
			fullWidth
			variant="outlined"
			color="secondary"
			disabled={!isEnabled}
			onClick={() => {
				if (isStart && isEnd) {
					onRangeChange({ start: undefined, end: undefined });
					return;
				}
				if (isStart) {
					onRangeChange({ start: slot.end, end: undefined });
					return;
				}
				if (isEnd) {
					onRangeChange({ end: slot.start, start: undefined });
					return;
				}
				if (shouldSelectStart) {
					onRangeChange({ start: slot.start, end: undefined });
					return;
				}
				if (shouldSelectEnd) {
					onRangeChange({ end: slot.end, start: undefined });
					return;
				}
				if (!selectedStartHour && !selectedEndHour) {
					onRangeChange({ start: slot.start, end: slot.end });
					return;
				}
			}}
			css={{
				marginBottom: 8,
				borderColor: "rgba(255, 255, 255, 0.12) !important",
				backgroundColor:
					isStart || isEnd ? "rgba(3, 104, 255, 1)" : "unset",
				"&:hover": {
					backgroundColor:
						isStart || isEnd
							? `${darken(
									"rgba(3, 104, 255, 1)",
									0.1
							  )} !important`
							: `${alpha(
									"rgba(3, 104, 255, 1)",
									0.6
							  )} !important`,
				},
				...(selectedStartHour &&
					slot.start >= selectedStartHour &&
					selectedEndHour &&
					slot.end <= selectedEndHour && {
						"&.Mui-disabled": {
							backgroundColor: `${alpha(
								"rgba(3, 104, 255, 1)",
								0.36
							)} !important`,
						},
					}),
			}}
		>{`${slot.start.toFormat("t")} - ${slot.end.toFormat("t")}`}</Button>
	);
};

type TimeSlotsPickerProps = {};
const TimeSlotsPicker: React.FC<TimeSlotsPickerProps> = () => {
	const { id } = useParams<any>();
	const eventApi = useApi(EventApi);
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState<LightEvent[]>([]);

	const eventEditState = useEventContextSelector("eventEditState");

	useEffect(() => {
		if (eventEditState.date && eventEditState.room) {
			setLoading(true);
			eventApi
				.getRoomEvents(eventEditState.date, eventEditState.room)
				.then((res) => {
					console.log(res.data);
					setEvents(res.data.filter((elem) => elem.id !== id));
				})
				.catch((e) => {
					MLDialog.showSnackbar(apiErrorParser(e), {
						variant: "error",
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventEditState.date, eventEditState.room]);

	const availableTimeSlots = useMemo(() => {
		if (eventEditState.date && eventEditState.room) {
			const selectedDay = DateTime.fromISO(eventEditState.date);
			const alreadyOccupiedTimeSlots = events
				?.filter(
					(event) =>
						event.from !== undefined && event.to !== undefined
				)
				.map((event) => {
					return {
						start: DateTime.fromISO(event.from!),
						end: DateTime.fromISO(event.to!),
					};
				});
			const intervalLength = Duration.fromObject({ hours: 1 }).toMillis();

			const period = {
				startTime:
					selectedDay.startOf("day") > DateTime.now().startOf("day")
						? selectedDay.startOf("day").plus({ hours: 5 })
						: DateTime.now().plus({ hour: 1 }).startOf("hour"),
				endTime: selectedDay?.endOf("day").plus({ hours: 3 }),
			};

			const slots = [];
			const slotCount = Math.round(
				(period.endTime.toMillis() - period.startTime.toMillis()) /
					intervalLength
			);
			for (let i = 0; i < slotCount; i++) {
				slots[i] = {
					startTime: period.startTime.plus(i * intervalLength),
					endTime: period.startTime.plus((i + 1) * intervalLength),
				};
			}

			const timeSlots = slots.map((x) => ({
				start: x.startTime,
				end: x.endTime,
			}));
			// return timeSlots;
			return timeSlots?.filter(
				(timeSlot) =>
					!alreadyOccupiedTimeSlots?.some(
						(occupiedTimeSlot) =>
							timeSlot.start.toMillis() >=
								occupiedTimeSlot.start.toMillis() &&
							timeSlot.end.toMillis() <=
								occupiedTimeSlot.end.toMillis()
					)
			);
		}
		return [];
	}, [eventEditState.date, eventEditState.room, events]);

	return (
		<Fragment>
			{loading ? (
				<Skeleton
					width={"100%"}
					height={48}
					variant="rect"
					css={{ borderRadius: 8 }}
				/>
			) : (
				<Fragment>
					{events?.map((event) => {
						return (
							<AlreadyCreatedEvent key={event.id} event={event} />
						);
					})}
					{availableTimeSlots?.map((slot) => {
						return (
							<SlotSelector
								key={`time_slots_${slot.start.toISO()}_${slot.end.toISO()}`}
								slot={slot}
								selectedStart={
									eventEditState.time.start
										? DateTime.fromISO(
												eventEditState.time.start
										  )
										: undefined
								}
								selectedEnd={
									eventEditState.time.end
										? DateTime.fromISO(
												eventEditState.time.end
										  )
										: undefined
								}
							/>
						);
					})}
				</Fragment>
			)}
		</Fragment>
	);
};
export default TimeSlotsPicker;
