import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function PriceIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "24"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 24"
		>
			<path
				fill={color || "#EF7705"}
				d="M5.25 12a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0zM12 4a8 8 0 100 16 8 8 0 000-16zm-.402 6.573c0 .321.352.542 1.216.854 1.196.422 1.668.975 1.668 1.89 0 .894-.623 1.657-1.779 1.848v.538a.497.497 0 01-.994 0v-.457a4.56 4.56 0 01-1.357-.273c-.213-.08-.313-.314-.256-.534l.046-.178c.08-.316.44-.47.748-.365.323.109.69.184 1.08.184.583 0 .975-.231.975-.623 0-.382-.322-.623-1.076-.874-1.075-.362-1.809-.865-1.809-1.84 0-.894.623-1.587 1.699-1.788v-.463a.492.492 0 01.984 0v.392c.441.014.788.085 1.075.178.232.075.347.326.284.562l-.038.143c-.08.304-.414.45-.715.36a3.077 3.077 0 00-.887-.127c-.653 0-.864.291-.864.573z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default PriceIcon;
