import LocalizedStrings from "react-localization";

export const localization = new LocalizedStrings({
  it: {
    appConfirm: "Conferma",
    appCancel: "Annulla",
    appClose: "Chiudi",
    appSend: "Invia",
    appSave: "Salva",
    appYes: "Sì",
    appNo: "No",
    appOptional: "opzionale",

    // Layout sections
    builderSignupLoginButton: "Accedi",
    builderSignupPlaceholderText: "Scrivi qui la tua risposta",
    builderSignupPlaceholderSelect: "Scegli la risposta",
  },
});
