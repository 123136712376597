import { BelDrawerParams } from "../components/BelDrawer";
import { useDrawerDispatch } from "./useDrawerDispatch";

export const useDrawerDispatchHelper = () => {
  const dispatch = useDrawerDispatch();

  function drawerDispatchHelper(payload: BelDrawerParams) {
    const defaultPayload: BelDrawerParams = {
      open: false,
      editMode: false,
      buildingSelected: false,
      editModelName: undefined,
      routes: [],
    };
    dispatch({ type: "DRAWER", payload: { ...defaultPayload, ...payload } });
  }

  return drawerDispatchHelper;
};
