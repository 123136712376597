import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const MotionIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize || "26"}
      height={iconSize || "28"}
      fill="none"
      fillOpacity={opacity || 100}
      viewBox="0 0 24 24"
    >
      <path
        fill={color || "#EF7705"}
        fillRule="evenodd"
        d="M13.849 2a2.272 2.272 0 000 4.544 2.274 2.274 0 002.268-2.272A2.274 2.274 0 0013.85 2zm3.972 9.975l.225.51a1.27 1.27 0 001.585.815c.648-.208 1.044-.9.83-1.594l-.886-2.81a1.254 1.254 0 00-.736-.796c-2.16-.862-2.737-1.084-2.906-1.144-.421-.219-.908-.297-1.382-.219a2.223 2.223 0 00-1.826 2.566l.019.11-2.073 1.69a1.271 1.271 0 00-.18 1.784c.443.554 1.255.62 1.784.185l.952-.778.375 2.24-1.18 1.26a1.258 1.258 0 00-.343.865v4.072a1.269 1.269 0 002.537 0V17.41l.465-.684c.372.144.805.178 1.158.116l.06-.01 1.588 4.107c.243.63.99.972 1.641.725.65-.253.974-.99.724-1.644L18.1 14.447l-.003-.017-.003-.015a.261.261 0 00-.006-.021l-.139-1.26-.02-.188-.013-.117-.094-.854zM2.52 13.03v-.625c4.087 0 7.412-3.33 7.412-7.422h.624c0 4.437-3.605 8.047-8.036 8.047zm0-4.32v.625a4.356 4.356 0 004.347-4.353h-.624A3.73 3.73 0 012.52 8.71zm6.192-3.728c0 3.419-2.778 6.2-6.192 6.2v-.625c3.068 0 5.567-2.5 5.567-5.575h.625zm-5.19.24a.937.937 0 10.001 1.876.937.937 0 00-.001-1.875z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default MotionIcon;
