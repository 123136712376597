import { localization } from "./localization";

export const hexWithOpacity = (hex: string, opacity: number) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? "rgba(" +
        parseInt(result[1], 16) +
        "," +
        parseInt(result[2], 16) +
        "," +
        parseInt(result[3], 16) +
        "," +
        opacity +
        ")"
    : hex;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const R = {
  colors: {
    background: "#EEF2F5",
    backgroundLight: "#f8f9fb",
    primary: "#1A59E7",
    border: hexWithOpacity("#094074", 0.12),
    secondary: "#00DA82",
    premium: "#FF9400",
    deepBlue: "#2C3346",
    premiumBackground: "#ffebd0",
    success: "#07B338",
  },
  dimens: {
    appbarHeight: {
      default: 72,
      mobile: 56,
      mobileExtended: 112,
    },
    drawerWidth: 266,
    ellipseMask: 388,
    sideWidth: {
      large: 456,
      strict: 380,
    },
  },
  strings: localization,
};

export default R;
