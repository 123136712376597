import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const DropIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize || "18"}
      height={iconSize || "24"}
      fill="none"
      fillOpacity={opacity || 100}
      viewBox="0 0 18 24"
    >
      <path
        fill={color || "#EF7705"}
        d="M9.465.193c.217.126.396.308.52.526l6.097 10.634A8.45 8.45 0 018.765 24 8.45 8.45 0 011.45 11.353L7.518.719A1.418 1.418 0 019.465.193zM7.888 22.19a6.748 6.748 0 004.224-.799 6.749 6.749 0 002.495-9.188L8.765 1.995 2.924 12.203a6.75 6.75 0 004.964 9.987zm4.841-7.242a.85.85 0 011.453.601 5.218 5.218 0 01-5.218 5.19.85.85 0 010-1.702 3.516 3.516 0 003.516-3.487.85.85 0 01.25-.602z"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default DropIcon;
