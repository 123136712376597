import { useHistory } from "react-router-dom";
import { PlusIcon } from "../components/BelVillageIcons";
import { HeaderParams } from "../components/Header";
import { useHeaderDispatch } from "./useHeaderDispatch";

export const useHeaderDispatchHelper = () => {
  const history = useHistory();
  const dispatch = useHeaderDispatch();

  function headerDispatchHelper(payload: HeaderParams) {
    const defaultPayload: HeaderParams = {
      actionText: "ADD NEW",
      actionVisible: true,
      actionIcon: PlusIcon,
      actionOnClick: () => {
        history.push(history.location.pathname + "/new");
      },
      customActionComponent: undefined,
    };
    dispatch({ type: "HEADER", payload: { ...defaultPayload, ...payload } });
  }

  return headerDispatchHelper;
};
