import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function FaceIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "24"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 24"
		>
			<path
				fill={color || "#EF7705"}
				d="M12 3c4.971 0 9 4.049 9 9 0 4.971-4.049 9-9 9s-9-4.029-9-9a9 9 0 019-9zm8.118 9c0-4.47-3.648-8.118-8.118-8.118-4.47 0-8.118 3.648-8.118 8.118 0 4.47 3.648 8.118 8.118 8.118 4.47 0 8.118-3.648 8.118-8.118zM16.33 9.775v1.443c0 .3-.24.562-.562.562a.548.548 0 01-.56-.562V9.775a.56.56 0 111.122 0zm-.962 6.515c.18-.16.18-.441 0-.622-.18-.16-.462-.16-.622 0a3.749 3.749 0 01-2.666 1.123h-.16c-1.002 0-1.945-.401-2.666-1.123-.16-.18-.441-.18-.622 0-.18.16-.18.441 0 .622.902.902 2.065 1.383 3.288 1.383h.16a4.663 4.663 0 003.287-1.383zM12.62 9.775v3.047c0 .902-.721 1.623-1.623 1.623h-.1c-.24 0-.441-.2-.441-.44 0-.241.2-.442.44-.442h.1c.402 0 .742-.34.742-.741V9.775c0-.24.2-.44.441-.44s.441.2.441.44zm-4.129 1.443V9.775c0-.3-.24-.541-.561-.561a.56.56 0 00-.561.561v1.443a.56.56 0 101.122 0z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default FaceIcon;
