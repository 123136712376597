/**@jsxImportSource @emotion/react */

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  TextField,
  Typography,
  Grid,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { TicketCategory } from "belvillage-api-manager";
import { useEffect, useState } from "react";

function TicketCategoryDialog(
  props: DialogProps & {
    isNew: boolean;
    initialValue?: TicketCategory;
    onSave?: (data: TicketCategory) => void;
    onDelete?: (id: string) => void;
  }
) {
  const { isNew, initialValue, ...dialogProps } = props;

  const [ticketCategory, setTicketCategory] = useState(initialValue);

  useEffect(() => {
    setTicketCategory(initialValue);
  }, [initialValue]);

  return (
    <Dialog {...dialogProps} fullWidth>
      <DialogContent>
        <DialogTitle>
          <Typography
            children={`${isNew ? "New" : "Edit"} ticket category`}
            variant="h6"
          />
        </DialogTitle>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="name"
              variant="outlined"
              fullWidth
              value={ticketCategory?.name || ""}
              onChange={(e) =>
                setTicketCategory((curr) => {
                  return { ...curr, name: e.target.value };
                })
              }
            />
          </Grid>
          <Grid item>
            <TextField
              label="Connected Email"
              variant="outlined"
              fullWidth
              value={ticketCategory?.linkedEmail || ""}
              onChange={(e) => {
                setTicketCategory((curr) => {
                  return { ...curr, linkedEmail: e.target.value };
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item css={{ flex: 1 }}>
            <Button
              children={isNew ? "Cancel" : "Delete"}
              variant="outlined"
              onClick={() => {
                if (isNew) {
                  props.onClose?.({}, "escapeKeyDown");
                } else {
                  props.onDelete?.(initialValue?.id || "");
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              children="Confirm"
              variant="contained"
              color="primary"
              onClick={() => {
                props.onSave?.(ticketCategory!);
              }}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default TicketCategoryDialog;
