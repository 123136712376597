import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const ThermometerIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    opacity,
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize || "24"}
      height={iconSize || "25"}
      fill="none"
      fillOpacity={opacity || 100}
      viewBox="0 0 24 25"
    >
      <path
        fill={color || "#EF7705"}
        fillRule="evenodd"
        d="M10.953 2.06a2.49 2.49 0 00-2.49 2.49v7.71a5.21 5.21 0 104.979.023V4.55a2.49 2.49 0 00-2.489-2.49zm0 1.14c.676 0 1.224.548 1.224 1.223v8.726a3.905 3.905 0 01-1.245 7.604 3.903 3.903 0 01-1.202-7.618V4.423c0-.675.547-1.223 1.223-1.223zm6.692 3.012h-2.76c-.354 0-.615-.286-.632-.632-.017-.342.304-.633.633-.633h2.76c.354 0 .615.29.632.633.017.341-.304.632-.633.632zm-1.067 1.806h-1.633a.643.643 0 01-.633-.633c-.017-.341.304-.632.633-.632h1.633c.354 0 .616.29.633.632.017.342-.304.633-.633.633zM13.19 16.85a2.257 2.257 0 11-4.515 0 2.257 2.257 0 014.514 0zm-1.266 0a.991.991 0 11-1.983 0 .991.991 0 011.983 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default ThermometerIcon;
