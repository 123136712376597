import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const NotVisibleIcon = (props: IIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted,
    ...others
  } = props;

  const iconSize =
    highlighted && !props.HighlightProps?.forceRegularSize
      ? calculateHighlightedSize(size)
      : size;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      fill="none"
      viewBox="0 0 24 24"
      {...others}
    >
      <path
        fill={color}
        d="M3.078 19.603l2.556-2.51a12.74 12.74 0 01-3.58-4.804.804.804 0 010-.575c.112-.29 2.784-7.077 9.946-7.077a9.83 9.83 0 014.983 1.313l2.76-2.71a.842.842 0 011.18 0 .811.811 0 010 1.157l-5.977 5.869-4.714 4.626-5.975 5.868a.842.842 0 01-1.179 0 .811.811 0 010-1.157zm8.497-10.016c-.508.084-.977.32-1.343.677a2.44 2.44 0 00-.69 1.319c-.086.499-.012 1.012.21 1.468l3.319-3.258a2.541 2.541 0 00-1.496-.206zm4.175-2.424a8.195 8.195 0 00-3.75-.89c-5.247 0-7.708 4.526-8.26 5.727a11.377 11.377 0 003.083 3.927l1.702-1.677a4.033 4.033 0 01.552-5.12 4.226 4.226 0 015.215-.536l1.458-1.431zm3.6.975c.22.02.424.125.565.292a12.744 12.744 0 012.032 3.28.804.804 0 010 .577c-.113.29-2.786 7.077-9.947 7.077-.82 0-1.635-.097-2.43-.29a.84.84 0 01-.53-.368.81.81 0 01.289-1.14.844.844 0 01.644-.08c.663.16 1.343.242 2.026.242 5.248 0 7.708-4.525 8.26-5.727a11.777 11.777 0 00-1.625-2.524.809.809 0 01.108-1.153.844.844 0 01.609-.186z"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default NotVisibleIcon;
