/**@jsxImportSource @emotion/react */

import {
  Grow,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Tutorial } from "belvillage-api-manager";
import AdminTable, {
  IHeadCell,
  parseCountFrom,
} from "../../../../components/admin/AdminTable";
import NotVisibleIcon from "../../../../components/icons/not_visible";
import VisibleIcon from "../../../../components/icons/visible";
import { backendMediaUrlParser } from "../../../../utils";
import { AdminTableBaseProps } from "../List";

const TutorialAdminTable = (
  props: AdminTableBaseProps<Tutorial[]> & {
    // loading: boolean;
    // filterState: [
    //   IQueryFilter,
    //   React.Dispatch<React.SetStateAction<IQueryFilter>>
    // ];
    // rawData: AxiosResponse<Tutorial[]>;
    // data?: Tutorial[];
    visibleTutorialIds: string[];
    onRemoveVisibleTutorial: (tutorialId: string) => void;
    onAddVisibleTutorial: (tutorialId: string) => void;
  }
) => {
  const {
    filterState,
    loading,
    rawData,
    visibleTutorialIds,
    data: tutorials,
    onRemoveVisibleTutorial,
    onAddVisibleTutorial,
  } = props;

  const [filter, setFilter] = filterState;

  return (
    <AdminTable
      headCells={TutorialHeadCells}
      fullWidth
      occlusionHeight={60}
      loading={loading}
      queryFilter={[filter, setFilter]}
      count={parseCountFrom(rawData)}
      data={tutorials}
    >
      {(data) =>
        data?.map((tutorial, i) => {
          return (
            <Grow
              key={tutorial?.id}
              appear={true}
              in={true}
              timeout={i * 300 + 300}
            >
              <TableRow
                key={tutorial?.id}
                css={{
                  opacity: visibleTutorialIds.includes(tutorial.id)
                    ? 1
                    : "0.6 !important",
                }}
              >
                <TableCell>
                  <div
                    css={{
                      backgroundImage: `url("${backendMediaUrlParser(
                        tutorial.thumbnail
                      )}")`,
                      width: 58,
                      height: 58,
                      borderRadius: 10,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 50%",
                    }}
                  />
                </TableCell>

                <TableCell>
                  <Typography variant="body1" color="textPrimary">
                    {tutorial.name}
                  </Typography>
                </TableCell>
                <TableCell>{tutorial.description}</TableCell>
                <TableCell css={{ textAlign: "right", paddingRight: 24 }}>
                  <IconButton
                    css={{
                      backgroundColor: "rgba(255,255, 255, 0.12)",
                      padding: 24,
                      transition: "150ms",
                    }}
                    onClick={() => {
                      if (visibleTutorialIds.includes(tutorial.id)) {
                        onRemoveVisibleTutorial(tutorial.id);
                      } else {
                        onAddVisibleTutorial(tutorial.id);
                      }
                    }}
                  >
                    <VisibleIcon
                      color="white"
                      css={{
                        position: "absolute",
                        opacity: visibleTutorialIds.includes(tutorial.id)
                          ? 1
                          : 0,
                        transition: "250ms",
                      }}
                    />
                    <NotVisibleIcon
                      color="white"
                      css={{
                        position: "absolute",
                        opacity: visibleTutorialIds.includes(tutorial.id)
                          ? 0
                          : 1,
                        transition: "250ms",
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </Grow>
          );
        })
      }
    </AdminTable>
  );
};

const TutorialHeadCells: IHeadCell[] = [
  { id: "thumbnail", label: "" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  { id: "visible", label: "", notSelectable: true },
];

export default TutorialAdminTable;
