"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminTicketApi = exports.AdminTicketApiFactory = exports.AdminTicketApiFp = exports.AdminTicketApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminTicketApi - axios parameter creator
 * @export
 */
exports.AdminTicketApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create ticket category
         * @param {string} id
         * @param {CreateEditTicketCategory} [createEditTicketCategory]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCategory: (id, createEditTicketCategory, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('createTicketCategory', 'id', id);
            const localVarPath = `/admin/building/{id}/ticketCategory/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createEditTicketCategory, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit ticket category
         * @param {string} categoryId
         * @param {CreateEditTicketCategory} [createEditTicketCategory]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketCategory: (categoryId, createEditTicketCategory, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryId' is not null or undefined
            common_1.assertParamExists('editTicketCategory', 'categoryId', categoryId);
            const localVarPath = `/admin/ticketCategory/{categoryId}/`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createEditTicketCategory, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all ticket categories
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTicketCategories: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAllTicketCategories', 'id', id);
            const localVarPath = `/admin/building/{id}/ticketCategory/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove ticket category
         * @param {string} categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketCategory: (categoryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'categoryId' is not null or undefined
            common_1.assertParamExists('removeTicketCategory', 'categoryId', categoryId);
            const localVarPath = `/admin/ticketCategory/{categoryId}/`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminTicketApi - functional programming interface
 * @export
 */
exports.AdminTicketApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AdminTicketApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Create ticket category
         * @param {string} id
         * @param {CreateEditTicketCategory} [createEditTicketCategory]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCategory(id, createEditTicketCategory, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createTicketCategory(id, createEditTicketCategory, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit ticket category
         * @param {string} categoryId
         * @param {CreateEditTicketCategory} [createEditTicketCategory]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketCategory(categoryId, createEditTicketCategory, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTicketCategory(categoryId, createEditTicketCategory, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all ticket categories
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTicketCategories(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllTicketCategories(id, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove ticket category
         * @param {string} categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketCategory(categoryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeTicketCategory(categoryId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AdminTicketApi - factory interface
 * @export
 */
exports.AdminTicketApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AdminTicketApiFp(configuration);
    return {
        /**
         *
         * @summary Create ticket category
         * @param {string} id
         * @param {CreateEditTicketCategory} [createEditTicketCategory]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketCategory(id, createEditTicketCategory, options) {
            return localVarFp.createTicketCategory(id, createEditTicketCategory, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit ticket category
         * @param {string} categoryId
         * @param {CreateEditTicketCategory} [createEditTicketCategory]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTicketCategory(categoryId, createEditTicketCategory, options) {
            return localVarFp.editTicketCategory(categoryId, createEditTicketCategory, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all ticket categories
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTicketCategories(id, filter, options) {
            return localVarFp.getAllTicketCategories(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove ticket category
         * @param {string} categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketCategory(categoryId, options) {
            return localVarFp.removeTicketCategory(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminTicketApi - object-oriented interface
 * @export
 * @class AdminTicketApi
 * @extends {BaseAPI}
 */
class AdminTicketApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create ticket category
     * @param {string} id
     * @param {CreateEditTicketCategory} [createEditTicketCategory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTicketApi
     */
    createTicketCategory(id, createEditTicketCategory, options) {
        return exports.AdminTicketApiFp(this.configuration).createTicketCategory(id, createEditTicketCategory, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit ticket category
     * @param {string} categoryId
     * @param {CreateEditTicketCategory} [createEditTicketCategory]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTicketApi
     */
    editTicketCategory(categoryId, createEditTicketCategory, options) {
        return exports.AdminTicketApiFp(this.configuration).editTicketCategory(categoryId, createEditTicketCategory, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all ticket categories
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTicketApi
     */
    getAllTicketCategories(id, filter, options) {
        return exports.AdminTicketApiFp(this.configuration).getAllTicketCategories(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove ticket category
     * @param {string} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTicketApi
     */
    removeTicketCategory(categoryId, options) {
        return exports.AdminTicketApiFp(this.configuration).removeTicketCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminTicketApi = AdminTicketApi;
