/** @jsxImportSource @emotion/react */

import { Button, Collapse, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

export interface HeaderParams {
  actionVisible?: boolean;
  actionText?: string;
  actionIcon?: React.FC | null;
  actionOnClick?: () => void;
  customActionComponent?: React.ReactNode;
}

interface HeaderProps {
  header: HeaderParams;
  [x: string]: any;
}

function Header(props: HeaderProps) {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const currentLocation = location.pathname;
  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        style={{
          // padding: backButton ? 0 : "13.5px 20px",
          // display: props.header?.visible ? "flex" : "none",
          // display: "flex",

          height: 60,

          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10,
          background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #070707",
        }}
      >
        <Grid container alignItems="center" style={{ padding: "0px 24px" }}>
          <Grid item xs={isMobile ? 1 : 3}>
            {renderBelVillageLogo()}
          </Grid>

          <Grid item xs>
            <Grid container justifyContent="flex-end">
              <Collapse in={props?.header?.actionVisible}>
                <Button
                  startIcon={props?.header?.actionIcon ? props?.header?.actionIcon?.({}) : null}
                  variant="contained"
                  color="primary"
                  children={props?.header?.actionText || "ADD NEW"}
                  onClick={props?.header?.actionOnClick}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  function renderBelVillageLogo(): React.ReactNode {
    return (
      <img
        src={"/images/logo_belvillage.svg"}
        alt="BelVillage"
        width={161}
        height={24}
        css={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/buildings");
        }}
      />
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    header: state.updates.header,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateHistory: (name: any, values: any) => dispatch({ type: "NEW_STEP", payload: values }),
    restoreSection: () => dispatch({ type: "RESTORE_SECTION", payload: "" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
