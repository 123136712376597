/** @jsxImportSource @emotion/react */
import { Grid, IconButton, Typography } from "@material-ui/core";
import { Faq } from "belvillage-api-manager";
import { forwardRef } from "react";
import { CircledEditIcon } from "../../../../components/CircledEditIcon";
import ArrowDownIcon from "../../../../components/icons/arrow_down";

type SingleFAQProps = {
	FAQ: Faq;
	faqNumber: number;
	matchedPath: string;
	index: number;
	goUp: () => void;
	goDown: () => void;
};

const SingleFAQ = forwardRef<any, SingleFAQProps>(
	({ FAQ, matchedPath, index, goUp, goDown, faqNumber, ...props }, ref) => {
		return (
			<Grid
				container
				spacing={2}
				css={{
					textDecoration: "none",
					backgroundColor: "rgba(255, 255, 255, 0.08)",
					borderRadius: 12,
					marginBottom: 16,
					padding: 12,
					boxSizing: "border-box",
					width: "100%",
					marginLeft: "0px !important",
				}}
				ref={ref}
				{...props}
			>
				<Grid
					item
					xs={1}
					css={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<IconButton
						onClick={goUp}
						disabled={index === 0}
						size="small"
						css={{ transform: "rotate(180deg)" }}
					>
						<ArrowDownIcon
							color={
								index === 0
									? "rgba(255,255,255,0.12)"
									: "rgba(255,255,255,0.38)"
							}
						/>
					</IconButton>
					<IconButton
						disabled={index === faqNumber - 1}
						size="small"
						onClick={goDown}
					>
						<ArrowDownIcon
							color={
								index === faqNumber - 1
									? "rgba(255,255,255,0.12)"
									: "rgba(255,255,255,0.38)"
							}
						/>
					</IconButton>
				</Grid>
				<Grid item xs={5}>
					<div
						css={{
							height: "100%",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Typography
							children={`${index}. ${FAQ.question}`}
							variant="subtitle2"
							css={{ color: "white" }}
						/>
					</div>
				</Grid>
				<Grid item xs={5}>
					<div
						css={{
							height: "100%",
							width: "100%",
							display: "flex",
							alignItems: "center",
							position: "relative",
						}}
					>
						<Typography
							children={FAQ.answer}
							variant="body2"
							noWrap
							css={{
								color: "white",
								width: "100%",
								position: "absolute",
								left: 0,
								right: 0,
							}}
						/>
					</div>
				</Grid>
				<Grid
					component="a"
					href={matchedPath + "/" + FAQ.id}
					item
					xs={1}
					css={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<CircledEditIcon />
				</Grid>
			</Grid>
		);
	}
);
export default SingleFAQ;
