"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingApi = exports.BookingApiFactory = exports.BookingApiFp = exports.BookingApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * BookingApi - axios parameter creator
 * @export
 */
exports.BookingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary All building guests
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBuildingGuests: (buildingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'buildingId' is not null or undefined
            common_1.assertParamExists('allBuildingGuests', 'buildingId', buildingId);
            const localVarPath = `/admin/booking/{buildingId}/`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Execute checkin
         * @param {InputExecuteCheckin} [inputExecuteCheckin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCheckin: (inputExecuteCheckin, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/booking/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputExecuteCheckin, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * get the current user booking
         * @summary Get my booking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyBooking: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/booking/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove checkin
         * @param {InputRemoveCheckin} [inputRemoveCheckin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCheckin: (inputRemoveCheckin, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/booking/checkin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputRemoveCheckin, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update bookings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookings: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/booking/update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BookingApi - functional programming interface
 * @export
 */
exports.BookingApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.BookingApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary All building guests
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBuildingGuests(buildingId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allBuildingGuests(buildingId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Execute checkin
         * @param {InputExecuteCheckin} [inputExecuteCheckin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCheckin(inputExecuteCheckin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.executeCheckin(inputExecuteCheckin, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * get the current user booking
         * @summary Get my booking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyBooking(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMyBooking(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove checkin
         * @param {InputRemoveCheckin} [inputRemoveCheckin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCheckin(inputRemoveCheckin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeCheckin(inputRemoveCheckin, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update bookings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookings(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateBookings(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * BookingApi - factory interface
 * @export
 */
exports.BookingApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.BookingApiFp(configuration);
    return {
        /**
         *
         * @summary All building guests
         * @param {string} buildingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBuildingGuests(buildingId, options) {
            return localVarFp.allBuildingGuests(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Execute checkin
         * @param {InputExecuteCheckin} [inputExecuteCheckin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCheckin(inputExecuteCheckin, options) {
            return localVarFp.executeCheckin(inputExecuteCheckin, options).then((request) => request(axios, basePath));
        },
        /**
         * get the current user booking
         * @summary Get my booking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyBooking(options) {
            return localVarFp.getMyBooking(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove checkin
         * @param {InputRemoveCheckin} [inputRemoveCheckin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCheckin(inputRemoveCheckin, options) {
            return localVarFp.removeCheckin(inputRemoveCheckin, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update bookings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookings(options) {
            return localVarFp.updateBookings(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
class BookingApi extends base_1.BaseAPI {
    /**
     *
     * @summary All building guests
     * @param {string} buildingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    allBuildingGuests(buildingId, options) {
        return exports.BookingApiFp(this.configuration).allBuildingGuests(buildingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Execute checkin
     * @param {InputExecuteCheckin} [inputExecuteCheckin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    executeCheckin(inputExecuteCheckin, options) {
        return exports.BookingApiFp(this.configuration).executeCheckin(inputExecuteCheckin, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get the current user booking
     * @summary Get my booking
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    getMyBooking(options) {
        return exports.BookingApiFp(this.configuration).getMyBooking(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove checkin
     * @param {InputRemoveCheckin} [inputRemoveCheckin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    removeCheckin(inputRemoveCheckin, options) {
        return exports.BookingApiFp(this.configuration).removeCheckin(inputRemoveCheckin, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update bookings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    updateBookings(options) {
        return exports.BookingApiFp(this.configuration).updateBookings(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.BookingApi = BookingApi;
