import { useTheme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
//@ts-ignore
import MUIPlacesAutocomplete from "mui-places-autocomplete";
import React, { useEffect, useRef, useState } from "react";

const geocodeByPlaceID = require("mui-places-autocomplete").geocodeByPlaceID;

// Geocode.setApiKey('AIzaSyBJ9-rlrqRUbJkdq6ACuFSs2yCqctdV93c');
// Geocode.setLanguage('it');
// if (process.env.NODE_ENV === 'development') {
//   Geocode.enableDebug();
// }

export type AutocompleteType =
  | "geocode"
  | "address"
  | "establishment"
  | "locality"
  | "sublocality"
  | "postal_code"
  | "country"
  | "administrative_area_level_1"
  | "administrative_area_level_2"
  | "administrative_area_level_3";

export interface GeocodePlaceResult {
  place_id?: string;
  coordinates: { lat: number; lng: number };
  description: string;
  matched_substrings?: string[];
  reference?: string;
  structured_formatting?: {
    main_text: string;
    main_text_matched_substrings: { lengt: number; offset: number }[];
    secondary_text: string;
  };
  terms?: { offset: number; value: string }[];
  types?: string[];
}

export default function PlacesGeocodeAutocompleteInput(props: {
  readOnly?: boolean;
  place?: GeocodePlaceResult;
  editable?: boolean;
  gmapsReady: boolean;
  onChange?: (newValue: GeocodePlaceResult) => void;
  style?: CSSProperties;
  variant?: "standard" | "outlined" | "filled";
  label?: string;
  placeholder?: string;
  withRadius?: boolean;
  autocompleteTypes?: AutocompleteType[];
  restrictedTo?: string[];
  debounceMilliseconds?: number;
}) {
  const theme = useTheme();
  // const [suggestion, setSuggestion] = useState(undefined);
  const [suggestion, setSuggestion] = useState(props.place || undefined);
  const [text, setText] = useState(props.place?.description || "");
  const muiRef = useRef<any>();
  const iRef = useRef<any>();
  const debounce = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setSuggestion(props.place);
    if (props.place?.description !== text)
      setText(props.place?.description || "");
  }, [props.place]);

  const onSuggestion = (newSuggestion: any) => {
    geocodeByPlaceID(newSuggestion.place_id)
      .then((results: any) => {
        const { geometry } = results[0];

        const coordinates = {
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        };

        const newSuggestionValue: GeocodePlaceResult = {
          ...newSuggestion,
          coordinates,
        };

        setText(newSuggestionValue?.description);
        setSuggestion(newSuggestionValue);
        props.onChange?.(newSuggestionValue);
      })
      .catch((err: any) => {
        //console.log(91, err)
      });
  };

  // const onPredictions = (list) => {
  //   console.log(25, list);
  //   //todo wip handle changes
  // };

  const textFieldProps = {
    inputRef: iRef,
    disabled: props.readOnly,
    fullWidth: true,
    label: props.label,
    variant: props.variant,
    placeholder: props.placeholder,
    // defaultValue: suggestion?.description,
    value: text,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setText(inputValue);

      /*
      const target = e.target;
      console.log(99, ((e as any).preventPropagationHandlerFlag = true));

      e.persist();
      e.stopPropagation();
      e.preventDefault();

      if (debounce.current) clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        console.log("debounced", inputValue);
        muiRef.current?.onInputValueChange?.(inputValue);

        muiRef.current?.autocompleteService.getPlacePredictions(
          createAutocompleteRequest(inputValue),
          (predictions, serviceStatus) => {
            // If the response doesn't contain a valid result then set our state as if no suggestions
            // were returned
            if (serviceStatus !== (window as any).google.maps.places.PlacesServiceStatus.OK) {
              onPredictions([]);
              return;
            }

            onPredictions(predictions);
          }
        );
      }, props.debounceMilliseconds || 700);
      */
    },
    // onChange: (e) => setText(e.target.value),
    onBlur: () => setText(suggestion?.description || ""),
  };

  return props.gmapsReady ? (
    <MUIPlacesAutocomplete
      ref={muiRef}
      onSuggestionSelected={onSuggestion}
      renderTarget={() => <div />}
      textFieldProps={textFieldProps}
      createAutocompleteRequest={createAutocompleteRequest}
    />
  ) : null;
  // <TextField {...textFieldProps} />

  function createAutocompleteRequest(inputValue: any) {
    console.log(91, inputValue);
    return {
      input: inputValue,
      types:
        props.autocompleteTypes ||
        (!props.withRadius ? ["address"] : ["geocode", "establishment"]),
      componentRestrictions: { country: props.restrictedTo },
    };
  }
}

function triggerEvent(el: any, type: any) {
  if ("createEvent" in document) {
    // modern browsers, IE9+
    var e: any = document.createEvent("HTMLEvents");
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  } else {
    // IE 8
    e = (document as any).createEventObject();
    e.eventType = type;
    el.fireEvent("on" + e.eventType, e);
  }
}
