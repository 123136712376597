import { AuthenticationApi } from "belvillage-api-manager";
import { useEffect } from "react";
import { useApi } from "../../utils/api";

function LogoutPage(props: any) {
  const apiAuthentication = useApi(AuthenticationApi);

  useEffect(() => {
    apiAuthentication
      .logout()
      .then((resp) => {
        localStorage.clear();
        window.location.href = "/login";
      })
      .catch(console.log);
  }, []);

  return <div />;
}
export default LogoutPage;
