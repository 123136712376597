import { useTheme } from "@material-ui/core";
import * as React from "react";

export function IdentityIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 13v-.6a3 3 0 016 0v.6m1.8-8.4h3.6m-3.6 3.6h3.6M7 3.4A1.8 1.8 0 107 7a1.8 1.8 0 000-3.6zM2.8 1h14.4a1.2 1.2 0 011.2 1.2v9.6a1.2 1.2 0 01-1.2 1.2H2.8a1.2 1.2 0 01-1.2-1.2V2.2A1.2 1.2 0 012.8 1z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function QuestionMarkIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 9.579V8h.527c.872 0 1.579-.707 1.579-1.579v-.105c0-.814-.66-1.474-1.474-1.474H8c-.872 0-1.579.707-1.579 1.58m1.053 4.736h1.053M8 15.368A7.368 7.368 0 118 .632a7.368 7.368 0 010 14.736z"
        stroke={color}
      />
    </svg>
  );
}

export function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 15 15`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.727 7.5h9.546M7.5 11.875l5-4.375-5-4.375"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1l4 4m4 4L5 5m0 0l4-4M5 5L1 9"
        stroke={color}
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function BellIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 13.18V10a6 6 0 00-5-5.91V3a1 1 0 00-2 0v1.09A6 6 0 006 10v3.18A3 3 0 004 16v2a1 1 0 001 1h3.14a4 4 0 007.72 0H19a1 1 0 001-1v-2a3 3 0 00-2-2.82zM8 10a4 4 0 018 0v3H8v-3zm4 10a2 2 0 01-1.72-1h3.44A2 2 0 0112 20zm6-3H6v-1a1 1 0 011-1h10a1 1 0 011 1v1z"
        fill={color}
      />
    </svg>
  );
}

export function EuroIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0a11 11 0 100 22 11 11 0 000-22zm0 20a9 9 0 110-17.999A9 9 0 0111 20zm.59-13.33a3.34 3.34 0 012.62 1.38 1 1 0 001.659-.105 1 1 0 00-.079-1.115 5.32 5.32 0 00-4.2-2.16A5.57 5.57 0 006.46 8.5H5a1 1 0 000 2h1v1H5a1 1 0 000 2h1.46a5.57 5.57 0 005.13 3.83 5.32 5.32 0 004.2-2.16A1 1 0 1014.21 14a3.34 3.34 0 01-2.62 1.38 3.42 3.42 0 01-2.92-1.88H11a1 1 0 000-2H8.05A4.233 4.233 0 018 11c.007-.168.023-.335.05-.5H11a1 1 0 000-2H8.67a3.42 3.42 0 012.92-1.83z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowNext(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.667 18.667L11 10 1.667 1.333"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ShoppingBagIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.417 8.25a.458.458 0 00.458-.458v-.917a.458.458 0 10-.917 0v.917a.458.458 0 00.459.458zm-1.834 0a.458.458 0 00.459-.458v-.917a.458.458 0 10-.917 0v.917a.458.458 0 00.458.458zm4.125-5.5h-.632l-.793-1.581a.458.458 0 10-.816.412l.582 1.169H3.951l.582-1.169a.458.458 0 00-.816-.412L2.924 2.75h-.632a1.375 1.375 0 00-.422 2.677l.34 3.419a1.375 1.375 0 001.374 1.237h3.84A1.375 1.375 0 008.8 8.846l.34-3.42a1.375 1.375 0 00-.432-2.676zm-.83 6.004a.458.458 0 01-.458.413H3.58a.458.458 0 01-.459-.413L2.796 5.5h5.408L7.88 8.754zm.83-4.17H2.292a.458.458 0 010-.917h6.416a.458.458 0 010 .916z"
        fill={color}
      />
    </svg>
  );
}

export function ThinArrowRight(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13 5L9 1m4 4L9 9m4-4H.5" stroke={color} strokeWidth={1.3} />
    </svg>
  );
}

export function SearchIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.666 10.836L9.502 8.689a5.25 5.25 0 10-.811.81l2.147 2.147a.583.583 0 00.828 0 .583.583 0 000-.81zM5.418 9.5a4.083 4.083 0 110-8.167 4.083 4.083 0 010 8.167z"
        fill={color}
      />
    </svg>
  );
}

export function ReadIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.702.945a5.93 5.93 0 00-4.203.853A5.93 5.93 0 002.292.879c-.333.002-.666.03-.994.087a.458.458 0 00-.38.459v5.5a.458.458 0 00.535.458 4.996 4.996 0 013.782.876l.055.032h.05a.417.417 0 00.321 0h.05l.055-.032a4.996 4.996 0 013.782-.926.458.458 0 00.536-.459v-5.5a.459.459 0 00-.38-.43zm-4.66 6.09a5.899 5.899 0 00-2.75-.678h-.46V1.774c.154-.009.307-.009.46 0a4.978 4.978 0 012.75.825v4.437zm4.124-.66h-.458c-.958 0-1.902.233-2.75.68V2.598a4.978 4.978 0 012.75-.825c.153-.009.306-.009.458 0v4.602zm.536 1.903A5.93 5.93 0 005.5 9.13a5.93 5.93 0 00-4.202-.853.458.458 0 00-.38.527.458.458 0 00.535.362 4.996 4.996 0 013.782.875.458.458 0 00.531 0 4.996 4.996 0 013.782-.875.458.458 0 00.536-.362.46.46 0 00-.38-.527z"
        fill={color}
      />
    </svg>
  );
}

export function ShoppingCartIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 14.735H5c-.265 0-.52-.104-.707-.289a.977.977 0 010-1.392c.187-.184.442-.288.707-.288h10.44a3.03 3.03 0 001.845-.625 2.95 2.95 0 001.065-1.61L20 4.143a.969.969 0 00-.545-1.13A1.014 1.014 0 0019 2.922H4.76a2.958 2.958 0 00-1.093-1.425A3.033 3.033 0 001.94.953H1c-.265 0-.52.104-.707.288a.977.977 0 000 1.393c.187.184.442.288.707.288h.94a1.01 1.01 0 01.634.2c.182.136.311.33.366.548l.06.473 1.73 6.654a3.023 3.023 0 00-2.082.96 2.929 2.929 0 00-.783 2.126c.036.784.386 1.52.974 2.05a3.031 3.031 0 002.161.77h.18a2.91 2.91 0 00.363 2.703c.277.389.645.706 1.072.925a3.037 3.037 0 002.77 0 2.986 2.986 0 001.072-.925 2.91 2.91 0 00.363-2.702h2.36a2.91 2.91 0 00.363 2.702c.277.389.645.706 1.072.925a3.037 3.037 0 002.77 0 2.986 2.986 0 001.072-.925 2.91 2.91 0 00.363-2.702H19c.265 0 .52-.104.707-.289a.977.977 0 000-1.392 1.008 1.008 0 00-.707-.288zM17.72 4.89l-1.31 5.158a.983.983 0 01-.366.548 1.01 1.01 0 01-.634.2H6.78l-1.5-5.906h12.44zM8 18.672c-.198 0-.391-.057-.556-.166a.989.989 0 01-.368-.441.97.97 0 01.217-1.073 1.005 1.005 0 011.09-.213c.182.074.339.2.448.362a.973.973 0 01-.124 1.243 1.008 1.008 0 01-.707.288zm8 0c-.198 0-.391-.057-.556-.166a.989.989 0 01-.368-.441.97.97 0 01.217-1.073 1.005 1.005 0 011.09-.213c.182.074.339.2.448.362a.973.973 0 01-.124 1.243 1.008 1.008 0 01-.707.288z"
        fill={color}
      />
    </svg>
  );
}

export function DeleteBinIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.63 1.813a.25.25 0 00-.238.17l-.25.753h4.38l-.25-.753a.25.25 0 00-.238-.17H6.63zm5.473.923l-.409-1.227a1.75 1.75 0 00-1.66-1.196H6.63a1.75 1.75 0 00-1.66 1.196L4.56 2.736H1.063a.75.75 0 000 1.5h.913l.575 8.615a3.75 3.75 0 003.741 3.5h4.08a3.75 3.75 0 003.741-3.5l.574-8.615h.914a.75.75 0 000-1.5h-3.498zm1.081 1.5H3.48l.567 8.515a2.25 2.25 0 002.245 2.1h4.08a2.25 2.25 0 002.244-2.1l.568-8.515zm-6.468 1.73a.75.75 0 01.75.75v5.654a.75.75 0 01-1.5 0V6.716a.75.75 0 01.75-.75zm3.981.75a.75.75 0 00-1.5 0v5.654a.75.75 0 001.5 0V6.716z"
        fill={color}
      />
    </svg>
  );
}

export function MinusIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.379 9H5.404m3.653 7.466c-4.109 0-7.44-3.342-7.44-7.466 0-4.124 3.331-7.467 7.44-7.467 4.11 0 7.44 3.343 7.44 7.467s-3.33 7.466-7.44 7.466z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

// export function PlusIcon(props: React.SVGProps<SVGSVGElement>) {
//   const theme = useTheme();
//   const width = props.width || 24;
//   const height = props.height || 24;

//   const color = props.color || theme.palette.text.primary;
//   return (
//     <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
//       <path
//         d="M9.03 12.333V9m0-3.333V9m0 0h3.321M9.03 9H5.708m3.322 7.466c-4.11 0-7.44-3.342-7.44-7.466 0-4.124 3.33-7.467 7.44-7.467 4.109 0 7.44 3.343 7.44 7.467s-3.331 7.466-7.44 7.466z"
//         stroke={color}
//         strokeWidth={1.5}
//         strokeLinecap="round"
//       />
//     </svg>
//   );
// }

export function AlertIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00078 2.89999C6.00078 2.62385 5.77692 2.39999 5.50078 2.39999C5.22464 2.39999 5.00078 2.62385 5.00078 2.89999H6.00078ZM5.00078 5.29999C5.00078 5.57613 5.22464 5.79999 5.50078 5.79999C5.77692 5.79999 6.00078 5.57613 6.00078 5.29999H5.00078ZM6.00078 6.79999C6.00078 6.52385 5.77692 6.29999 5.50078 6.29999C5.22464 6.29999 5.00078 6.52385 5.00078 6.79999H6.00078ZM5.00078 6.80599C5.00078 7.08213 5.22464 7.30599 5.50078 7.30599C5.77692 7.30599 6.00078 7.08213 6.00078 6.80599H5.00078ZM5.00078 2.89999V5.29999H6.00078V2.89999H5.00078ZM5.00078 6.79999V6.80599H6.00078V6.79999H5.00078ZM5.50078 8.69999C3.45733 8.69999 1.80078 7.04344 1.80078 4.99999H0.800781C0.800781 7.59573 2.90504 9.69999 5.50078 9.69999V8.69999ZM9.20078 4.99999C9.20078 7.04344 7.54423 8.69999 5.50078 8.69999V9.69999C8.09652 9.69999 10.2008 7.59573 10.2008 4.99999H9.20078ZM5.50078 1.29999C7.54423 1.29999 9.20078 2.95653 9.20078 4.99999H10.2008C10.2008 2.40425 8.09652 0.299988 5.50078 0.299988V1.29999ZM5.50078 0.299988C2.90504 0.299988 0.800781 2.40425 0.800781 4.99999H1.80078C1.80078 2.95653 3.45733 1.29999 5.50078 1.29999V0.299988Z"
        fill={color}
      />
    </svg>
  );
}

export function DocumentLoadIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.802 9h-3.75a.75.75 0 000 1.5h3.75a.75.75 0 100-1.5zm.75 6h-6a.75.75 0 01-.75-.75V3.75a.75.75 0 01.75-.75h3.75v2.25a2.25 2.25 0 002.25 2.25h2.25v.75a.75.75 0 001.5 0v-1.5a.643.643 0 00-.053-.247v-.068a.8.8 0 00-.142-.21l-4.5-4.5a.75.75 0 00-.217-.143h-.06a.66.66 0 00-.24-.082h-4.5a2.25 2.25 0 00-2.25 2.25v10.5a2.25 2.25 0 002.25 2.25h6a.75.75 0 100-1.5h-.038zm-.75-10.942L11.737 6h-1.185a.75.75 0 01-.75-.75V4.058zm4.178 11.16a.809.809 0 00-.382-.202.75.75 0 10.382.201zM9.802 12h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5zm3.645-2.25a2.25 2.25 0 00-1.95 1.125.753.753 0 001.305.75.75.75 0 11.645 1.125.75.75 0 000 1.5 2.25 2.25 0 100-4.5zM6.052 7.5h.75a.75.75 0 100-1.5h-.75a.75.75 0 000 1.5z"
        fill={color}
      />
    </svg>
  );
}

export function DocumentSentIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.625 15h-4.5a.75.75 0 01-.75-.75V3.75a.75.75 0 01.75-.75h3.75v2.25a2.25 2.25 0 002.25 2.25h2.25v3.75a.75.75 0 101.5 0v-4.5-.045a.982.982 0 00-.045-.202v-.068a.804.804 0 00-.143-.21l-4.5-4.5a.802.802 0 00-.21-.143.218.218 0 00-.075 0A.825.825 0 008.67 1.5H4.125a2.25 2.25 0 00-2.25 2.25v10.5a2.25 2.25 0 002.25 2.25h4.5a.75.75 0 100-1.5zm.75-10.942L11.318 6h-1.193a.75.75 0 01-.75-.75V4.058zM5.625 10.5h4.5a.75.75 0 100-1.5h-4.5a.75.75 0 000 1.5zm3 1.5h-3a.75.75 0 100 1.5h3a.75.75 0 100-1.5zm-3-4.5h.75a.75.75 0 000-1.5h-.75a.75.75 0 000 1.5zm10.283 4.717a.75.75 0 00-1.066 0l-2.467 2.476-.967-.976a.752.752 0 10-1.066 1.066l1.5 1.5a.75.75 0 001.066 0l3-3a.75.75 0 000-1.066z"
        fill={color}
      />
    </svg>
  );
}

export function DocumentToSignIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.533 12.533l-1.816-1.816a.75.75 0 00-1.065 0l-2.684 2.685a.75.75 0 00-.218.533v1.815a.75.75 0 00.75.75h1.815a.748.748 0 00.533-.218l2.684-2.684a.75.75 0 000-1.066zM12 15h-.75v-.75l1.935-1.935.75.75L12 15zm-4.5 0h-3a.75.75 0 01-.75-.75V3.75A.75.75 0 014.5 3h3.75v2.25A2.25 2.25 0 0010.5 7.5h2.25v.75a.75.75 0 101.5 0v-1.5-.045a.982.982 0 00-.045-.202v-.068a.804.804 0 00-.143-.21l-4.5-4.5a.802.802 0 00-.21-.143.24.24 0 00-.067 0l-.24-.082H4.5a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 16.5h3a.75.75 0 100-1.5zM9.75 4.058L11.693 6H10.5a.75.75 0 01-.75-.75V4.058zM6 10.5h4.5a.75.75 0 100-1.5H6a.75.75 0 000 1.5zm0-3h.75a.75.75 0 000-1.5H6a.75.75 0 000 1.5zM7.5 12H6a.75.75 0 100 1.5h1.5a.75.75 0 100-1.5z"
        fill={color}
      />
    </svg>
  );
}

export function CafMessageIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.857 14.794l.283-.855-.393-.13-.354.214.464.77zm1.929-1.93l-.758-.485-.232.363.136.407.854-.285zm.964 2.886l-.282.855a.9.9 0 001.136-1.14l-.854.285zm-3.357-1.727c-.84.506-2.235.827-3.393.827v1.8c1.39 0 3.13-.367 4.322-1.085l-.93-1.542zM9 14.85A5.85 5.85 0 013.15 9h-1.8A7.65 7.65 0 009 16.65v-1.8zM3.15 9A5.85 5.85 0 019 3.15v-1.8A7.65 7.65 0 001.35 9h1.8zM9 3.15A5.85 5.85 0 0114.85 9h1.8A7.65 7.65 0 009 1.35v1.8zM14.85 9c0 1.238-.277 2.527-.822 3.38l1.516.969c.795-1.244 1.106-2.912 1.106-4.349h-1.8zm-2.275 6.649l2.893.956.564-1.71-2.892-.956-.565 1.71zm4.029-.184l-.965-2.886-1.707.57.964 2.886 1.708-.57z"
        fill={color}
      />
    </svg>
  );
}

export function DropDownIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 7.5l5 5 5-5"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SearchCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.167 9.2l1.633 1.6m-3.267-.933a2.333 2.333 0 110-4.667 2.333 2.333 0 010 4.667zM8 14.533A6.533 6.533 0 118 1.467a6.533 6.533 0 010 13.066z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function VerifiedCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.733 8l2.8 2.333 3.734-4.666M8 14.533A6.533 6.533 0 118 1.467a6.533 6.533 0 010 13.066z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function DeniedCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.333 10.041L8 8M5.958 5.958L8 8m0 0l2.333-2.042M8 8L5.958 10.04M8 14.534A6.533 6.533 0 118 1.467a6.533 6.533 0 010 13.066z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function DownloadCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.958 8.292L8 10.333m0 0l2.042-2.041M8 10.333V5.667m0 8.866A6.533 6.533 0 118 1.467a6.533 6.533 0 010 13.066z"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function InfoUploadIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.357 10.8a.5.5 0 101 0h-1zm.5-3.267h.5v-.5h-.5v.5zm-.5-2.333a.5.5 0 001 0h-1zm1-.01a.5.5 0 00-1 0h1zM6.486 7.034a.5.5 0 000 1v-1zm0 3.267a.5.5 0 000 1v-1zm2.742 1a.5.5 0 100-1v1zm-.871-.5V7.533h-1V10.8h1zm0-5.6v-.01h-1v.01h1zM6.486 8.033h1.371v-1H6.486v1zm0 3.267h2.742v-1H6.486v1zm1.371-9.333c3.249 0 5.9 2.691 5.9 6.033h1c0-3.875-3.08-7.033-6.9-7.033v1zM1.957 8c0-3.342 2.651-6.033 5.9-6.033v-1C4.037.967.957 4.125.957 8h1zm5.9 6.034c-3.249 0-5.9-2.692-5.9-6.034h-1c0 3.875 3.08 7.034 6.9 7.034v-1zm0 1c3.82 0 6.9-3.16 6.9-7.034h-1c0 3.342-2.651 6.034-5.9 6.034v1z"
        fill={color}
      />
    </svg>
  );
}

export function ImagePickerIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 0H3a3 3 0 00-3 3v14a3 3 0 003 3h14c.165-.002.328-.019.49-.05l.3-.07H17.91l.37-.14.13-.07c.1-.06.21-.11.31-.18a3.78 3.78 0 00.38-.32l.07-.09c.098-.1.188-.206.27-.32l.09-.13c.07-.111.13-.228.18-.35.027-.048.05-.098.07-.15.05-.12.08-.25.12-.38v-.15c.057-.195.09-.397.1-.6V3a3 3 0 00-3-3zM3 18a1 1 0 01-1-1v-4.31l3.29-3.3a1 1 0 011.42 0l8.6 8.61H3zm15-1a1 1 0 01-.07.36c-.023.049-.05.096-.08.14a.948.948 0 01-.09.12l-5.35-5.35.88-.88a1.002 1.002 0 011.42 0l3.29 3.3V17zm0-5.14L16.12 10a3.08 3.08 0 00-4.24 0l-.88.88L8.12 8a3.08 3.08 0 00-4.24 0L2 9.86V3a1 1 0 011-1h14a1 1 0 011 1v8.86zM11.5 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill={color}
      />
    </svg>
  );
}

export function UploadIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.532 5.783L8.25 4.058v7.192a.75.75 0 101.5 0V4.058l1.717 1.725a.75.75 0 001.066 0 .75.75 0 000-1.065l-3-3a.75.75 0 00-.248-.158.75.75 0 00-.57 0 .75.75 0 00-.248.158l-3 3a.753.753 0 101.066 1.065zM15.75 10.5a.75.75 0 00-.75.75v3a.75.75 0 01-.75.75H3.75a.75.75 0 01-.75-.75v-3a.75.75 0 00-1.5 0v3a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25v-3a.75.75 0 00-.75-.75z"
        fill={color}
      />
    </svg>
  );
}

export function PencilCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 9.5l-.53-.53-.22.22v.31h.75zm4-4l.53-.53a.75.75 0 00-1.06 0l.53.53zm2 2l.53.53a.75.75 0 000-1.06l-.53.53zm-4 4v.75h.31l.22-.22-.53-.53zm-2 0h-.75c0 .414.336.75.75.75v-.75zm3 3.25A6.25 6.25 0 012.25 8.5H.75a7.75 7.75 0 007.75 7.75v-1.5zm6.25-6.25a6.25 6.25 0 01-6.25 6.25v1.5a7.75 7.75 0 007.75-7.75h-1.5zM8.5 2.25a6.25 6.25 0 016.25 6.25h1.5A7.75 7.75 0 008.5.75v1.5zm0-1.5A7.75 7.75 0 00.75 8.5h1.5A6.25 6.25 0 018.5 2.25V.75zm-2.47 9.28l4-4-1.06-1.06-4 4 1.06 1.06zm2.94-4l2 2 1.06-1.06-2-2-1.06 1.06zm2 .94l-4 4 1.06 1.06 4-4-1.06-1.06zM7.5 10.75h-2v1.5h2v-1.5zm-1.25.75v-2h-1.5v2h1.5z"
        fill={color}
      />
    </svg>
  );
}

export function PersonCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.6 21.8V21a4.8 4.8 0 014.8-4.8h3.2a4.8 4.8 0 014.8 4.8v.8M13 6.6a3.2 3.2 0 100 6.4 3.2 3.2 0 000-6.4zm0 17.6C6.814 24.2 1.8 19.186 1.8 13 1.8 6.814 6.814 1.8 13 1.8c6.185 0 11.2 5.014 11.2 11.2 0 6.186-5.015 11.2-11.2 11.2z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function PeopleIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.01 16.05h1.72a1 1 0 001-1v-.632a2.72 2.72 0 00-2.72-2.72m-3.807-6.937a3.262 3.262 0 01-3.264 3.262 3.262 3.262 0 110-6.523 3.262 3.262 0 013.264 3.261zm-1.088 5.374H4.764c-1.803 0-3.264 1.93-3.264 3.732v1.173a1 1 0 001 1h8.879a1 1 0 001-1v-1.173c0-1.802-1.462-3.732-3.264-3.732zm4.351-.612a2.176 2.176 0 110-4.351 2.176 2.176 0 010 4.35z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function PlusCircledIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.412 13.75V10m0-3.75V10m0 0h3.75m-3.75 0h-3.75m3.75 8.4a8.4 8.4 0 110-16.8 8.4 8.4 0 010 16.8z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function QuestionnaireIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.802 9h-3.75a.75.75 0 000 1.5h3.75a.75.75 0 100-1.5zm.75 6h-6a.75.75 0 01-.75-.75V3.75a.75.75 0 01.75-.75h3.75v2.25a2.25 2.25 0 002.25 2.25h2.25v.75a.75.75 0 001.5 0v-1.5a.643.643 0 00-.053-.247v-.068a.8.8 0 00-.142-.21l-4.5-4.5a.75.75 0 00-.217-.143h-.06a.66.66 0 00-.24-.082h-4.5a2.25 2.25 0 00-2.25 2.25v10.5a2.25 2.25 0 002.25 2.25h6a.75.75 0 100-1.5h-.038zm-.75-10.942L11.737 6h-1.185a.75.75 0 01-.75-.75V4.058zm4.178 11.16a.809.809 0 00-.382-.202.75.75 0 10.382.201zM9.802 12h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5zm3.645-2.25a2.25 2.25 0 00-1.95 1.125.753.753 0 001.305.75.75.75 0 11.645 1.125.75.75 0 000 1.5 2.25 2.25 0 100-4.5zM6.052 7.5h.75a.75.75 0 100-1.5h-.75a.75.75 0 000 1.5z"
        fill={color}
      />
    </svg>
  );
}

export function GearIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0)"
        stroke={color}
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" />
        <path d="M14.55 11.25a1.238 1.238 0 00.247 1.365l.045.045a1.5 1.5 0 01-1.06 2.562 1.5 1.5 0 01-1.062-.44l-.045-.044a1.238 1.238 0 00-1.365-.248 1.238 1.238 0 00-.75 1.133v.127a1.5 1.5 0 11-3 0v-.068a1.238 1.238 0 00-.81-1.132 1.238 1.238 0 00-1.365.247l-.045.045a1.5 1.5 0 11-2.123-2.122l.046-.045a1.238 1.238 0 00.247-1.365 1.238 1.238 0 00-1.132-.75H2.25a1.5 1.5 0 110-3h.067a1.237 1.237 0 001.133-.81 1.237 1.237 0 00-.248-1.365l-.044-.045A1.5 1.5 0 115.28 3.217l.045.046a1.237 1.237 0 001.365.247h.06a1.237 1.237 0 00.75-1.132V2.25a1.5 1.5 0 013 0v.067a1.238 1.238 0 00.75 1.133 1.237 1.237 0 001.365-.248l.045-.044a1.5 1.5 0 112.123 2.122l-.045.045a1.237 1.237 0 00-.248 1.365v.06a1.237 1.237 0 001.133.75h.127a1.5 1.5 0 010 3h-.068a1.238 1.238 0 00-1.132.75v0z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArchiveIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 24;
  const height = props.height || 24;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.786 13.286h6.428M4.286 8.143v11.571c0 .71.575 1.286 1.285 1.286H18.43c.71 0 1.285-.576 1.285-1.286V8.143H4.286zm-.286 0h16a1 1 0 001-1V4a1 1 0 00-1-1H4a1 1 0 00-1 1v3.143a1 1 0 001 1z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function ArchiveFolderIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();
  const width = props.width || 40;
  const height = props.height || 40;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.94 4.253a1.78 1.78 0 01-1.78-1.78h20.47a6.23 6.23 0 016.23 6.23v13.34h-1.78V8.703a4.45 4.45 0 00-4.45-4.45H12.94z"
        fill={color}
      />
      <path
        d="M36.08 9.593H18.17a2.7 2.7 0 01-2-.85l-5.57-6a.9.9 0 00-.65-.28H6.03a3.8 3.8 0 00-3.8 3.8v22a4.44 4.44 0 004.44 4.45h24.92a4.45 4.45 0 004.45-4.45l.04-18.67zm-30-8.9H10a2.631 2.631 0 011.95.85l5.58 6a.88.88 0 00.65.29h18.8a.89.89 0 01.89.89v19.57a6.23 6.23 0 01-6.23 6.23H6.72a6.221 6.221 0 01-6.22-6.23v-22a5.58 5.58 0 015.57-5.6h.01z"
        fill={color}
      />
    </svg>
  );
}

export function PlusIcon(props: React.SVGProps<SVGSVGElement>) {
  const width = props.width || 14;
  const height = props.height || 14;

  const color = props.color || "white";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.749.75a.75.75 0 00-1.5 0v5.498h-5.5a.75.75 0 000 1.5h5.5v5.5a.75.75 0 001.5 0v-5.5h5.498a.75.75 0 000-1.5H7.75V.75z"
        fill={color}
      />
    </svg>
  );
}

export function PencilEditIcon(props: React.SVGProps<SVGSVGElement>) {
  const width = props.width || 16;
  const height = props.height || 16;

  const color = props.color || "white";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.955 12.633l11.18-11.18a1.753 1.753 0 012.48 0L16.44 3.28a1.753 1.753 0 010 2.48L5.26 16.94H.955v-4.306zm.975 3.33h2.927l8.63-8.63-2.926-2.927-8.63 8.631v2.927zm12.393-9.465L15.75 5.07a.777.777 0 000-1.1l-1.826-1.827a.777.777 0 00-1.1 0l-1.429 1.428 2.927 2.927z"
        fill={color}
      />
    </svg>
  );
}

export function BinIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 18;
  const height = props.height || 18;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.305 3.494a.528.528 0 01-.155-.371.551.551 0 01.53-.53h5.033v-.784A1.659 1.659 0 017.372.15h2.668a1.659 1.659 0 011.66 1.659v.783h5.031a.551.551 0 01.531.531.528.528 0 01-.528.526h-1.326v10.734a3.446 3.446 0 01-3.468 3.467H5.495a3.468 3.468 0 01-3.468-3.468V3.649H.678a.528.528 0 01-.373-.155zM6.77 1.81v.783h3.872V1.81a.602.602 0 00-.602-.602H7.372a.602.602 0 00-.602.602zm7.581 12.573V3.649H3.084v10.733a2.411 2.411 0 002.411 2.411h6.445a2.41 2.41 0 002.411-2.411zm-8.37-.192a.528.528 0 01-.37.155.551.551 0 01-.531-.53V6.127a.528.528 0 01.528-.528h.005a.506.506 0 01.523.523v7.694c0 .14-.055.274-.154.373zm6.351-8.062v7.686a.551.551 0 01-.53.531.528.528 0 01-.527-.528V6.123A.505.505 0 0111.8 5.6h.005a.528.528 0 01.528.528zM9.08 14.19a.528.528 0 01-.902-.373V6.128a.528.528 0 111.057 0v7.689c0 .14-.056.274-.155.373z"
        fill={color}
      />
    </svg>
  );
}

export function DoneIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 18;
  const height = props.height || 12;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.78.72a.75.75 0 010 1.06L7.695 11.866a2.75 2.75 0 01-3.89 0L.22 8.28a.75.75 0 111.06-1.06l3.586 3.586a1.25 1.25 0 001.768 0L16.72.72a.75.75 0 011.06 0z"
        fill={color}
      />
    </svg>
  );
}

export function BuildingIcon(props: React.SVGProps<SVGSVGElement>) {
  const width = props.width || 14;
  const height = props.height || 16;

  const color = props.color || "white";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.195.177a.605.605 0 00-.855 0L.177 6.34a.605.605 0 10.855.855l5.735-5.736 5.736 5.736a.605.605 0 10.855-.855L7.195.177zm-4.24 8.55a.605.605 0 00-1.21 0v4.503A2.77 2.77 0 004.516 16H9.02a2.77 2.77 0 002.77-2.77V8.727a.604.604 0 10-1.209 0v4.503a1.562 1.562 0 01-1.56 1.561H4.515a1.56 1.56 0 01-1.561-1.56V8.726z"
        fill={color}
      />
    </svg>
  );
}

export function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 14;
  const height = props.height || 14;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 10a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm1.5 0c0-5.523-4.477-10-10-10C4.479 0 0 4.477 0 10s4.478 10 10 10c5.523 0 10-4.477 10-10zm-9.25 5V9h-1.5v6h1.5zM11 6a1 1 0 10-2 0 1 1 0 002 0z"
        fill={color}
        fillOpacity={0.6}
      />
    </svg>
  );
}

export function CategoryIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0a1 1 0 01.897.557l2.118 4.293 4.737.688a1 1 0 01.555 1.706l-3.428 3.341.809 4.718a1 1 0 01-1.451 1.054L9 14.13l-4.237 2.227a1 1 0 01-1.45-1.054l.808-4.718L.694 7.244a1 1 0 01.554-1.706l4.737-.688L8.103.557A1 1 0 019 0zM6.649 5.764l-5.257.764 3.804 3.708-.898 5.236L9 13l4.702 2.472-.898-5.236 3.804-3.708-5.257-.764L9 1 6.649 5.764z"
        fill={color}
      />
    </svg>
  );
}

export function NotificationIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.834 16c-1.885 0-2.628-1.257-2.8-2.286H.55c-.229 0-.457-.114-.515-.343-.057-.228-.057-.457.115-.628 0 0 1.543-1.6 1.543-4.172 0-1.657 0-6.457 4.571-6.857V.571c0-.342.229-.571.571-.571.343 0 .572.229.572.571v1.143c4.571.343 4.571 5.2 4.571 6.857 0 2.572 1.543 4.172 1.543 4.172.172.171.229.4.114.628-.057.229-.285.343-.514.343H9.634C9.463 14.743 8.72 16 6.834 16zm-1.6-2.286c.172.515.572 1.143 1.6 1.143 1.086 0 1.486-.628 1.6-1.143h-3.2zm-.685-1.143h7.428c-.514-.857-1.143-2.228-1.143-4 0-3.942-1.257-5.714-4-5.714-2.742 0-4 1.772-4 5.714 0 1.772-.628 3.143-1.142 4h2.857z"
        fill={color}
      />
    </svg>
  );
}

export function FaqIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.025 12.38v.43c0 .149-.112.26-.261.26h-.654a.255.255 0 01-.261-.26v-.43c0-.15.112-.262.261-.262h.654c.149.019.26.131.26.262zm.822-3.996c-.318-.28-.747-.411-1.289-.411-.504 0-.915.112-1.232.355-.28.205-.43.523-.448.915 0 .093.074.168.168.168l.747.018a.28.28 0 00.26-.186.448.448 0 01.132-.224.567.567 0 01.392-.15c.187 0 .336.056.448.168.112.112.15.262.15.467a.823.823 0 01-.131.486c-.094.13-.206.261-.355.354-.299.206-.504.393-.616.56-.094.131-.15.3-.168.523-.02.112.074.187.186.187h.803c.094 0 .168-.075.187-.168.019-.112.037-.205.075-.299a.793.793 0 01.298-.317 1.9 1.9 0 00.654-.56c.168-.243.261-.504.261-.785-.056-.448-.205-.821-.522-1.101zM.617 9.523c-.244.168-.374.467-.3.747.056.28.3.504.598.541.205.037.41.037.616.037.616 0 1.176-.13 1.662-.354.317.168.653.299 1.008.41v-.223c0-.318.019-.617.075-.915a6.27 6.27 0 01-.803-.374l-.299-.168-.28.168c-.28.168-.597.28-.952.336.168-.224.299-.467.392-.728l.112-.317-.224-.243c-.747-.803-1.139-1.774-1.139-2.801 0-2.483 2.353-4.519 5.228-4.519 2.204 0 4.09 1.177 4.855 2.839.187-.02.373-.02.56-.02.205 0 .41.02.635.02C11.558 1.662 9.149 0 6.33 0 2.838 0 0 2.52 0 5.62c0 1.177.41 2.316 1.195 3.268-.15.261-.336.485-.579.635zm17.383.99c0 1.194-.43 2.352-1.214 3.323.15.261.336.486.58.635.242.168.373.467.298.747a.674.674 0 01-.598.541c-.205.038-.41.038-.616.038a3.97 3.97 0 01-1.699-.374 7.088 7.088 0 01-3.174.747c-3.53 0-6.405-2.558-6.405-5.676 0-3.118 2.894-5.658 6.423-5.658 3.53 0 6.405 2.54 6.405 5.676zm-1.102 0c0-2.521-2.371-4.575-5.303-4.575-2.931 0-5.302 2.054-5.302 4.574 0 2.521 2.37 4.575 5.302 4.575a5.983 5.983 0 002.913-.747l.28-.168.28.168c.3.168.617.28.971.336a2.858 2.858 0 01-.392-.746l-.112-.318.224-.243c.747-.821 1.14-1.81 1.14-2.857z"
        fill={color}
      />
    </svg>
  );
}

export function TutorialVideoIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.5 10C.5 4.751 4.751.5 10 .5s9.5 4.251 9.5 9.5-4.251 9.5-9.5 9.5A9.497 9.497 0 01.5 10zm7 3.5a1 1 0 001.6.8l4.667-3.5a1 1 0 000-1.6L9.1 5.7a1 1 0 00-1.6.8v7z"
        stroke={color}
      />
    </svg>
  );
}

export function RoomIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.104 5.724c.324.19.804.082.984-.26.183-.346.086-.778-.26-.983a231.111 231.111 0 00-.735-.437l-.88-.522a992.8 992.8 0 01-3.928-2.335C9.98 1.002 9.672.82 9.365.637a73.26 73.26 0 01-.27-.162c-.208-.125-.415-.25-.626-.37L8.462.102 8.456.098a.72.72 0 00-.727 0l-.368.219-.368.218-.879.523-.88.522c-.708.423-1.416.844-2.125 1.265l-.922.548a979.762 979.762 0 01-1.819 1.08c-.001.002-.004.003-.006.004l-.006.004a.747.747 0 00-.331.43.728.728 0 00.072.554c.208.327.636.464.984.259l.367-.219.368-.218.88-.523.879-.522 2.127-1.264.923-.549a992.64 992.64 0 011.197-.71l.274-.16.184.11.185.11.879.522.88.522 1.307.777.82.487.922.549.923.548.27.162c.207.125.415.25.625.37l.013.008zm-.378 3.578c-.44-.298-.96-.42-1.487-.42H7.89l-.25-.002c-.144-.002-.29-.004-.435 0a.46.46 0 00-.048.003h-.008c-.32.027-.62.22-.779.498-.111.198-.13.409-.13.628v2.002H1.761V7.83c0-.376-.33-.737-.72-.719-.39.018-.719.316-.719.72v7.444c0 .376.331.738.72.72.39-.018.72-.317.72-.72V13.45h12.66v1.831c0 .376.33.737.719.72.39-.019.72-.317.72-.72v-3.874a2.561 2.561 0 00-1.136-2.104zm-9.331.865a1.448 1.448 0 00-.957-1.334 1.419 1.419 0 00-1.54.415c-.386.466-.462 1.133-.143 1.658.315.522.919.77 1.51.66.648-.12 1.112-.723 1.13-1.368v-.031z"
        fill={color}
      />
    </svg>
  );
}

export function GuestIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 6a3 3 0 100-6 3 3 0 000 6zm2-3a2 2 0 11-4 0 2 2 0 014 0zm2 15a1 1 0 001-1v-5a5 5 0 00-10 0v5a1 1 0 001 1h8zm-8-6a4 4 0 118 0v5H1v-5z"
        fill={color}
      />
    </svg>
  );
}

export function AdviceIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.333c-4.18 0-7.333 2.754-7.333 5.879 0 1.816 1.041 3.252 2.843 4.374l.035.022.033.027a1.488 1.488 0 01.43 1.738l-.02.05-.029.044a5.404 5.404 0 01-.228.335l.086-.025c.363-.106.712-.229.988-.363.138-.067.245-.13.323-.187a.718.718 0 00.106-.097.667.667 0 01.686-.305c.696.127 1.398.215 2.104.266 4.17-.01 7.31-2.76 7.31-5.879 0-3.125-3.152-5.879-7.333-5.879zM.335 7.212C.334 3.113 4.346 0 9.001 0s8.666 3.113 8.666 7.212-4.011 7.212-8.666 7.212h-.024l-.023-.002c-.64-.045-1.277-.119-1.909-.223a3.072 3.072 0 01-.658.415 7.87 7.87 0 01-1.196.443 13.47 13.47 0 01-1.733.387 1 1 0 01-.9-.364.811.811 0 01-.159-.654.84.84 0 01.37-.536 4.09 4.09 0 001.023-1.072.155.155 0 00-.037-.131C1.743 11.419.334 9.625.334 7.212z"
        fill={color}
      />
    </svg>
  );
}

export function EventIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16zm-3.706-4.271a1 1 0 01-.262-.958l1.232-4.79a1 1 0 01.72-.72l4.804-1.23a1 1 0 011.217 1.217l-1.219 4.759a1 1 0 01-.715.719l-4.818 1.261a1 1 0 01-.959-.258zm5.638-6.446l-3.7.947-.947 3.681L5 11.02l1.107-.29 3.71-.972.937-3.653L11.037 5l-1.105.283zm-1.82 3.94a1.111 1.111 0 100-2.223 1.111 1.111 0 000 2.222z"
        fill={color}
      />
    </svg>
  );
}

export function ServiceIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0H5.359a2.23 2.23 0 00-2.227 2.227v1.505a.544.544 0 01-.513.576h-.515a.893.893 0 00-.99.751l-.78 7.27v.041A3.634 3.634 0 003.962 16h6.36a3.634 3.634 0 003.63-3.63l-.73-6.83a1.204 1.204 0 00-1.126-1.232H11.7a.415.415 0 01-.475-.357V2.228A2.23 2.23 0 009 0zM5.18 1.108c.059-.01.119-.015.179-.015h3.64a1.135 1.135 0 011.134 1.134v1.505a.544.544 0 01-.513.576H4.704l-.007.001a.416.416 0 01-.474-.357l.002-1.725c0-.06.005-.12.015-.18.02-.29.052-.472.26-.68.207-.207.388-.238.68-.26zM11.484 5.4h.007a.533.533 0 01.088.006.855.855 0 01.215.056.528.528 0 01.342.458l.725 6.47a2.54 2.54 0 01-2.456 2.515H3.965a2.54 2.54 0 01-2.537-2.517l.748-6.664a.353.353 0 01.137-.198c.09-.067.243-.126.508-.126h8.664z"
        fill={color}
      />
    </svg>
  );
}

export function HelpIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 17;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15.167a.605.605 0 01-.356-.15l-2.315-2.35h-4.08A1.25 1.25 0 010 11.418V1.916A1.25 1.25 0 011.25.666H14.75A1.25 1.25 0 0116 1.917v9.502a1.25 1.25 0 01-1.249 1.249h-4.044L8.354 15.02a.499.499 0 01-.354.147zm-6.75-13.5a.25.25 0 00-.25.25v9.501a.25.25 0 00.25.249h4.289a.498.498 0 01.356.15L8 13.956l2.147-2.144a.498.498 0 01.353-.146h4.25a.25.25 0 00.25-.249V1.916a.25.25 0 00-.249-.25H1.25zM8 3.286a.667.667 0 110 1.333.667.667 0 010-1.333zm-.667 3.017c0-.276.3-.5.667-.5.368 0 .667.224.667.5v3c0 .276-.299.5-.667.5-.368 0-.667-.224-.667-.5v-3z"
        fill={color}
      />
    </svg>
  );
}

export function LightIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 30;
  const height = props.height || 30;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.484 12.84l-3.96-1.155a.95.95 0 11.53-1.822l3.96 1.155a.949.949 0 11-.53 1.821zm3.104-5.12L5.915 4.35a.95.95 0 111.488-1.18l2.673 3.372a.95.95 0 11-1.488 1.18zm5.704-2.405V.952a.951.951 0 111.9 0v4.363a.95.95 0 11-1.9 0zm14.268 6.37l-3.96 1.154a.948.948 0 11-.531-1.82l3.96-1.156a.949.949 0 11.531 1.822zm-4.242-7.222l-2.67 3.368a.95.95 0 11-1.488-1.18l2.67-3.367a.95.95 0 111.488 1.18zm-9.11 26.67a7.07 7.07 0 01-4.228-1.28.769.769 0 01.934-1.22 5.52 5.52 0 003.295.965 5.52 5.52 0 003.294-.965.77.77 0 01.934 1.222 7.07 7.07 0 01-4.06 1.278h-.168zm-.017-2.652a7.072 7.072 0 01-4.229-1.284.769.769 0 11.934-1.222 5.517 5.517 0 003.295.969 5.516 5.516 0 003.294-.969.77.77 0 01.934 1.222 7.071 7.071 0 01-4.07 1.283l-.158.001zm-4.782-4.782a8.652 8.652 0 00-1.107-2.452l-.007-.011c-.95-1.712-2.128-3.837-2.128-6.065a8.047 8.047 0 0116.095 0c0 2.229-1.184 4.363-2.133 6.073a8.552 8.552 0 00-.938 1.904c-.037.107-.064.22-.09.335-.064.268-.128.536-.325.717-.147.135-.335.215-.522.294-.1.043-.2.085-.294.136a7.514 7.514 0 01-3.592.907h-.163a7.52 7.52 0 01-3.775-.916 3.492 3.492 0 00-.275-.13c-.18-.079-.359-.157-.5-.288a.772.772 0 01-.246-.504z"
        fill={color}
      />
    </svg>
  );
}
export function TurnedOffLightIcon(props: React.SVGProps<SVGSVGElement>) {
  const theme = useTheme();

  const width = props.width || 30;
  const height = props.height || 30;

  const color = props.color || theme.palette.text.primary;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 17"
      {...props}
    >
      <path
        fill={color}
        d="M6.028 16.006a4.714 4.714 0 01-2.82-.853.513.513 0 01.623-.813 3.68 3.68 0 002.197.643 3.68 3.68 0 002.196-.643.512.512 0 11.623.814 4.713 4.713 0 01-2.707.852h-.112zm0-1.768a4.715 4.715 0 01-2.82-.856.512.512 0 11.623-.814 3.68 3.68 0 002.197.646 3.678 3.678 0 002.196-.646.512.512 0 11.623.814 4.714 4.714 0 01-2.714.856h-.105zm-3.2-3.188a5.767 5.767 0 00-.738-1.634C1.456 8.273.667 6.854.667 5.365a5.365 5.365 0 1110.73 0c0 1.486-.79 2.909-1.422 4.049a5.703 5.703 0 00-.738 1.634.513.513 0 01-.513.472h-.041a.513.513 0 01-.467-.551 5.809 5.809 0 01.861-2.05c.608-1.092 1.294-2.327 1.294-3.552a4.336 4.336 0 10-8.672 0c0 1.225.687 2.46 1.294 3.552.417.622.709 1.318.861 2.05a.514.514 0 01-.471.553H3.34a.515.515 0 01-.514-.473l.001.001z"
      ></path>
    </svg>
  );
}

export const VisibleBaseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  const width = props.width || 30;
  const height = props.height || 30;

  const color = props.color || theme.palette.text.primary;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M2.052 11.209C2.164 10.913 4.837 4 12 4s9.836 6.913 9.948 7.209a.85.85 0 010 .583c-.112.296-2.785 7.209-9.948 7.209s-9.836-6.913-9.948-7.209a.85.85 0 010-.583zm1.685.291c.554 1.25 2.965 5.834 8.263 5.834 5.254 0 7.709-4.621 8.263-5.834-.554-1.25-2.965-5.833-8.263-5.833-5.254 0-7.709 4.62-8.263 5.833zm8.264 3.54a3.542 3.542 0 100-7.083 3.542 3.542 0 000 7.084z"
      ></path>
    </svg>
  );

  return icon;
};
export const WarningTriangleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  const width = props.width || 18;
  const height = props.height || 17;

  const color = props.color || theme.palette.text.primary;

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill={color}
        d="M3.315 16a2.912 2.912 0 01-2.667-1.282 2.913 2.913 0 01.19-2.955l5.67-10.085A2.844 2.844 0 018.916 0a2.844 2.844 0 012.405 1.678l5.673 10.085a2.912 2.912 0 01.189 2.955A2.911 2.911 0 0114.515 16h-11.2zM7.659 2.325L1.986 12.41a1.7 1.7 0 00-.2 1.641 1.701 1.701 0 001.53.628h11.2a1.7 1.7 0 001.529-.628 1.701 1.701 0 00-.2-1.641L10.169 2.325c-.36-.638-.816-1.004-1.254-1.004-.438 0-.896.366-1.256 1.004zm.386 9.824a.867.867 0 111.735.008.867.867 0 01-1.735-.008zm.352-2.015l-.346-4.213h.004c0-.024-.007-.047-.007-.073a.87.87 0 011.74 0 .718.718 0 01-.008.073h.005l-.347 4.213a.519.519 0 01-1.035 0h-.006z"
      ></path>
    </svg>
  );

  return icon;
};
