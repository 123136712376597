import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function PinIcon(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "16"}
			height={iconSize || "17"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 16 17"
		>
			<path
				fill={color || "#EF7705"}
				d="M7.466 14.873a.4.4 0 00.535 0c.169-.152 4.132-3.762 4.132-7.497a4.4 4.4 0 00-8.8 0c0 3.735 3.964 7.345 4.133 7.497zM5.189 4.83a3.604 3.604 0 012.544-1.055 3.604 3.604 0 013.6 3.6c0 2.848-2.724 5.778-3.6 6.646-.876-.867-3.6-3.793-3.6-6.646.001-.955.38-1.87 1.056-2.545zM6.622 9.04a2 2 0 102.222-3.326 2 2 0 00-2.222 3.326zm.444-2.661A1.2 1.2 0 118.4 8.373a1.2 1.2 0 01-1.334-1.995z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default PinIcon;
