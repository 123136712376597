/**@jsxImportSource @emotion/react */

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { RoomApi } from "belvillage-api-manager";
import { isFunction } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { DropEvent, FileRejection } from "react-dropzone";
import { useParams } from "react-router-dom";
import { BinIcon, DoneIcon } from "../../../../components/BelVillageIcons";
import DocumentPicker from "../../../../components/DocumentPicker";
import ArrowDownIcon from "../../../../components/icons/arrow_down";
import CalendarIcon from "../../../../components/icons/calendar";
import ClockIcon from "../../../../components/icons/clock";
import CloseCancIcon from "../../../../components/icons/close_canc";
import DeleteIcon from "../../../../components/icons/delete";
import DocumentsIcon from "../../../../components/icons/documents";
import PinIcon from "../../../../components/icons/pin";
import LoadingIconButton from "../../../../components/LoadingIconButton";
import MLDialog from "../../../../components/poppers";
import { backendMediaUrlParser } from "../../../../utils";
import { useApi, useFetch } from "../../../../utils/api";
import { useEventContextSelector } from "../controllers/EventController";
import CalendarPicker from "./CalendarPicker";
import LocationPicker from "./LocationPicker";
import TimeSlotsPicker from "./TimeSlotsPicker";

type PickerRowProps = {
	disabled?: boolean;
	startIcon: React.ReactNode;
	title: string;
	endText: string;
	children?:
		| ((closeAccordion: () => void) => React.ReactNode)
		| React.ReactNode;
};

const PickerRow: React.FC<PickerRowProps> = ({
	disabled,
	startIcon,
	title,
	endText,
	children,
}) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<Accordion
			disabled={disabled}
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			css={{
				backgroundColor: "rgba(7, 7, 7, 1)",
				borderRadius: "12px !important",
				border: "unset",
				overflow: "hidden",
			}}
		>
			<AccordionSummary
				expandIcon={<ArrowDownIcon size={24} color="white" />}
				css={{
					padding: 12,
					backgroundColor: "#222222",
					"&.Mui-expanded": {
						minHeight: 0,
					},
					"& .MuiAccordionSummary-content": {
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: 0,
						paddingRight: 8,
						margin: 0,
					},
					"& .MuiAccordionSummary-expandIcon": {
						padding: 0,
						margin: 0,
					},
				}}
			>
				<div
					css={{
						display: "flex",
						alignItems: "center",
						gap: 8,
					}}
				>
					{startIcon}
					<Typography
						children={title}
						variant="subtitle2"
						css={{ color: "white" }}
					/>
				</div>
				<Typography
					children={endText}
					variant="body2"
					color="primary"
				/>
			</AccordionSummary>
			<AccordionDetails
				css={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					padding: 12,
				}}
			>
				{isFunction(children)
					? children?.(() => setExpanded(false))
					: children}
			</AccordionDetails>
		</Accordion>
	);
};

export type EventEditFormProps = {
	new?: boolean;
};
export default function EventEditForm(props: EventEditFormProps) {
	const [saveLoading, setSaveLoading] = useState(false);

	const { buildingId, id: eventId } = useParams<any>();
	const roomApi = useApi(RoomApi);
	const { data: rooms, loading: roomsLoading } = useFetch(
		roomApi.allAdminRoomsByBuildingId,
		buildingId
	);

	const event = useEventContextSelector("eventEditState");
	const deleteEvent = useEventContextSelector("deleteEvent");
	const saveDataToBackend = useEventContextSelector("saveDataToBackend");
	const onEventStateChange = useEventContextSelector("onEventStateChange");

	const onDrop = useCallback<
		<T extends File>(
			acceptedFiles: T[],
			fileRejections: FileRejection[],
			event: DropEvent
		) => void
	>((acceptedFiles, fileRejections) => {
		acceptedFiles.forEach((file: File) => {
			onEventStateChange({ image: file });
		});

		fileRejections.forEach((fileRejection) => {
			MLDialog.showSnackbar("Failed to upload file", {
				variant: "error",
				autoHideDuration: 3000,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<Grid container spacing={3} direction="column">
				<Grid item>
					<TextField
						value={event.name}
						onChange={(e) =>
							onEventStateChange({ name: e.target.value })
						}
						label="Leave a title"
						fullWidth
						variant="outlined"
					/>
				</Grid>
				<Grid item>
					<TextField
						value={event.description}
						onChange={(e) =>
							onEventStateChange({ description: e.target.value })
						}
						label="Leave a description"
						fullWidth
						variant="outlined"
					/>
				</Grid>
				{!props.new && (
					<Grid item>
						<Typography
							children="Upload image"
							variant="overline"
						/>
						<DocumentPicker
							onDrop={onDrop}
							title="Upload post image"
							accept="image/*"
							border
							css={{
								backgroundImage:
									event.image &&
									`url("${
										typeof event.image === "string"
											? backendMediaUrlParser(event.image)
											: URL.createObjectURL(event.image)
									}")`,
								backgroundPosition: "50% 50%",
								backgroundSize: "contain",
								backgroundRepeat: "no-repeat",
							}}
						/>
					</Grid>
				)}
				<Grid item>
					<PickerRow
						startIcon={<CalendarIcon size={24} color="#ffff" />}
						title={"Date"}
						endText={
							event.date
								? DateTime.fromISO(event.date).toFormat(
										"LLLL, EEEE dd"
								  )
								: "Choose a date"
						}
					>
						{(closeAccordion) => (
							<CalendarPicker
								onDateChange={(date) => {
									onEventStateChange({
										date: DateTime.fromJSDate(date).toISO(),
										time: {},
									});
									closeAccordion();
								}}
								selectedDate={
									event.date
										? DateTime.fromISO(
												event.date
										  ).toJSDate()
										: undefined
								}
							/>
						)}
					</PickerRow>
				</Grid>
				<Grid item>
					<PickerRow
						startIcon={<PinIcon size={24} color="#ffff" />}
						title={"Location"}
						endText={
							event.room
								? rooms?.find((elem) => elem.id === event.room)
										?.name ?? "Choose a location"
								: "Choose a location"
						}
					>
						{(closeAccordion) => (
							<LocationPicker
								roomId={event.room}
								rooms={rooms ?? []}
								loading={roomsLoading}
								onLocationChange={(location) => {
									onEventStateChange({
										room: location.id,
										time: {},
									});
									closeAccordion();
								}}
							/>
						)}
					</PickerRow>
				</Grid>
				<Grid item>
					<PickerRow
						startIcon={<ClockIcon size={24} color="#ffff" />}
						title={"Time slots"}
						endText={
							event.time.start && event.time.end
								? `${DateTime.fromISO(
										event.time.start
								  ).toFormat("t")} - ${DateTime.fromISO(
										event.time.end
								  ).toFormat("t")}`
								: "Choose a time slot"
						}
						disabled={
							event.date === undefined || event.room === undefined
						}
					>
						<TimeSlotsPicker />
					</PickerRow>
				</Grid>
				<Grid item css={{ paddingBottom: "0px !important" }}>
					<Grid container justifyContent={"space-between"}>
						<Grid item>
							{!props.new && event.id && (
								<Button
									variant="text"
									children="Delete"
									startIcon={<BinIcon />}
									onClick={async () => {
										MLDialog.showModal(
											"Delete event",
											"Are you sure you want to delete this event?",
											{
												onPositiveClick: deleteEvent,
												positiveButton: (
													onClick,
													loading
												) => {
													return (
														<LoadingIconButton
															loading={loading}
															variant="contained"
															color="primary"
															children="Delete"
															startIcon={
																<DeleteIcon
																	color="#fff"
																	size={24}
																/>
															}
															onClick={onClick}
														/>
													);
												},
												negativeButton: (
													<Button
														children="Cancel"
														startIcon={
															<CloseCancIcon />
														}
														onClick={
															MLDialog.hideModal
														}
														variant="outlined"
													/>
												),
											}
										);
									}}
									css={{ height: 48 }}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container css={{ gap: 12 }}>
								{!props.new && (
									<Grid item>
										<CopyToClipboard
											text={`https://app.belvillage.com/community/events/${eventId}`}
											onCopy={() =>
												MLDialog.showSnackbar(
													"Link copied successfully",
													{ variant: "success" }
												)
											}
										>
											<Button
												children="Copy event link"
												variant="outlined"
												color="primary"
												startIcon={
													<DocumentsIcon size={24} />
												}
												css={{ height: 48 }}
											/>
										</CopyToClipboard>
									</Grid>
								)}
								<Grid item>
									<LoadingIconButton
										loading={saveLoading}
										variant="contained"
										color="primary"
										children="Confirm"
										startIcon={<DoneIcon />}
										disabled={
											!event.date ||
											!event.name ||
											!event.time.start ||
											!event.time.end ||
											!event.room ||
											!event.description
										}
										onClick={async () => {
											setSaveLoading(true);
											await saveDataToBackend();
											setSaveLoading(false);
										}}
										css={{ height: 48 }}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
