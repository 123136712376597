/**@jsxImportSource @emotion/react */
import { Button, Grid, Typography } from "@material-ui/core";
import {
  AirConditioning,
  EditAirConditioningInput,
  EditOrCreateAirConditioningModel,
} from "belvillage-api-manager";
import { useState } from "react";
import { PlusIcon } from "../../../../components/BelVillageIcons";
import theme from "../../../../theme";
import AirConditioningTile from "./AirConditioningTile";
import DialogDeleteAirConditioning from "./DialogDeleteAirConditioning";
import DialogEditAddAirConditioning from "./DialogEditAddAirConditioning";

export default function RoomAirConditioning(props: {
  airConditioningData: AirConditioning[];
  onNew: () => void;
  onDelete: (airConditioningId: string) => void;
  onSave: (
    airConditioningId: string,
    airConditioningData: EditOrCreateAirConditioningModel
  ) => void;
}) {
  const { airConditioningData } = props;
  const [currentAirConditioning, setCurrentAirConditioning] = useState<
    AirConditioning | undefined
  >(undefined);
  const [currentAirConditioningToDelete, setCurrentAirConditioningToDelete] =
    useState<AirConditioning | undefined>(undefined);
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item css={{ flex: 1 }}>
            <Typography
              children="Air Conditioning"
              variant="overline"
              css={{ opacity: 0.38 }}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              css={{
                textTransform: "initial",
                fontSize: 14,
                fontWeight: 400,
              }}
              size="small"
              children={"Add Air conditioning"}
              endIcon={<PlusIcon color={theme.palette.primary.main} />}
              onClick={() => {
                props.onNew();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row">
          {airConditioningData?.map((airConditioningToRender, i) => [
            <AirConditioningTile
              airConditioning={airConditioningToRender}
              key={`air-conditioning-${i}`}
              onEdit={() => {
                setCurrentAirConditioning(airConditioningToRender);
              }}
              onDelete={() => {
                setCurrentAirConditioningToDelete(airConditioningToRender);
              }}
            />,
            <DialogEditAddAirConditioning
              open={
                currentAirConditioning?.hsycoId ===
                airConditioningToRender.hsycoId
              }
              onClose={() => {
                setCurrentAirConditioning(undefined);
              }}
              airConditioning={airConditioningToRender}
              onSave={(airConditioningData) => {
                props.onSave(airConditioningToRender.id, airConditioningData);
              }}
              loading={false}
            />,
            <DialogDeleteAirConditioning
              onClose={() => setCurrentAirConditioningToDelete(undefined)}
              open={
                currentAirConditioningToDelete?.hsycoId ===
                airConditioningToRender.hsycoId
              }
              onDeleteConfirmation={() => {
                console.log("Delete flow");
                props.onDelete(airConditioningToRender.id);
                setCurrentAirConditioningToDelete(undefined);
              }}
            />,
          ])}
        </Grid>
      </Grid>
    </Grid>
  );
}
