import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

function StarIconOutlined(props: IIcon) {
	const theme = useTheme();
	const {
		size = 24,
		color = theme.palette.primary.main,
		highlighted,
		opacity,
	} = props;

	const iconSize =
		highlighted && !props.HighlightProps?.forceRegularSize
			? calculateHighlightedSize(size)
			: size;

	const icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={iconSize || "24"}
			height={iconSize || "24"}
			fill="none"
			fillOpacity={opacity || 100}
			viewBox="0 0 24 24"
		>
			<path
				fill={color || "#EF7705"}
				d="M12 3a1 1 0 01.897.557l2.118 4.293 4.737.688a1 1 0 01.555 1.706l-3.428 3.341.809 4.718a1 1 0 01-1.451 1.054L12 17.13l-4.237 2.227a1 1 0 01-1.45-1.054l.808-4.718-3.427-3.341a1 1 0 01.554-1.706l4.737-.688 2.118-4.293A1 1 0 0112 3zM9.649 8.764l-5.257.764 3.804 3.708-.898 5.236L12 16l4.702 2.472-.898-5.236 3.804-3.708-5.257-.764L12 4 9.649 8.764z"
			></path>
		</svg>
	);

	return <TemplateIcon icon={icon} iconProps={props} />;
}

export default StarIconOutlined;
